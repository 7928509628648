<template lang="pug">
	div.tab-wrap
		v-layout.tab-container(
			column
			align-center
		)

			.direction-layer.fill-height
				.make-title(
					v-if="directions.length > 0"
				) 검수 할 프로젝트를 선택하세요

				v-layout.direction-wrap(
					v-if="directions.length > 0"
					column
				)
					.legend
						v-image(src="make_project_legend.png")
					.directions(
						v-for="type in ['low', 'medium', 'high']"
						:key="type"
					)
						v-layout.direction(
							justify-center
							align-center
							v-for="(direction, index) in directionItems[type]"
							:key="index"
							:class="`${selectedClass(direction)} ${completedClass(direction)}`"
							@click="selectItem(direction)"
						) {{ names[type] }}{{ direction.order }}
						.direction-name(v-if="directionItems[type].length === 0") {{ names[type] }}
				.empty(v-else)
					span 진행 중인 프로젝트가 없어요

</template>

<script>

    export default {
        data() {
            return {
                directions: [],
                names: { low: '하', medium: '중', high: '상'},
                selected: null,
                directionItems: { low :[], medium: [], high: [] },

	            dialogId: -1,
            }
        },

        computed: {
            mqttData() {
                return this.$gameData.mqttData
            },

        },
        watch: {
            mqttData(val) {
                if (val && val.action === 'direction' && (val.target === 'part' && val.part === 'make' || val.target
	                === 'team' && val.part === 'buy'
                )) {
                    console.log(val)
                    if (val.target === 'team') {
	                    this.$getUserData()
	                    this.getTeamDirections()
                    } else if (val.target === 'part') {

	                    if (val.make_status !== undefined) {
		                    if (this.selected && this.selected.type === val.type && this.selected.order === val.order) {

			                    if (val.inspected && val.user_id !== this.user.id) {
				                    this.selected = null
				                    this.$hideDialog(this.dialogId)
			                    } else {
				                    this.directions.find(v => v.type === val.type && v.order === val.order).make_status = val.make_status
			                    }
		                    } else {
			                    this.directions.find(v => v.type === val.type && v.order === val.order).make_status = val.make_status
		                    }
	                    }
                    }

                }
                if (val && val.action === 'inspection') {
                    if (val.type && val.order) {
                        this.$getUserData()
                        this.getTeamDirections()
                    }
                }

                this.$gameData.mqttData = null
            }
        },

        beforeDestroy() {
            this.unLoadEvent()
            $(window).off('beforeunload', this.unLoadEvent)
        },

        async mounted() {
            $(window).on('beforeunload', this.unLoadEvent)
            await this.getTeamDirections()
        },

        methods: {
            unLoadEvent(e) {
                if (!this.selected) return

                this.selected.make_status = 0
                this.selected.inspected = 0
                this.selected.part = 'make'
                this.selected.user_id = this.user.id

                // this.$publishMqtt('part', 'direction', this.selected)
	            this.$publishDirection('part', 'make', this.selected)

                if (e) {
                    e.preventDefault();
                    e.returnValue = '';
                }

            },
            async getTeamDirections() {
                let response
                response = await this.$get('/team/directions')

                this.$set(this, 'directions', response.data.filter(v => v.buy_status === 2 || v.buy_status === 3))

                let items = {low: [], medium: [], high: []}
                for (let type of ['low', 'medium', 'high']) {
                    this.directions.forEach(direction => {
                        if (direction.type === type) items[type].push(direction)
                    })

                    items[type].sort((a, b) => {
                        return a.order > b.order ? 1 : -1;
                    })
                }

                this.directionItems = items
            },
            selectedClass(item) {
                if (item.make_status === 1) return 'selected'
                if (this.selected && this.selected.type === item.type && this.selected.order === item.order) return 'selected'

                return ''
            },
            completedClass(item){
                if (item.make_status === 2) return 'completed'
                return ''
            },
            selectItem(item) {
                if (item.make_status === 1) {
                    this.$showToast('make1')
                    return
                } else if (item.make_status === 2) {
                    return
                }

                this.selected = item
                item.make_status = 1
                item.inspected = 0
                item.part = 'make'
                item.user_id = this.user.id
                item.make_opened = moment('YYYY-MM-DD HH:mm:ss')

                // this.$publishMqtt('part', 'direction', item)
	            this.$publishDirection('part', 'make', item)

                this.dialogId = this.$showDialog({
                    type: 'make1',
                    message: `&nbsp;\n검수할 프로젝트는 [${this.names[item.type]}${item.order}]입니다`,
                    align: 'left',
                    color: '#969696',
                    buttons: [
                        { type: 'cancel', click: _ =>{
                                if (item.make_status === 1) {
                                    item.make_status = 0
                                    item.inspected = 0
                                }
                                // this.$publishMqtt('part', 'direction', item)
		                        this.$publishDirection('part', 'make', item)
                                this.selected = null
                            }},
                        { type: 'request', click: this.inspection},
                    ]
                })
            },

            inspection() {
                if (this.user.capital < 100) {
                    this.selected.make_status = 0
                    this.selected.inspected = 0
                    this.selected.part = 'make'
                    this.selected.user_id = this.user.id

                    // this.$publishMqtt('part', 'direction', this.selected)
	                this.$publishDirection('part', 'make', this.selected)
	                this.$showToast('point_not_enough')
                    this.selected = null
                    return
                }
                setTimeout(async _ => {

                    const response = await this.$post('/inspect/request', this.selected)
                    if (response.code !== '0000') {

	                    if (response.code === '9601') {
		                    this.$showToast('make1')
		                    this.selected = null
		                    return
	                    }

	                    this.selected.make_status = 0
                        this.selected.inspected = 0
                        this.selected.part = 'make'
                        this.selected.user_id = this.user.id

                        // this.$publishMqtt('part', 'direction', this.selected)
	                    this.$publishDirection('part', 'make', this.selected)

                        this.$showToast('point_not_enough')
                        this.selected = null
                        return
                    }
                    this.$publishMqtt('team', 'inspection', this.selected)

                    this.selected.make_status = 1
                    this.selected.inspected = 1
                    // this.$publishMqtt('part', 'direction', this.selected)
	                this.$publishDirection('part', 'make', this.selected)

                    this.selected = null
                    this.$showDialog({
                        type: 'make2',
                        buttons: [
                            { type: 'make2' },
                        ]
                    })
                }, 200)
            }
        }
    }
</script>

<style lang="scss" scoped>
	.tab-wrap {
		height: 100%;

		.make-title {
			margin-top: 20px;
			font-size: 22px;
			color: #5D5D5D;
		}

		.direction-wrap {
			padding-bottom: 150px;

			.legend {
				margin-top: 26px;
				text-align: right;
			}

			.directions {
				width: 522px;
				padding: 15px 0;

				&+.directions {
					border-top: 1px solid #D1D1D1;
				}

				.direction {
					display: inline-flex;
					width: 120px;
					height: 120px;
					background-color: #ffffff;
					border: 1px solid #D1D1D1;
					border-radius: 15px;
					font-size: 30px;
					margin: 5px;

					transition: background-color 0.5s, border-color 0.5s;

					&.selected {
						background-color: #FEC6C7;
						border-color: #FDA3A3;
					}
					&.completed {
						background-color: #C10D12;
						border-color: #A51116;
						color: #fff;
					}
				}
				.direction-name {
					text-align: center;
					font-size: 45px;
					color: #D1D1D1;
					margin-top: 37px;
					margin-bottom: 30px;
				}
			}
		}

		.empty {
			margin-top: 273px;
			font-size: 22px;
			color: #5D5D5D;
			text-align: center;

			.button {
				position: fixed;
				left: 0;
				bottom: 120px;
				width: 100%;

			}
		}

	}
</style>