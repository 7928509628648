<template lang="pug">
	.direction-wrap()
		v-card.elevation-0
			v-card-title 완료 목록
			v-card-actions
				v-spacer
				v-select.flex-grow-0(
					v-model="team"
					dense
					hide-details
					outlined
					:items="teams"
					@change="getDirections"
				)
			v-card-text
				v-card(
					style="width: 75%; margin: 0 auto;"
				)
					v-card-text
						v-data-table(
							:headers="headers"
							:items="directions"
							:items-per-page="-1"
							:loading="isLoading"
							loading-text="데이터 조회 중.. 잠시만 기다리세요."
							hide-default-footer
						)
</template>

<script>
	export default {
		data() {
			return {
				isLoading: false,
				teams: ['전체'],
				team: '전체',
				directions: [],
				headers: [
					{text: '시간', sortable: true, value: 'time'},
					{text: '팀', sortable: true, value: 'team'},
					{text: '완료한 프로젝트', sortable: true, value: 'type_order'},
					{text: '사용한 설계도', sortable: true, value: 'direction_type'},
				],
			}
		},
		async mounted() {
			this.$adminData.tabIndex = 2
			this.$adminData.tabSubIndex = 1

			_.range(1, this.training.team_count + 1).forEach(v => {
				this.teams.push(v + '팀')
			})

			await this.getDirections()
		},

		methods: {
			async getDirections() {
				this.isLoading = true

				const team = this.team === '전체' ? '' : this.team
				const response = await this.$get('/admin/training/directions/completed', { id: this.training.id, team: team })

				this.directions = response.data

				this.isLoading = false
			}
		}
	}
</script>

<style lang="scss" scoped>
	.direction-wrap {
		margin-top: 20px;
		padding: 10px;

		.subtitle {
			color: #C00000;
		}
	}
</style>
