<template lang="pug">
	div.tab-wrap
		v-layout.tab-container(
			v-if="summaries"
			column
			align-center
		)
			.progress
				v-image.background(src="background_dashboard_progress.png")
				.plan
					.value.value1 {{ progressCount('plan', 1) }}
					.value.value2 24개
					.value.value3 {{ progressPercent('plan', 1) }}%
					.value.value4 {{ progressCount('plan', 2) }}
					.value.value5 24개
					.value.value6 {{ progressPercent('plan', 2) }}%
				.buy
					.value.value1 {{ progressCount('buy', 1) }}
					.value.value2 {{ progressCount('plan', 1) }}개
					.value.value3 {{ progressPercent('buy', 1) }}%
					.value.value4 {{ progressCount('buy', 2) }}
					.value.value5 {{ progressCount('plan', 2) }}개
					.value.value6 {{ progressPercent('buy', 2)}}%
				.make
					.value.value1 {{ progressCount('make', 1) }}
					.value.value2 {{ progressCount('buy', 1) }}개
					.value.value3 {{ progressPercent('make', 1) }}%
					.value.value4 {{ progressCount('make', 2) }}
					.value.value5 {{ progressCount('buy', 2) }}개
					.value.value6 {{ progressPercent('make', 2)}}%
			v-layout.detail(align-center column)
				.plan
					v-image.background(src="background_dashboard_plan.png")
					.value.value1 {{ summaries.outgoings.plan }}
					.value.value2 /{{ summaries.idealOutgoings.plan }}p
					.value.value3 {{ spentTime('plan', 'low') }}
					.value.value4 {{ totalSpentTime('plan', 'low') }}분
					.value.value5 {{ spentTime('plan', 'medium') }}
					.value.value6 {{ totalSpentTime('plan', 'medium') }}분
					.value.value7 {{ spentTime('plan', 'high') }}
					.value.value8 {{ totalSpentTime('plan', 'high')}}분
				.buy
					v-image.background(src="background_dashboard_buy.png")
					.value.value1 {{ summaries.outgoings.buy }}
					.value.value2 /{{ summaries.idealOutgoings.buy }}p
					.value.value3 {{ spentTime('buy', 'low') }}
					.value.value4 {{ totalSpentTime('buy', 'low') }}분
					.value.value5 {{ spentTime('buy', 'medium') }}
					.value.value6 {{ totalSpentTime('buy', 'medium') }}분
					.value.value7 {{ spentTime('buy', 'high') }}
					.value.value8 {{ totalSpentTime('buy', 'high')}}분
					.value.value9 {{ summaries.outgoings.deliveryFee }}
					.value.value10 /{{ summaries.outgoings.totalDeliveryFee }}p
				.make
					v-image.background(src="background_dashboard_make.png")
					.value.value1 {{ summaries.outgoings.make }}
					.value.value2 /{{ summaries.idealOutgoings.make }}p
					.value.value3 {{ spentTime('make', 'low') }}
					.value.value4 {{ totalSpentTime('make', 'low') }}분
					.value.value5 {{ spentTime('make', 'medium') }}
					.value.value6 {{ totalSpentTime('make', 'medium') }}분
					.value.value7 {{ spentTime('make', 'high') }}
					.value.value8 {{ totalSpentTime('make', 'high')}}분
					.value.value11 {{ summaries.resendInspects }}
					.value.value12 /{{ summaries.totalResendInspects }}

</template>

<script>

	export default {
		data() {
			return {
				summaries: null,
			}
		},

		computed: {
		},
		watch: {
		},

		beforeDestroy() {
		},

		async mounted() {
			await this.getSummaries()
		},

		methods: {
			async getSummaries() {
				const response = await this.$get('/summaries')
				this.summaries = response.data
			},
			progressCount(part, round) {
				const item = this.summaries.progress[part].find(v => v.round === round)
				if (round === 1) {
					if (!item) return 0
					return item.count
				} else if (round === 2) {
					const round1 = this.progressCount(part, 1)
					if (!item) return round1
					return round1 + item.count
				}
			},
			progressPercent(part, round) {
				let denominator
				switch (part) {
					case 'plan':
						denominator = 24
						break;
					case 'buy':
						denominator = this.progressCount('plan', round)
						break;
					case 'make':
						denominator = this.progressCount('buy', round)
						break;
				}
				const count = this.progressCount(part, round)

				return Math.round(count / denominator * 100)
			},
			spentTime(part, type) {
				const minutes = this.summaries.timeSpent[part][type]
				const count = this.summaries.timeSpent[part][`${type}Count`]

				if (count === 0) return 0

				return Math.round(minutes / count)
			},
			totalSpentTime(part, type) {
				const minutes = this.summaries.totalTimeSpent[part][type]
				const count = this.summaries.totalTimeSpent[part][`${type}Count`]

				if (count === 0) return 0

				return Math.round(minutes / count)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.tab-wrap {
		height: 100%;

		.tab-container {
			padding-bottom: 50px;

			.progress {
				position: relative;
				background-color: #F7F7F7;
				margin: 45px auto 25px;

				.value {
					position: absolute;
					width: 50px;
					text-align: right;
					&.value1 {
						font-size: 27px;
						color: #fff;
					}
					&.value2 {
						left: 51px;
						top: 11px;
						font-size: 17px;
						color: #969696;
					}
					&.value3 {
						left: 114px;
						top: 0;
						width: 80px;
						text-align: center;
						font-size: 27px;
						color: #222222;
					}
					&.value4 {
						left: 230px;
						font-size: 27px;
						color: #fff;
					}
					&.value5 {
						left: 281px;
						top: 11px;
						font-size: 17px;
						color: #969696;
					}
					&.value6 {
						left: 344px;
						top: 0;
						width: 80px;
						text-align: center;
						font-size: 27px;
						color: #222222;
					}
				}

				.plan {
					position: absolute;
					left: 105px;
					top: 113px;
					width: 575px;
				}
				.buy {
					position: absolute;
					left: 105px;
					top: 235px;
					width: 575px;
				}
				.make {
					position: absolute;
					left: 105px;
					top: 357px;
					width: 575px;
				}
			}

			.detail {
				width: 100%;
				background-color: #FFFFFF;
				padding-top: 25px;
				padding-bottom: 50px;

				&>div {
					position: relative;
					padding-bottom: 25px;
				}
				.value {
					position: absolute;
					font-size: 27px;
					color: #222222;
					width: 40px;
					text-align: right;
				}

				.value1 {
					left: 77px;
					top: 131px;
					width: 100px;
					text-align: center;
					font-weight: bold;
				}
				.value2 {
					left: 77px;
					top: 169px;
					width: 100px;
					text-align: center;
					font-size: 17px;
					color: #969696;
				}
				.value3 {
					left: 33px;
					top: 324px;
					font-weight: bold;
				}
				.value4 {
					left: 95px;
					top: 334px;
					font-size: 17px;
					color: #969696;
				}
				.value5 {
					left: 200px;
					top: 324px;
					font-weight: bold;
				}
				.value6 {
					left: 262px;
					top: 334px;
					font-size: 17px;
					color: #969696;
				}
				.value7 {
					left: 367px;
					top: 324px;
					font-weight: bold;
				}
				.value8 {
					left: 429px;
					top: 334px;
					font-size: 17px;
					color: #969696;
				}
				.value9 {
					left: 334px;
					top: 131px;
					width: 100px;
					text-align: center;
					font-weight: bold;
				}
				.value10 {
					left: 334px;
					top: 169px;
					width: 100px;
					text-align: center;
					font-size: 17px;
					color: #969696;
				}
				.value11 {
					left: 345px;
					top: 140px;
					width: 50px;
					text-align: center;
					font-weight: bold;
				}
				.value12 {
					left: 377px;
					top: 150px;
					width: 50px;
					text-align: center;
					font-size: 17px;
					color: #969696;
				}
			}
		}
	}
</style>