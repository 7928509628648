import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: require('../components/login/Login').default
	},
	{
		name: 'Main',
		path: '/',
		component: require('../components/frontend/Main').default,
	},

	{

		name: 'SuperAdminMain',
		component: require('../components/admin/Main').default,
		path: '/manage',
		children: [

			// Super admin
			{
				name: 'AdminUser',
				path: '/manage/users',
				component: require('../components/admin/superadmin/User').default,
			},
			{
				name: 'AdminMonitoring',
				path: '/manage/monitoring',
				component: require('../components/admin/superadmin/Monitoring').default,
			},
			{
				name: 'AdminSettingMoney',
				path: '/manage/setting/money',
				component: require('../components/admin/superadmin/SettingMoney').default,
			},
			{
				name: 'AdminSettingCall',
				path: '/manage/setting/call',
				component: require('../components/admin/superadmin/SettingCall').default,
			},
			{
				name: 'AdminSettingDirection',
				path: '/manage/setting/direction',
				component: require('../components/admin/superadmin/SettingDirection').default,
			},
			{
				name: 'AdminSettingGame',
				path: '/manage/setting/game',
				component: require('../components/admin/superadmin/SettingGame').default,
			},
		]
	},

	{
		name: 'AdminMain',
		component: require('../components/admin/Main').default,
		path: '/admin',
		children: [

			// Admin
			{
				name: 'AdminTrainings',
				path: '/admin/trainings',
				component: require('../components/admin/Trainings').default,
			},
			{
				name: 'AdminTraining',
				path: '/admin/training/:id?',
				component: require('../components/admin/Training').default,
			},
			{
				name: 'AdminPlanDirection',
				path: '/admin/plan/direction',
				component: require('../components/admin/PlanDirection').default,
			},
			{
				name: 'AdminPlanCompleted',
				path: '/admin/plan/completed',
				component: require('../components/admin/PlanCompleted').default,
			},
			{
				name: 'AdminBuyGame',
				path: '/admin/buy/game',
				component: require('../components/admin/BuyGame').default,
			},
			{
				name: 'AdminBuyCompleted',
				path: '/admin/buy/completed',
				component: require('../components/admin/BuyCompleted').default,
			},
			{
				name: 'AdminMakeInspection',
				path: '/admin/make/inspection',
				component: require('../components/admin/MakeInspection').default,
			},
			{
				name: 'AdminMakeOwned',
				path: '/admin/make/owned',
				component: require('../components/admin/MakeOwned').default,
			},
			{
				name: 'AdminPoint',
				path: '/admin/point',
				component: require('../components/admin/Point').default,
			},
			{
				name: 'AdminResult',
				path: '/admin/result',
				component: require('../components/admin/Result').default,
			},
		]
	},
	{
		name: 'StatusBoard',
		component: require('../components/status/Status').default,
		path: '/status',
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
