<template lang="pug">
	.module-wrap.pa-5
		v-card
			v-card-actions
				v-spacer
				v-btn(color="secondary" @click="downloadImage") 매출 캡쳐
				v-btn(color="secondary" @click="downloadExcel") 엑셀 다운로드
			v-card-text
				div(style="width: 70%; margin: 0 auto")
					v-card#ranking
						v-card-text
							v-simple-table(:class="capture")
								template
									thead
										tr
											th(style="width: 20%") 팀
											th(style="width: 20%") 기획파트
											th(style="width: 20%") 구매파트
											th(style="width: 20%") 제작파트
											th(style="width: 20%") 최종 매출
									tbody
										tr(
											v-for="(team, index) in points"
											:key="index"
										)
											td {{ team.team }}팀
											td {{ team.capital_plan.toLocaleString() }}p
											td {{ team.capital_buy.toLocaleString() }}p
											td {{ team.capital_make.toLocaleString() }}p
											td {{ team.total.toLocaleString() }}p
										tr.text-center(
											v-if="points.length === 0"
										)
											td(colspan="5") 데이터가 없습니다
</template>

<script>
	import Excel from "exceljs";
	import domtoimage from 'dom-to-image'

	export default {
		data() {
			return {
				points: [],
				capture: '',
			}
		},
		mounted() {
			this.getStatus()
		},
		methods: {
			async getStatus() {
				const response = await this.$get('/admin/point/status', { id: this.training.id, by_ranking: true })

				this.points = response.data.map(v => {
					v.total = v.capital_plan + v.capital_buy + v.capital_make

					return v
				})
			},
			downloadImage() {
				const self = this
				this.$store.commit('showLoader')
				this.capture = 'capture'
				domtoimage.toJpeg(document.getElementById('ranking'), { quality: 0.95, cacheBust: true })
					.then(function (dataUrl) {
						const link = document.createElement('a');
						link.download = `${self.training.name}과정_매출_${moment().format('YYYY-MM-DD')}.jpeg`;
						link.href = dataUrl;
						link.click();
						self.$store.commit('hideLoader')
						self.capture = ''
					});

			},
			async downloadExcel() {

				const wb = new Excel.Workbook();
				const ws = wb.addWorksheet('Export');

				const border = {
					top: {style: 'thin'},
					left: {style: 'thin'},
					bottom: {style: 'thin'},
					right: {style: 'thin'},
				}
				ws.columns = [
					{header: '팀', key: 'team', width: 20},
					{header: '기획파트', key: 'capital_plan', width: 30},
					{header: '구매파트', key: 'capital_buy', width: 20},
					{header: '제작파트', key: 'capital_make', width: 20},
					{header: '최종매출', key: 'total', width: 20},
				]
				ws.getRow(1).eachCell(function(cell, colNumber) {
					cell.font = {
						bold: true,
					}
					cell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor: {argb: 'FFEFEFEF'}
					}
					cell.border = {
						top: {style:'thin'},
						left: {style:'thin'},
						bottom: {style:'thin'},
						right: {style:'thin'}
					};
					cell.alignment = {
						horizontal: "center"
					}
				})
				const points = _.cloneDeep(this.points)
				points.forEach(item => {
					item.team = item.team + '팀'
					item.capital_plan = item.capital_plan.toLocaleString() + 'p'
					item.capital_buy = item.capital_buy.toLocaleString() + 'p'
					item.capital_make = item.capital_make.toLocaleString() + 'p'
					item.total = item.total.toLocaleString() + 'p'

					const row = ws.addRow(item)
					row.eachCell(function(cell) {
						cell.border = {
							top: {style:'thin'},
							left: {style:'thin'},
							bottom: {style:'thin'},
							right: {style:'thin'}
						};
						cell.alignment = {
							horizontal: "center"
						}
					})
				})

				const filename = `${this.training.name}_매출_${moment().format('YYYYMMDD')}.xlsx`
				wb.xlsx.writeBuffer( {
					base64: true
				})
					.then( function (xls64) {
						// build anchor tag and attach file (works in chrome)
						const a = document.createElement("a");
						const data = new Blob([xls64], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

						const url = URL.createObjectURL(data);
						a.href = url;
						a.download = filename;
						document.body.appendChild(a);
						a.click();
						setTimeout(function() {
								document.body.removeChild(a);
								window.URL.revokeObjectURL(url);
							},
							0);
					})
					.catch(function(error) {
						console.log(error.message);
					});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.capture * {
			color: #000 !important;
	}
</style>