<template lang="pug">
	div.tab-wrap
		div.item-wrap
			div.item(
				v-for="product in products"
				:class="stockClass(product)"
			)
				div.name-wrap
					v-layout.name(justify-start align-end)
						span {{ product.label }}
						span.stock(v-if="product.stock !== undefined")
							span(v-if="trainingData[product.stock] > 0") (재고 : {{ trainingData[product.stock] }})
							span(v-else) (재고 없음)
					div.owned 현재 보유 개수 : {{ inventoryOwned(product) }}
				div.price-wrap
					v-image.stock-warning(
						src="icon_stock_warning.png"
					)
					span.price {{ setting[product.price] }}
					span p
				v-layout.amount-wrap
					v-image(src="btn_minus.png"
						v-if="product.amount > 0"
						@click="onStepperClick(product, -1)"
					)
					v-image(src="btn_minus_disabled.png"
						v-else
					)
					v-layout.amount(align-center justify-center) {{ product.amount }}
					v-image(src="btn_plus.png"
						v-if="(totalPrice + setting[product.price]) <= capital && (!product.stock || trainingData[product.stock] > product.amount)"
						@click="onStepperClick(product, 1)"
					)
					v-image(src="btn_plus_disabled.png"
						v-else
					)
				v-layout.btn-buy(
					justify-center
					:class="!canBuy ? 'disabled' : ''"
				)
					span
						v-image(
							v-if="canBuy"
							src="btn_buy.png"
							@click="confirmBuy"
						)
						v-image(src="btn_buy_disabled.png"
							v-else
						)
						div.price(
						) {{ totalPrice }}
</template>

<script>
	export default {

		data() {
			return {
				products: [
					{label: '일반 설계도', name: 'normal_direction', price: 'price_normal_direction', owned: 0, amount: 0},
					{label: '고급 설계도', name: 'advanced_direction', price: 'price_advanced_direction', owned: 0, amount: 0},
					{label: '자', name: 'ruler', stock: 'stock_ruler', price: 'price_ruler', owned: 0, amount: 0},
					{label: '색연필', name: 'pencil', stock: 'stock_pencil', price: 'price_pencil', owned: 0, amount: 0},
				],
			}
		},

		computed: {
			setting() {
				return this.$userData.setting
			},
			user() {
				return this.$userData.user
			},
			capital() {
				return this.$userData.user.capital
			},
			canBuy() {
				let canBuy = true
				if (this.totalPrice === 0) canBuy = false

				this.products.forEach(product => {
					if (product.stock) {
						if (product.amount > 0 && product.amount > this.trainingData[product.stock]) {
							canBuy = false
						}
					}
				})
				if (!canBuy) this.$hideDialog()

				return canBuy
			},
			totalPrice() {
				let totalPrice = 0
				this.products.forEach(product => {
					if (product.amount > 0) {
						totalPrice += this.setting[product.price] * product.amount
					}
				})

				return totalPrice
			}
		},

		watch: {
		    stocks(val) {
		        let soldOut = false

			    this.products.forEach(product => {

			    })

			    if (soldOut) {
			        this.$showToast('stock_not_enough')
			    }
		    }
		},

		mounted() {
		},

		methods: {
			inventoryOwned(product) {
				const inventory = this.inventories.find(v => v.name === product.name && v.part === this.user.part)
				return inventory ? inventory.amount : 0
			},
			stockClass(product) {
				// :class="product.stock !== undefined && trainingData[product.stock] === 0 ? (product.amount === 0 ? 'sold-out' : 'unbuyable') : ''"

				if (!product.stock) return ''

				if (this.trainingData[product.stock] < product.amount || this.trainingData[product.stock] === 0) {
					if (product.amount === 0) return 'sold-out'
					else {
						this.$showToast('stock_not_enough')
						return 'unbuyable'
					}
				}
				return ''
			},
			onStepperClick(product, amount) {
				if (product.amount < 1 && amount === -1)  return

				product.amount += amount
			},
			confirmBuy() {
                if (this.$userData.user.capital < this.totalPrice) {
                    this.$showToast('point_not_enough')
                    return
                }
				this.$showDialog({
					type: 'plan1',
					buttons: [
						{ type: 'cancel' },
						{ type: 'buy', click: this.buy },
					]
				})
			},
			async buy() {

			    if (this.$userData.user.capital < this.totalPrice) {
			        this.$showToast('point_not_enough')
				    return
			    }
				const products = this.products.filter(v => v.amount > 0).map(v => {
					return { name: v.name, amount: v.amount }
				})

				const response = await this.$post('/inventory', { inventories: products, price: this.totalPrice })
				if (response.code !== '0000') {
					if (response.code === '9501') {
						this.$showToast('point_not_enough')
					} else {
						this.$showToast('stock_not_enough')
					}
					return
				}

				this.$userData.user.capital -= this.totalPrice
				this.products.forEach(v => v.amount = 0)

				this.$publishMqtt('training', 'reload', {})
                this.$showToast('buy_succeed')

			}
		}
	}
</script>

<style lang="scss" scoped>
	.tab-wrap {
		padding-bottom: 140px;

		.item-wrap {
			padding: 38px 54px 150px;

			.item {
				position: relative;

				height: 140px;
				background-color: #fff;
				border: 1px solid #D1D1D1;
				border-radius: 15px;

				line-height: 1;

				margin-bottom: 16px;

				.name-wrap {
					position: absolute;
					left: 26px;
					top: 20px;
					color: #C10D0D;
					font-size: 30px;

					.name {
						.stock {
							margin-left: 8px;
							font-size: 22px;
							line-height: 1.3;
						}
					}

					.owned {
						color: #222222;
						font-size: 17px;
						margin-top: 12px;
					}
				}

				.price-wrap {
					position: absolute;
					right: 25px;
					top: 15px;
					color: #C10D0D;
					font-size: 32px;

					.stock-warning {
						display: none;
						position: absolute;
						transform: translate(-50px, -3px);
					}

					.price {
						font-size: 40px;
						font-weight: 600;
						padding-right: 10px;
					}
				}

				.amount-wrap {
					position: absolute;
					right: 25px;
					bottom: 15px;

					.amount {
						width: 135px;
						height: 45px;
						background-color: #F0F0F0;
						font-size: 27px;
						padding-top: 5px;
					}
				}

				&.sold-out {
					pointer-events: none;
					background-color: #E0E0E0;
					border: 1px solid #D1D1D1;

					.name-wrap {
						color: #BCBCBC;
					}

					.price-wrap, .amount-wrap {
						display: none;
					}
				}
				&.unbuyable {
					background-color: #FEC6C7;
					border: 1px solid #FDA3A3;

					.stock-warning {
						display: block;
					}
					.name-wrap {
					}
				}

			}
		}

		.btn-buy {
			position: fixed;
			bottom: 115px;
			left: 0;
			width: 100%;

			cursor: pointer;

			span {
				position: relative;
			}

			.price {
				position: absolute;
				right: 233px;
				top: 35px;
				color: #C10D0D;
				font-size: 40px;
				font-weight: 600;
				line-height: 1;

				pointer-events: none;
			}

			&.disabled {
				cursor: default;

				.price {
					color: #ACACAC;
				}
			}
		}
	}
</style>