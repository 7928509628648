<template lang="pug">
	.setting-wrap
		v-card.elevation-0
			v-card-title 운송게임 설정
			v-card-subtitle.subtitle ※ 설정 탭에서 변경한 데이터는 이전 교육에는 적용되지 않으며, 앞으로 추가하는 교육에만 반영됩니다.

			v-card-text
				v-card
					v-card-subtitle.font-weight-bold 등급별 운송비 설정
					v-card-text
						v-layout(justify-center)
							v-simple-table(style="width: 400px;")
								template
									thead
										tr
											th 등급
											th 운송비
									tbody
										tr(
											v-for="n in 4"
											:key="n"
										)
											td {{ n }}등급
											td
												v-layout(align-center)
													input.ml-2(
														type="number"
														min="0"
														v-model="settings[`delivery_fee${n}`]"
														style="width: 70px;"
													)
													span.ml-1 p
					v-card-actions
						v-spacer
						v-btn(color="secondary" @click="save") 저장
			v-card-text
				v-card
					v-card-subtitle.font-weight-bold 시간 설정
					v-card-text
						v-layout(column justify-center align-center)
							v-layout(align-center)
								div
									span 운송게임 간격 :
									input.ml-2(
										type="number"
										min="0"
										v-model="settings.delivery_game_interval"
										style="width: 80px;"
									)
									span.ml-1 분
								div.ml-10
									span 운송게임 진행 시간 :
									input.ml-2(
										type="number"
										min="0"
										v-model="settings.delivery_game_time"
										style="width: 80px;"
									)
									span.ml-1 분
					v-card-actions
						v-spacer
						v-btn(color="secondary" @click="save") 저장
			v-card-text
				v-card
					v-card-subtitle.font-weight-bold 운송게임 정답 설정
					v-card-text
						v-layout(justify-center)

						v-layout(justify-center)
							v-simple-table(style="width: 700px;")
								template
									thead
										tr
											th 순서
											th 운송게임 문제 및 정답
											th
									tbody
										tr(
											v-if="answers.length"
											v-for="(n, index) in 10"
											:key="n"
										)
											td {{ n }}
											td
												v-layout(align-center)
													input.ml-2.answer(
														type="number"
														min="0"
														v-for="no in 7"

														v-model="answers[index][`answer${no}`]"
														style="width: 60px;"
														:class="no === 1 ? 'start' : no === 4 ? 'waypoint' : no === 7 ? 'destination' : ''"
													)
											td
												v-btn(
													small
													color="secondary"
													@click="saveAnswer(index)"
												) 저장
</template>

<script>
	export default {
		data() {
			return {
				settings: {
					delivery_fee1: 0,
					delivery_fee2: 0,
					delivery_fee3: 0,
					delivery_fee4: 0,
					delivery_game_interval: 0,
					delivery_game_time: 0,
				},

				answers: []
			}
		},
		mounted() {
			this.$adminData.tabIndex = 2
			this.$adminData.tabSubIndex = 3

			this.getSettings()
		},

		methods: {
			async getSettings() {
				let response
				response = await this.$get('/admin/setting')
				if (response.code === '0000') {
					const data = response.data
					Object.keys(this.settings).forEach(k => {
						this.settings[k] = data[k]
					})
				}

				response = await this.$get('/admin/setting/deliveries')
				if (response.code === '0000') {
					const data = response.data
					const answers = []

					for (let no=1; no<=10; no++) {
						const answer = data.find(v => v.no === no)
						if (answer) {
							answers.push(answer)
						} else {
							answers.push({
								no: no,
							})
						}
					}
					this.answers = answers
				}
			},
			async save() {
				this.$store.commit('showLoader')
				await this.$post('/admin/setting', this.settings)
				this.$store.commit('hideLoader')
			},

			async saveAnswer(index) {
				this.$store.commit('showLoader')
				const answer = this.answers[index]

				await this.$post('/admin/setting/delivery', answer)
				this.$store.commit('hideLoader')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.setting-wrap {
		margin-top: 20px;
		padding: 10px;

		.subtitle {
			color: #C00000;
		}

		input {
			border: 1px solid #000;
			padding: 5px 0 5px 10px;
			background-color: #fff;
		}

		.answer {
			text-align: center;

			&.start {
				background-color: #FBE5D6;
			}
			&.waypoint {
				background-color: #FFF2CC;
			}
			&.destination {
				background-color: #E2F0D9;
			}
		}
	}
</style>