<template lang="pug">
	.container-wrap.mt-7.ml-3.pb-1
		v-card.elevation-0
			v-card-title 제작 검수
			v-card-actions
				v-spacer
				v-select.flex-grow-0(
					v-model="team"
					dense
					hide-details
					outlined
					:items="teams"
					@change="getInspections"
				)
			v-card(
				style="width: 75%; margin: 0 auto;"
			)
				v-card-subtitle 검수 요청
				v-card-text
					v-data-table(
						:headers="requestHeaders"
						:items="inspections.filter(v => !v.proceeded)"
						:items-per-page="-1"
						:loading="isLoading"
						loading-text="데이터 조회 중.. 잠시만 기다리세요."
						hide-default-footer
					)
						template(
							v-slot:item.inspect="{ item }"
						)
							v-btn(
								color="secondary"
								@click="showAnswer(item)"
							) 정답 확인
			v-card.mt-10(
				style="width: 75%; margin: 0 auto;"
			)
				v-card-subtitle 검수 완료
				v-card-text
					v-data-table(
						:headers="completeHeaders"
						:items="inspections.filter(v => !!v.passed)"
						:items-per-page="-1"
						:loading="isLoading"
						loading-text="데이터 조회 중.. 잠시만 기다리세요."
						hide-default-footer
					)
						template(v-slot:item.id="{ item }") {{ item.time }}
		v-dialog(
			v-model="showDialog"
			content-class="answer-dialog"
		)
			v-card(v-if="direction && selected")
				v-card-title.justify-center &lt;{{ selected.type_order }}&gt; 제작 검수
				v-card-text.pt-7.pb-7
					v-layout(
						justify-center align-center
					)
						img(:src="direction.answer_image1" v-if="direction.answer_image1" @click="showImage(direction.answer_image1)")
						img.ml-2(:src="direction.answer_image2" v-if="direction.answer_image2" @click="showImage(direction.answer_image2)")
						img.ml-2(:src="direction.answer_image3" v-if="direction.answer_image3" @click="showImage(direction.answer_image3)")
				v-card-actions
					v-layout(justify-center)
						v-btn(@click="deny") 반려
						v-btn.ml-2(
							color="secondary"
							@click="accept"
						) 승인
		v-dialog(
			v-model="showImageDialog"
		)
			v-card
				v-card-text
					v-layout.fill-height(justify-center align-center)
						img(:src="image" @click="closeImage")
				v-card-actions
					v-layout(justify-center)
						v-btn(
							color="secondary"
							@click="closeImage"
						) 확인
</template>

<script>
	export default {
		data() {
			return {
				isLoading: false,
				teams: ['전체'],
				team: '전체',
				inspections: [],
				directions: [],
				direction: null,
				selected: null,
				requestHeaders: [
					{text: '팀', sortable: true, value: 'team', width: '33%'},
					{text: '제작품', sortable: true, value: 'type_order', width: '33%'},
					{text: '검수', sortable: true, value: 'inspect'},
				],
				completeHeaders: [
					{text: '시간', sortable: true, value: 'id', width: '33%'},
					{text: '팀', sortable: true, value: 'team', width: '33%'},
					{text: '제작품', sortable: true, value: 'type_order'},
				],
				image: null,
				showDialog: false,
				showImageDialog: false,
				legos: [],
			}
		},
		computed: {
			mqttData() {
				return this.$gameData.mqttData
			}
		},
		watch: {
			mqttData(val) {
				if (val.action === 'inspection') {
					if (this.selected) {
						if (val.type === this.selected.type && val.order === this.selected.order) {
							this.selected = null
							this.showDialog = false
							this.showImageDialog = false
							this.$store.commit('hideDialog')

							if (val.passed === 1) {
								this.$store.commit('showSnackbar', { message: '다른 관리자가 먼저 승인 처리했습니다.' })
							} else {
								this.$store.commit('showSnackbar', { message: '다른 관리자가 먼저 반려 처리했습니다.' })
							}
						}
					}
					this.getInspections()
				}
			}
		},
		mounted() {
			this.$adminData.tabIndex = 4
			this.$adminData.tabSubIndex = 0

			_.range(1, this.training.team_count + 1).forEach(v => {
				this.teams.push(v + '팀')
			})

			this.getDirections()
			this.getInspections()
			this.getLegos()
		},

		methods: {
			async getDirections() {
				const response = await this.$get('/admin/training/directions', { id: this.training.id })

				const directions = response.data
				directions.forEach(direction => {
					direction.legos = JSON.parse(direction.legos)
				})
				this.directions = directions
			},

			async getInspections() {

				const team = this.team === '전체' ? '' : this.team
				const response = await this.$get('/admin/training/inspections', { id: this.training.id, team: team })

				this.inspections = response.data
			},
			async getLegos() {

				const response = await this.$get('/admin/training/lego/owned', { id: this.training.id })

				const legos = []
				response.data.forEach(lego => {
					const item = legos.find(v => v.team === lego.team)

					if (!item) {
						legos.push({
							team: lego.team,
							legos: [lego]
						})
					} else {
						item.legos.push(lego)
					}
				})

				this.legos = legos
			},

			showAnswer(item) {
				this.selected = item
				this.direction = this.directions.find(v => v.type === item.type && v.order === item.order)
				this.showDialog = true
			},
			async deny() {
				const response = await this.$post('/admin/training/inspection', { ...this.selected, action: 'deny' })
				if (response.code !== '0000') {
					this.$store.commit('showSnackbar', {
						message: '이미 다른 관리자가 검수처리했습니다.'
					})
					return
				}

				this.showDialog = false
				await this.getInspections()

				this.publish('deny')
			},
			async accept() {
				let response
				response = await this.$get('/admin/training/lego/owned', { id: this.training.id, team: this.selected.team })

				let owned = response.data
				const legos = _.cloneDeep(this.direction.legos)

				let ownedAll = true
				for (let lego of legos) {
					const ownedItem = owned.find(v => v.name === lego.lego_name)
					if (!ownedItem) {
						lego.amount = 0
						ownedAll = false
					} else {
						if (ownedItem.amount < lego.amount) {
							lego.amount = ownedItem.amount
							ownedAll = false
						}
					}
				}
				if (!ownedAll) {
					this.$store.commit('showDialog', {
						type: 'confirm',
						message: '제작에 필요한 레고가 부족합니다.\n그래도 승인하시겠습니까?',
						okCb: async _ => {
							const response = await this.$post('/admin/training/inspection', { ...this.selected, action: 'accept', legos: legos })
							if (response.code !== '0000') {
								this.$store.commit('showSnackbar', {
									message: '이미 다른 관리자가 검수처리했습니다.'
								})
								this.showDialog = false
								return
							}

							this.showDialog = false
							await this.getInspections()
							this.selected.passed = 1
							this.publish()
						},

					})
					return
				}

				await this.$post('/admin/training/inspection', { ...this.selected, action: 'accept', legos: legos })

				this.showDialog = false
				await this.getInspections()
				this.selected.passed = 1
				this.publish()
			},
			publish() {
				// this.$publishMqtt('team', 'inspection', this.selected)
				this.$publish({
					type: 'team',
					trainingId: this.training.id,
					team: this.selected.team,
					part: this.selected.part,
					action: 'inspection',
					message: this.selected
				})

				this.selected = null
				this.showDialog = false
				this.showImageDialog = false
			},
			showImage(image) {
				this.image = image
				this.showImageDialog = true
			},
			closeImage() {
				this.showImageDialog = false
				this.image = null
			}
		}
	}
</script>

<style lang="scss" scoped>

	.container-wrap {
		margin-top: 20px;
		padding: 10px;

		.subtitle {
			color: #C00000;
		}
	}

	.answer-dialog {
		img { max-width: 33%; cursor: pointer }
	}
</style>
