<template lang="pug">
	div.tab-wrap
		v-layout.tab-container(
			column
			align-center
		)
			v-layout.header
				.column1(@click="setSort('time')")
					span 시간
					v-image.icon-sort(
						v-if="sort.name === 'time'"
						:src="`icon_collapse_${sort.order === 'asc' ? 'up' : 'down'}.png`"
					)
				.column2(@click="setSort('project')")
					span 프로젝트
					v-image.icon-sort(
						v-if="sort.name === 'project'"
						:src="`icon_collapse_${sort.order === 'asc' ? 'up' : 'down'}.png`"
					)
				.column3 파트
				.column4 수행 내역
			.timeline-item(
				v-for="(item, index) in timeline"
			)
				v-layout.item(
					align-center
					:class="className(item)"
					@click="toggleCollapse(item, index)"
				)
					.column1 {{ item.time }}
					.column2 {{ item.project }}
					.column3 {{ partNames[item.part] }}
					.column4 {{ item.name2 }}
						span.icon(v-if="['remittance','inspection'].indexOf(className(item)) > -1")
							v-image(v-if="item.expanded" src="icon_collapse_up.png")
							v-image(v-else src="icon_collapse_down.png")
				v-layout.inspection-detail(
					justify-center align-center
					v-if="className(item) === 'inspection'"
					:class="item.expanded ? 'expanded' : ''"
				) 총 소요시간
					span.minutes {{ item.time_spent }}분
				v-layout.remittance-detail(
					justify-center align-center
					v-if="className(item) === 'remittance'"
					:class="item.expanded ? 'expanded' : ''"
				)
					table.remittance-table(cellspacing="0")
						tr
							th.tcolumn1
							th.tcolumn2 송금 전
							th.tcolumn3
							th.tcolumn4 송금 후
						tr
							th.tcolumn1 기획
							td.tcolumn2(:class="remittanceClass(item, 'before', 'plan')") {{ item.remittance.before.plan }}p
							td.tcolumn3
								v-image(src="icon_to.png")
							td.tcolumn4(:class="remittanceClass(item, 'after', 'plan')") {{ item.remittance.after.plan }}p
						tr
							th.tcolumn1 구매
							td.tcolumn2(:class="remittanceClass(item, 'before', 'buy')") {{ item.remittance.before.buy }}p
							td.tcolumn3
								v-image(src="icon_to.png")
							td.tcolumn4(:class="remittanceClass(item, 'after', 'buy')") {{ item.remittance.after.buy }}p
						tr
							th.tcolumn1 제작
							td.tcolumn2(:class="remittanceClass(item, 'before', 'make')") {{ item.remittance.before.make }}p
							td.tcolumn3
								v-image(src="icon_to.png")
							td.tcolumn4(:class="remittanceClass(item, 'after', 'make')") {{ item.remittance.after.make }}p



</template>

<script>

	export default {
		data() {
			return {
				timeline: [],
				partNames: { plan: '기획', buy: '구매', make: '제작' },
				sort: {
					name: 'time',
					order: 'asc'
				}
			}
		},

		computed: {
		},
		watch: {
		},

		beforeDestroy() {
		},

		async mounted() {
			await this.getTimeline()
		},

		methods: {
			setSort(name) {
				if (this.sort.name === name) {
					this.sort.order = this.sort.order === 'asc' ? 'desc' : 'asc'
				} else {
					this.sort.name = name
					this.sort.order = 'asc'
				}

				this.timeline.sort((a, b) => {
					const { name, order } = this.sort
					if (name === 'time') {
						if (order === 'asc') {
							if (a.id > b.id) return 1
							if (a.id < b.id) return -1
						} else {
							if (a.id > b.id) return -1
							if (a.id < b.id) return 1
						}
					} else if (name === 'project') {
						let projectA = (a.project || '').replace('하', 'A')
							.replace('중', 'B')
							.replace('상', 'C')
						let projectB = (b.project || '').replace('하', 'A')
							.replace('중', 'B')
							.replace('상', 'C')

						if (order === 'asc') {
							if (projectA > projectB) return 1
							if (projectA < projectB) return -1
						} else {
							if (projectA > projectB) return -1
							if (projectA < projectB) return 1
						}
					}
				})

				$('.header').hide()
				setTimeout(_ => {
					$('.header').show()
				}, 1)

			},
			async getTimeline() {
				const response = await this.$get('/timeline')

				const timeline = response.data
				timeline.forEach(item => {
					if (item.remittance) {
						item.remittance = JSON.parse(item.remittance)
					}
				})

				this.timeline = timeline
			},
			className(item) {
				if (item.name2 === '검수 완료') return 'inspection'
				if (item.name2 === 'CALL') return 'call'
				if (item.name2 === '송금') return 'remittance'
				// if (item.name2 === '대출') return 'interest'
			},
			toggleCollapse(item, index) {
				if (['검수 완료', '송금'].indexOf(item.name2) === -1) return

				this.$set(this.timeline[index], 'expanded', !item.expanded)

				$('.page').animate({scrollTop: $('.page').scrollTop()+(item.expanded ? 50 : -50)}, 500)

			},
			remittanceClass(item, when, part) {
				const before = item.remittance['before'][part]
				const after = item.remittance['after'][part]

				if (before === after) return 'unchanged'

				if (when === 'before') return ''

				if (after > before) {
					return 'plus'
				}
				return 'minus'
			}
		}
	}
</script>

<style lang="scss" scoped>
	.tab-wrap {
		height: 100%;

		.tab-container {
			padding-bottom: 100px;
		}

		.column1 {
			width: 140px;
		}
		.column2 {
			width: 140px;
		}
		.column3 {
			width: 110px;
		}
		.column4 {
			position: relative;
			width: 185px;
			padding-right: 20px;

			.icon {
				position: absolute;
				right: 20px;
			}
		}
		.header {
			text-align: center;
			font-size: 22px;
			color: #5D5D5D;
			margin-top: 38px;

			.icon-sort {
				position: absolute;
				margin-top: 5px;
				margin-left: 5px;
				pointer-events: none;
			}
		}

		.timeline-item {
			background-color: #EFEFEF;
			border-radius: 15px;
			margin-top: 10px;
			overflow: hidden;

			.item {
				position: relative;
				height: 80px;
				background-color: #fff;
				border-radius: 15px;
				text-align: center;
				font-size: 26px;
				color: #222222;
				z-index: 1;

				&.remittance {
					background-color: #DAE3F2;
				}
				&.inspection {
					color: #fff;
					background-color: #222222;
				}
				&.call {
					background-color: #E2F0DA;
				}
				&.interest {
					background-color: #FAE5D7;
				}
			}

			.inspection-detail {
				height: 0;
				opacity: 0;
				font-size: 22px;
				color: #222222;
				-webkit-transition: all 0.5s;
				-moz-transition: all 0.5s;
				-o-transition: all 0.5s;
				transition: all 0.5s;

				&.expanded {
					height: 70px;
					opacity: 1;
				}

				.minutes {
					font-size: 27px;
					margin-left: 20px;
					font-weight: bold;
				}
			}
			.remittance-detail {
				height: 0;
				opacity: 0;
				text-align: center;
				-webkit-transition: all 0.5s;
				-moz-transition: all 0.5s;
				-o-transition: all 0.5s;
				transition: all 0.5s;

				&.expanded {
					height: 228px;
					opacity: 1;
				}

				.remittance-table {
					margin-top: 16px;
					margin-bottom: 10px;
					border-collapse: collapse;

					tr {
						&+tr {
							height: 56px;
							border-top: 1px solid #E0E0E0;
						}
					}
					tr:nth-of-type(odd) td {
						background-color: inherit;
					}
					th {
						font-size: 22px;
						font-weight: normal;
					}
					td {
						font-size: 27px;
					}
					.tcolumn1 {
						width: 79px;
					}
					.tcolumn2 {
						width: 111px;
					}
					.tcolumn3 {
						width: 34px;
					}
					.tcolumn4 {
						width: 138px;
						padding-right: 24px;
					}

					.unchanged {
						color: #BCBCBC;
					}
					.plus {
						color: #ED4C4C;
					}
					.minus {
						color: #4C90ED;
					}
				}
			}
		}

	}
</style>