<template lang="pug">
	div.part-wrap(v-if="ready")
		v-layout.tabs
			v-image(src="tab_make1.png" v-if="tab === 0")
			v-image(src="tab_make1_deactive.png" v-else
				@click="changeTab(0)"
			)
			v-image(src="tab_make2.png" v-if="tab === 1")
			v-image(src="tab_make2_deactive.png" v-else
			@click="changeTab(1)"
			)
		div.content-wrap
			transition(name="fade")
				make-tab1.tab(v-if="tab === 0")
				make-tab2.tab(v-if="tab === 1")
</template>

<script>
	import MakeTab1 from "@/components/frontend/modules/MakeTab1";
	import MakeTab2 from "@/components/frontend/modules/MakeTab2";

	export default {
		components: {
			MakeTab1,
			MakeTab2,
		},
		data() {
			return {
				tab: 0,
				ready: false,
			}
		},
		computed: {
			mqttData() {
				return this.$gameData.mqttData
			},
		},
		watch: {
			mqttData(val) {
				// if (val && val.target === 'team' && val.action === 'delivery') {
				// 	if (val.sendType === 'resend') {
				// 		const names = {low: '하', medium: '중', high: '상'}
				// 		this.$showDialog({
				// 			type: 'empty',
				// 			message: `[구매파트]에서\n[${names[val.type]}${val.order}] 설계도 또는 레고를\n다시 발송했습니다.`,
				// 			buttons: [
				// 				{ type: 'ok' }
				// 			]
				// 		})
				// 	}
				// }
			}
		},
		mounted() {
			const index = this.$cookie.get('tabindex')
			if (index !== null) {
			    this.tab = parseInt(index)
			}

			this.ready = true
        },
		methods: {
		    changeTab(index) {
		        this.tab = index

			    this.$cookie.set('tabindex', index)
		    }
		}
    }
</script>

<style lang="scss" scoped>
	.part-wrap {
		width: 100%;
		.tabs {
			position: fixed;
			top: 150px;
			z-index: 1;
		}
		.content-wrap {
			.tab {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
			}
		}
	}
</style>