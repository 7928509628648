<template lang="pug">
	.container-wrap.mt-7.ml-3.pb-1
		v-card.elevation-0
			v-card-title 운송게임 설정
			v-card-text
				v-card
					v-card-actions
						v-spacer
						v-layout.legend.flex-grow-0(align-center)
							span.legend-box.box1
							span 완료
							span.legend-box.box2
							span 진행 중
					v-card-text
						v-layout.pb-10(align-center justify-space-between)
							v-layout.round-wrap(justify-center)
								v-layout.round(
									v-for="n in 10"
									:key="n"
									:class="className(n)"
									justify-center
									align-center
								) {{ n }}
									span.time(
										v-if="deliveryTime(n)"
									) {{ deliveryTime(n) }}
							.buttons.ml-16
								v-btn(
									color="secondary"
									@click="startGame"
								) 시작
								v-btn.ml-2(
									color="primary"
									@click="resetGame"
								) 초기화
			v-card-title 운송게임 정답
			v-card-text
				v-card
					v-card-actions
						v-spacer
						v-layout.legend.flex-grow-0(align-center)
							span.legend-box.answer1
							span 출발지
							span.legend-box.answer2
							span 경유지
							span.legend-box.answer3
							span 도착지
					v-card-text
						v-layout(justify-center)

						v-layout(justify-center)
							v-simple-table(
								v-if="setting.deliveries"
								style="width: 700px;"
							)
								template
									thead
										tr
											th 순서
											th 운송게임 문제 및 정답
									tbody
										tr(
											v-for="(n, index) in 10"
											:key="n"
										)
											td {{ n }}
											td
												v-layout(align-center justify-center)
													span.ml-2.answer(
														v-for="no in 7"
														v-text="setting.deliveries[index][`answer${no}`]"
														style="width: 60px;"
														:class="no === 1 ? 'start' : no === 4 ? 'waypoint' : no === 7 ? 'destination' : ''"
													)
</template>

<script>
	export default {
		data() {
			return {
				game: {},
			}
		},
		computed: {
			mqttData() {
				return this.$gameData.mqttData
			}
		},
		watch: {
			mqttData(val) {
				if (val.action === 'game_start' || val.action === 'game_reset') {
					val.timestamp = moment().unix()
					this.$set(this, 'game', val)
				}
			}
		},
		async mounted() {
			this.$adminData.tabIndex = 3
			this.$adminData.tabSubIndex = 0

			await this.getTrainingData()
		},
		methods: {
			className(n) {
				if (!this.game) return
				const deliveryNo = this.game.delivery_no || 0

				if (deliveryNo === n) {
					return 'current-game'
				} else if (deliveryNo > n) {
					return 'completed-game'
				}

				return ''

			},
			deliveryTime(n) {
				let time = ''
				if (n >= this.game.delivery_no && n <= this.game.delivery_no + 2) {
					if (this.game[`delivery_start_time${this.game.round}`]) {
						time = moment(this.game[`delivery_start_time${this.game.round}`]).add((n - this.game.delivery_no) * this.setting.delivery_game_interval, 'minutes').format('HH:mm')
						// const roundTime = moment(this.game.delivery_start_time2 || this.game.delivery_start_time1).unix()
						// time = moment.unix(roundTime + (n - 1) * this.setting.delivery_game_interval * 60).format('HH:mm')
					}
				}

				return time
			},
			async getTrainingData() {
				let response
				response = await this.$get('/admin/training/setting', { training_id: this.training.id })
				this.setting = response.data

				response = await this.$get('/admin/training/data', { training_id: this.training.id })
				this.game = response.data
			},
			async startGame() {
				const response = await this.$post('/admin/training/game/start', {training_id: this.training.id})
				const game = response.data
				this.$publishMqtt('training', 'game_start', game)
			},
			async resetGame() {
				const response = await this.$post('/admin/training/game/reset', {training_id: this.training.id})
				const game = response.data
				this.$publishMqtt('training', 'game_reset', game)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.legend {
		& > span {
			display: inline-block;
			padding-top: 3px;
		}
		.legend-box {
			width: 30px;
			height: 20px;
			border: 1px solid #999;
			margin-left: 20px;
			margin-right: 10px;

			&.box1 {
				background-color: #efefef;
			}
			&.box2 {
				background-color: #BF121C;
			}
			&.answer1 {
				background-color: #FBE5D6;
			}
			&.answer2 {
				background-color: #FFF2CC;
			}
			&.answer3 {
				background-color: #E2F0D9;
			}
		}
	}

	.round-wrap {
		.round {
			position: relative;
			flex-grow: 0;
			width: 50px;
			height: 30px;
			border: 1px solid #999;

			&.current-game {
				background-color: #BF121C;
				color: #fff;
			}
			&.completed-game {
				background-color: #efefef;
			}

			&+.round {
				position: relative;
				margin-left: 20px;
				&:before {
					content: '▶';
					position: absolute;
					left: -17px;
				}
			}

			.time {
				color: #222222 !important;
				position: absolute;
				bottom: -25px;
			}
		}
	}

	.answer {
		border: 1px solid #999;
		padding: 5px 0;
		background-color: #fff;
		text-align: center;

		&.start {
			background-color: #FBE5D6;
		}
		&.waypoint {
			background-color: #FFF2CC;
		}
		&.destination {
			background-color: #E2F0D9;
		}
	}
</style>
