<template lang="pug">
	.module-wrap.pt-5.pb-5.pl-1.pr-1
		v-card
			v-card-actions
				v-spacer
				v-btn(color="secondary" @click="downloadImage") 결과 분석 캡쳐
				v-btn(color="secondary" @click="downloadExcel") 엑셀 다운로드
			v-card-text
				table#result.result-table(
					:class="capture"
					width="100%"
					cellpadding="1"
					)
					thead
						tr
							th.th1(rowspan="2") 팀
							th.th1(colspan="6") 기획파트
							th.th1(colspan="6") 구매파트
							th.th1(colspan="5") 제작파트
							th.th1(rowspan="2") CALL<br />횟수
							th.th1(rowspan="2") 송금<br />횟수
							th.th1(rowspan="2") 업무<br />완성률<br />(C/A)
							th.th1(rowspan="2") 전체 프로젝트<br />완성률<br />(C/지시서<br />총계)
							th.th1(rowspan="2") 최종<br />매출
						tr
							th 하
							th 중
							th 상
							th 합계<br />(A)
							th 수행률<br />(A/지서서<br />총계)
							th.th2 재발송<br />횟수
							th 하
							th 중
							th 상
							th 합계<br />(B)
							th 수행률<br />(B/A)
							th.th2 재발송<br />횟수
							th 하
							th 중
							th 상
							th 합계<br />(C)
							th 수행률<br />(C/B)
					tbody
						tr(
							v-for="n in training.team_count"
							v-if="Object.keys(items).length"
						)
							td {{ n }}팀
							td {{ teamItem(n).plan.low || 0 }}
							td {{ teamItem(n).plan.medium || 0 }}
							td {{ teamItem(n).plan.high || 0 }}
							td {{ partTotal(n, 'plan') }}
							td {{ partProcessRate(n, 'plan') }}%
							td.td2 {{ teamItem(n).plan_resend || 0 }}
							td {{ teamItem(n).buy.low || 0 }}
							td {{ teamItem(n).buy.medium || 0 }}
							td {{ teamItem(n).buy.high || 0 }}
							td {{ partTotal(n, 'buy') }}
							td {{ partProcessRate(n, 'buy') }}%
							td.td2 {{ teamItem(n).buy_resend || 0 }}
							td {{ teamItem(n).make.low || 0 }}
							td {{ teamItem(n).make.medium || 0 }}
							td {{ teamItem(n).make.high || 0 }}
							td {{ partTotal(n, 'make') }}
							td {{ partProcessRate(n, 'make') }}%
							td {{ teamItem(n).call_count || 0 }}
							td {{ teamItem(n).remittance_count || 0 }}
							td {{ teamProcessRate(n) }}%
							td {{ projectProcessRate(n) }}%
							td {{ teamItem(n).capital || 0 }}

</template>

<script>
	import Excel from "exceljs";
	import domtoimage from 'dom-to-image'

	export default {
		data() {
			return {
				items: {},
				capture: '',
			}
		},
		mounted() {
			this.getResult()
		},
		methods: {
			async getResult() {
				const response = await this.$get('/admin/result', { id: this.training.id })
				const items = {}
				response.data.items.forEach(item => {
					if (!items[item.team]) {
						items[item.team] = this.defaultItem()
					}
					if (item.plan_type) {
						items[item.team].plan[item.plan_type] = item.plan_count
					}
					if (item.buy_type) {
						items[item.team].buy[item.buy_type] = item.buy_count
					}
					if (item.make_type) {
						items[item.team].make[item.make_type] = item.make_count
					}
					if (item.plan_resend_type) {
						items[item.team].plan_resend += item.plan_resend_count
					}
					if (item.buy_resend_type) {
						items[item.team].buy_resend += item.buy_resend_count
					}
				})

				response.data.calls.forEach(item => {
					if (!items[item.team]) {
						items[item.team] = this.defaultItem()
					}
					items[item.team].call_count = item.call_count
				})

				response.data.capitals.forEach(item => {
					if (!items[item.team]) {
						items[item.team] = this.defaultItem()
					}
					items[item.team].capital = item.capital.toLocaleString()
				})

				response.data.remittances.forEach(item => {
					if (!items[item.team]) {
						items[item.team] = this.defaultItem()
					}
					items[item.team].remittance_count = item.remittance_count
				})

				this.items = items
			},
			defaultItem() {
				return {
					plan: {},
					buy: {},
					make: {},
					plan_resend: 0,
					buy_resend: 0,
					call_count: 0,
					capital: 0,
				}
			},
			teamItem(team) {
				if (!this.items[team])
					return this.defaultItem()

				return this.items[team]
			},
			partTotal(team, part) {
				if (!this.items[team] || !this.items[team][part]) return 0
				const partData = this.items[team][part]

				let total = 0
				total += partData.low || 0
				total += partData.medium || 0
				total += partData.high || 0

				return total
			},
			partProcessRate(team, part) {
				const total = this.partTotal(team, part)
				if (total === 0) return 0

				let rate = 0
				if (part === 'plan') {
					rate = total / 24
				} else if (part === 'buy') {
					const planTotal = this.partTotal(team, 'plan')
					if (planTotal === 0) return 0
					rate = total / planTotal
				} else if (part === 'make') {
					const buyTotal = this.partTotal(team, 'buy')
					if (buyTotal === 0) return 0
					rate = total / buyTotal
				}

				return (rate * 100).toFixed(1)
			},
			teamProcessRate(team) {
				const planTotal = this.partTotal(team, 'plan')
				const makeTotal = this.partTotal(team, 'make')

				if (planTotal === 0) return 0

				const rate = makeTotal / planTotal
				return (rate * 100).toFixed(1)
			},
			projectProcessRate(team) {
				const makeTotal = this.partTotal(team, 'make')

				const rate = makeTotal / 24
				return (rate * 100).toFixed(1)
			},
			downloadImage() {
				const self = this
				this.$store.commit('showLoader')
				this.capture = 'capture'
				domtoimage.toJpeg(document.getElementById('result'), { quality: 0.95, cacheBust: true })
					.then(function (dataUrl) {
						const link = document.createElement('a');
						link.download = `${self.training.name}과정_결과분석_${moment().format('YYYY-MM-DD')}.jpeg`;
						link.href = dataUrl;
						link.click();
						self.capture = ''
						self.$store.commit('hideLoader')
					});

			},
			async downloadExcel() {

				const wb = new Excel.Workbook();
				const ws = wb.addWorksheet('Export');

				const border = {
					top: {style: 'thin'},
					left: {style: 'thin'},
					bottom: {style: 'thin'},
					right: {style: 'thin'},
				}
				ws.columns = [
					{header: '팀', key: 'team', width: 5, },
					{header: '기획파트', key: 'plan1', width: 5},
					{header: '', key: 'plan2', width: 5},
					{header: '', key: 'plan3', width: 5},
					{header: '', key: 'plan4', width: 10},
					{header: '', key: 'plan5', width: 10},
					{header: '', key: 'plan6', width: 10},
					{header: '구매파트', key: 'buy1', width: 5},
					{header: '', key: 'buy2', width: 5},
					{header: '', key: 'buy3', width: 5},
					{header: '', key: 'buy4', width: 10},
					{header: '', key: 'buy5', width: 10},
					{header: '', key: 'buy6', width: 10},
					{header: '제작파트', key: 'make1', width: 5},
					{header: '', key: 'make2', width: 5},
					{header: '', key: 'make3', width: 5},
					{header: '', key: 'make4', width: 10},
					{header: '', key: 'make5', width: 10},
					{header: 'CALL 횟수', key: 'call', width: 10},
					{header: '송금\n횟수', key: 'remittance', width: 10},
					{header: '업무\n완성률\n(C/A)', key: 'team_process_rate', width: 10},
					{header: '전체 프로젝트\n완성률\n(C/지시서\n총계)', key: 'project_process_rate', width: 10},
					{header: '최종\n매출', key: 'total', width: 10},
				]
				ws.addRow([
					'',
					'하',
					'중',
					'상',
					'합계\n(A)',
					'수행률\n(A/지시서 총계)',
					'재발송\n횟수',
					'하',
					'중',
					'상',
					'합계\n(B)',
					'수행률\n(B/A)',
					'재발송\n횟수',
					'하',
					'중',
					'상',
					'합계\n(C)',
					'수행률\n(C/B)',
					'',
					'',
					'',
					'',
					''
				])
				ws.getRow(1).eachCell(function(cell, colNumber) {
					cell.font = {
						bold: false,
					}
					cell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor: {argb: 'ffdfaa'}
					}
					cell.border = {
						top: {style:'thin'},
						left: {style:'thin'},
						bottom: {style:'thin'},
						right: {style:'thin'}
					};
					cell.alignment = {
						vertical: "middle",
						horizontal: "center",
						wrapText: true,
					}
				})
				ws.getRow(2).eachCell(function(cell, colNumber) {
					cell.font = {
						bold: false,
					}
					if ([7,13].indexOf(colNumber) > -1) {
						cell.fill = {
							type: 'pattern',
							pattern:'solid',
							fgColor: {argb: 'd5ebff'}
						}
					} else {
						cell.fill = {
							type: 'pattern',
							pattern:'solid',
							fgColor: {argb: 'fff4e4'}
						}
					}
					cell.border = {
						top: {style:'thin'},
						left: {style:'thin'},
						bottom: {style:'thin'},
						right: {style:'thin'}
					};
					cell.alignment = {
						vertical: "middle",
						horizontal: "center",
						wrapText: true,
					}
				})
				ws.mergeCells('A1:A2')
				ws.mergeCells('B1:G1')
				ws.mergeCells('H1:M1')
				ws.mergeCells('N1:R1')
				ws.mergeCells('S1:S2')
				ws.mergeCells('T1:T2')
				ws.mergeCells('U1:U2')
				ws.mergeCells('V1:V2')
				ws.mergeCells('W1:W2')


				for (let n of _.range(1, this.training.team_count + 1)) {

					const row = ws.addRow([
						n + '팀',
						this.teamItem(n).plan.low || 0,
						this.teamItem(n).plan.medium || 0,
						this.teamItem(n).plan.high || 0,
						this.partTotal(n, 'plan'),
						this.partProcessRate(n, 'plan') + '%',
						this.teamItem(n).plan_resend || 0,
						this.teamItem(n).buy.low || 0,
						this.teamItem(n).buy.medium || 0,
						this.teamItem(n).buy.high || 0,
						this.partTotal(n, 'buy'),
						this.partProcessRate(n, 'buy') + '%',
						this.teamItem(n).buy_resend || 0,
						this.teamItem(n).make.low || 0,
						this.teamItem(n).make.medium || 0,
						this.teamItem(n).make.high || 0,
						this.partTotal(n, 'make'),
						this.partProcessRate(n, 'make') + '%',
						this.teamItem(n).call_count || 0,
						this.teamItem(n).remittance_count || 0,
						this.teamProcessRate(n) + '%',
						this.projectProcessRate(n) + '%',
						this.teamItem(n).capital || 0,

				])
					row.eachCell(function(cell, colNumber) {
						if ([7,13].indexOf(colNumber) > -1) {
							cell.fill = {
								type: 'pattern',
								pattern: 'solid',
								fgColor: {argb: 'd5ebff'}
							}
						}
						cell.border = {
							top: {style:'thin'},
							left: {style:'thin'},
							bottom: {style:'thin'},
							right: {style:'thin'}
						};
						cell.alignment = {
							horizontal: "center"
						}
					})
				}

				const filename = `${this.training.name}_결과분석_${moment().format('YYYYMMDD')}.xlsx`
				wb.xlsx.writeBuffer( {
					base64: true
				})
					.then( function (xls64) {
						// build anchor tag and attach file (works in chrome)
						const a = document.createElement("a");
						const data = new Blob([xls64], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

						const url = URL.createObjectURL(data);
						a.href = url;
						a.download = filename;
						document.body.appendChild(a);
						a.click();
						setTimeout(function() {
								document.body.removeChild(a);
								window.URL.revokeObjectURL(url);
							},
							0);
					})
					.catch(function(error) {
						console.log(error.message);
					});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.result-table {
		background-color: #efefef;
		th {
			background-color: #fff4e4;
			height: 35px;
			font-weight: normal;

			&.th1 {
				background-color: #ffdfaa;
			}
			&.th2 {
				background-color: #d5ebff;
			}
		}

		td {
			text-align: center;
			background-color: #fff;
			height: 35px;
			&.td2 {
				background-color: #d5ebff;
			}
		}

	}

	.capture * {
		color: #000 !important;
	}
</style>