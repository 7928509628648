<template lang="pug">
	div.part-wrap(v-if="ready")
		v-layout.tabs
			v-image(src="tab_summary1.png" v-if="tab === 0")
			v-image(src="tab_summary1_deactive.png" v-else
				@click="changeTab(0)"
			)
			v-image(src="tab_summary2.png" v-if="tab === 1")
			v-image(src="tab_summary2_deactive.png" v-else
			@click="changeTab(1)"
			)
		div.content-wrap
			transition(name="fade")
				summary-tab1.tab(v-if="tab === 0")
				summary-tab2.tab(v-if="tab === 1")
</template>

<script>
	import SummaryTab1 from "@/components/frontend/modules/SummaryTab1";
	import SummaryTab2 from "@/components/frontend/modules/SummaryTab2";

	export default {
		components: {
			SummaryTab1,
			SummaryTab2,
		},
		data() {
			return {
				tab: 0,
				ready: false,
			}
		},
		mounted() {
			const index = this.$cookie.get('tabindex')
			if (index !== null) {
			    this.tab = parseInt(index)
			}

			this.ready = true
        },
		methods: {
		    changeTab(index) {
		        this.tab = index

			    this.$cookie.set('tabindex', index)
		    }
		}
    }
</script>

<style lang="scss" scoped>
	.part-wrap {
		width: 100%;
		.tabs {
			position: fixed;
			top: 150px;
			z-index: 1;
		}
		.content-wrap {
			.tab {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
			}
		}
	}
</style>