<template lang="pug">
	.container-wrap.mt-7.ml-3.pb-1
		v-card.elevation-0
			v-card-title 현황/결과
			v-card-actions
				v-btn.pa-5(
					color="secondary"
					@click="openStatus"
				) 실시간 현황
				v-btn.ml-5.pa-5(
					color="secondary"
					@click="downloadStatus"
				) 현황판 캡쳐
				v-spacer
				v-btn.ml-5.pa-5(
					v-if="roundEnded"
					color="primary"
					@click="setEnded"
				) 교육생 결과 보기
					span.ending-msg * [교육생 결과 보기]를 눌러야 교육생들이 과정 결과를 볼 수 있습니다.
			v-card-text
				v-tabs(
					v-model="tab"
					color="secondary"
					grow
				)
					v-tabs-slider(
						color="#C10D12"
					)
					v-tab(
						v-for="item in items"
						:key="item"
					) {{ item }}

				v-tabs-items(
					v-model="tab"
				)
					v-tab-item(
					)
						div(
							v-if="tab === 0"
						)
							result-ranking
					v-tab-item(
					)
						div(
							v-if="tab === 1"
						)
							result-status
					v-tab-item(
					)
						div(
							v-if="tab === 2"
						)
							result-timeline

</template>

<script>

	import ResultRanking from "@/components/admin/ResultRanking";
	import ResultStatus from "@/components/admin/ResultStatus";
	import ResultTimeline from "@/components/admin/ResultTimeline";

	export default {
		components: {
			ResultRanking,
			ResultStatus,
			ResultTimeline,
		},
		data() {
			return {
				tab: 0,
				items: ['매출', '결과 분석', '타임라인'],

				isLoading: false,

				names: { plan: '기획', buy: '구매', make: '제작'},
			}
		},
		computed: {
			roundEnded() {
				const now = moment().format('YYYY-MM-DD HH:mm:ss')
				const endDt = moment(`${this.training.date} ${this.training.end_time2}`).format('YYYY-MM-DD HH:mm:ss')

				return now >= endDt
			}
		},
		mounted() {
			this.$adminData.tabIndex = 6
		},
		methods: {
			openStatus() {
				window.open('/status')
			},
			downloadStatus() {
				window.open('/status?capture=true')
			},
			async setEnded() {
				await this.$post('/admin/training/ended', {
					id: this.training.id
				})

				this.$publish({
					type: 'training',
					trainingId: this.training.id,
					action: 'ended',
				})

				this.$store.commit('showDialog', {
					type: 'alert',
					message: '교육생 모바일에 결과 화면이 제공되었습니다.'
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrap {
		margin-top: 20px;
		padding: 10px;

		.subtitle {
			color: #C00000;
		}

		.ending-msg {
			position: absolute;
			top: -35px;
			right: -20px;
			color: #C00000;
			pointer-events: none;
		}
	}

</style>
<style lang="scss">

	.v-data-table {
		.incoming {
			background-color: #F1F8E9;
		}
		.outgoing {
			background-color: #FFEBEE;
		}
	}
</style>
