<template lang="pug">
	.training-wrap
		v-layout(justify-end)
			v-btn(
				color="secondary"
				@click="openTraining"
			) 새 교육 생성
		v-card.mt-5
			v-card-text
				v-data-table(
					:headers="headers"
					:items="trainings"
					:items-per-page="itemsPerPage"
					:page.sync="page"
					:loading="isLoading"
					loading-text="데이터 조회 중.. 잠시만 기다리세요."
					hide-default-footer
					@page-count="pageCount = $event"
				)
					template(
						v-slot:item.name="{ item }"
					)
						a(@click="selectTraining(item)") {{ item.name }}
					template(
						v-slot:item.date="{ item }"
					) {{ item.date }} {{ `${item.date} ${item.start_time1}` | moment('HH:mm') }} ~ {{ `${item.date} ${item.end_time2}` | moment('HH:mm') }}
					template(
						v-slot:item.actions="{ item }"
					)
						.actions
							v-btn(
								small color="secondary"
								@click="openTraining(item)"
							) 수정
							v-btn.ml-2(
								small color="error"
								@click="deleteTraining(item)"
							) 삭제
				v-divider
				.text-center.pt-2.mt-5
				v-pagination(
					v-model="page"
					:length="pageCount"
				)
		v-dialog(
			v-model="showDialog"
			persistent
			width="800"
		)
			v-card
				v-card-title 교육 정보를 입력해주세요
				v-card-text
					v-form()
						v-container
							v-row(no-gutters)
								v-col.d-flex.justify-end.align-center.pr-2(cols="3") 교육명
								v-col(cols="9")
									v-text-field(
										v-model="item.name"
										dense
										outlined
										hide-details
										color="secondary"
										@change="onNameChanged"
									)
							v-row.mt-2(no-gutters)
								v-col.d-flex.justify-end.align-center.pr-2(cols="3") 날짜
								v-col(cols="9")
									v-text-field(
										v-model="item.date"
										type="date"
										dense
										outlined
										hide-details
										color="secondary"
									)
							v-row.mt-2(no-gutters)
								v-col.d-flex.justify-end.align-center.pr-2(cols="3") 1라운드
								v-col(cols="9")
									v-layout
										v-text-field(
											v-model="item.start_time1"
											label="시작 시각"
											type="time"
											dense
											outlined
											hide-details
											color="secondary"
										)
										v-text-field.ml-2(
											v-model="item.end_time1"
											label="종료 시각"
											type="time"
											dense
											outlined
											hide-details
											color="secondary"
										)
							v-row.mt-2(no-gutters)
								v-col.d-flex.justify-end.align-center.pr-2(cols="3") 2라운드
								v-col(cols="9")
									v-layout
										v-text-field(
											v-model="item.start_time2"
											label="시작 시각"
											type="time"
											dense
											outlined
											hide-details
											color="secondary"
										)
										v-text-field.ml-2(
											v-model="item.end_time2"
											label="종료 시각"
											type="time"
											dense
											outlined
											hide-details
											color="secondary"
										)
							v-row.mt-2(no-gutters)
								v-col.d-flex.justify-end.align-center.pr-2(cols="3") 팀 수
								v-col(cols="9")
									v-text-field(
										v-model="item.team_count"
										type="number"
										min="1"
										max="50"
										dense
										outlined
										messages="* 최대 팀 수는 50팀까지"
										color="secondary"
										@change="onTeamCountChanged"
									)
						v-layout(justify-center)
							v-btn(@click="showDialog = false") 취소
							v-btn.ml-2(
								color="secondary"
								@click="saveTraining"
								:disabled="!canSave"
							) 저장
</template>

<script>
	export default {
		data() {
			return {
				page: 1,
				pageCount: 0,
				itemsPerPage: 10,
				headers: [
					{text: 'No.', sortable: true, value: 'id'},
					{text: '교육명', sortable: true, value: 'name'},
					{text: '일시', sortable: true, value: 'date'},
					{text: '팀 수', sortable: true, value: 'team_count'},
					{text: '현황', sortable: true, value: 'status'},
					{text: '', sortable: false, value: 'actions', align: 'center'},
				],
				trainings: [],

				item: {},
				oldTeamCount: 0,

				isLoading: false,
				showDialog: false,
				generatePdf: false,
			}
		},
		computed: {
			canSave() {
				let canSave = true
				if (!this.item.name) canSave = false
				if (!this.item.date) canSave = false
				if (!this.item.start_time1) canSave = false
				if (!this.item.end_time1) canSave = false
				if (!this.item.start_time2) canSave = false
				if (!this.item.end_time2) canSave = false
				if (!this.item.team_count) canSave = false

				return canSave
			}
		},
		watch: {
			mqttData(val) {
				if (val) {
					if (val.action === 'round' || val.action === 'round_ended') {
						const training = this.trainings.find(v => v.id === this.training.id)
						console.log(training)
						if (training) {
							training.status = this.gameStatus
						}
					}
				}
			},
		},
		mounted() {
			this.$adminData.tabIndex = 0

			this.getList()
		},
		methods: {
			async getList() {

				this.trainings = []

				const response = await this.$get('/admin/trainings')
				if (response.code === '0000') {
					const trainings = []
					const now = moment().format('YYYY-MM-DD HH:mm:ss')
					response.data.forEach(training => {
						const start1 = `${training.date} ${training.start_time1}`
						const end1 = `${training.date} ${training.end_time1}`
						const start2 = `${training.date} ${training.start_time2}`
						const end2 = `${training.date} ${training.end_time2}`

						if (now < start1) {
							training.status = '예약'
						} else if (now >= start1 && now <= end1)  {
							training.status = '1라운드 진행 중'
						} else if (now < start2)  {
							training.status = '2라운드 준비'
						} else if (now >= start2 && now <= end2)  {
							training.status = '2라운드 진행 중'
						} else if (now > end2)  {
							training.status = '교육 종료'
						}
						trainings.push(training)
					})

					this.trainings = trainings
				}
			},
			selectTraining(item) {
				this.$adminData.training = item
				this.$cookie.set('training_id', item.id)
				this.$router.push(`/admin/training/${item.id}`)
			},
			openTraining(item) {
				if (item) {
					this.item = _.cloneDeep(item)
					this.oldTeamCount = this.item.team_count
				} else {
					item = {}
					this.oldTeamCount = 0
				}
				this.showDialog = true
			},
			onNameChanged() {
				this.generatePdf = true
			},
			onTeamCountChanged() {
				this.generatePdf = this.oldTeamCount !== parseInt(this.item.team_count)
			},
			async saveTraining() {
				this.showDialog = false
				this.$store.commit('showLoader')

				if (!this.item.id) {
					this.generatePdf = true
				}

				this.item.name = (this.item.name || '').trim()

				const response = await this.$post('/admin/training', this.item)
				const item = response.data
				this.$adminData.training = item
				this.$cookie.set('training_id', item.id)
				await this.getList()
				this.$store.commit('hideLoader')

				if (this.generatePdf) {
					this.$publish({
						type: 'training',
						trainingId: item.id,
						action: 'generate_pdf',
					})

					this.generatePdf = false
				}
			},

			async deleteTraining(item) {
                this.$store.commit('showDialog', {
                    type: 'confirm',
                    message: `교육을 삭제하면 복구가 불가능합니다.\n&lt;${item.name}&gt; 교육을 삭제하시겠습니까?`,
                    okCb: async _ => {
                        this.$store.commit('showLoader')
                        await this.$post('/admin/training/delete', {id: item.id})

	                    await this.getList()
                        this.$store.commit('hideLoader')
                    }
                })

			}
		}
	}
</script>

<style lang="scss" scoped>
	.training-wrap {
		margin-top: 20px;
		padding: 10px;
	}
</style>
