<template lang="pug">
	.training-wrap
		v-layout(justify-space-between)
			h3.training-title {{ training.name }}
			v-btn(
				color="secondary"
				@click="downloadPdf"
			) PDF 다운로드
		v-card.mt-5
			v-card-text
				v-simple-table
					template(v-slot:default)
						thead
							tr
								th(width="25%") 팀
								th(width="25%") 파트
								th(width="25%") ID
								th(width="25%") PW
						tbody
							tr(
								v-for="(user, index) in users"
								:key="index"
							)
								td.team(rowspan=6 v-if="user.order === 1") {{ user.team }}팀
								td {{ partNames[user.part] }} {{ (user.order - 1) % 2 + 1 }}
								td
									a(@click="loginAsUser(user)") {{ user.username }}
								td {{ user.passwd }}

</template>

<script>
	export default {
		data() {
			return {
				users: [],
				partNames: { plan: '기획', buy: '구매', make: '제작' }
			}
		},
		computed: {
			training() {
				return this.$adminData.training
			}
		},
		watch: {
			training() {
				this.getList()
			}
		},
		beforeCreate() {
			this.$adminData.tabIndex = 1
		},
		async mounted() {
			this.getList()
		},
		methods: {
			async getList() {
				if (!this.training.id) return
				const response = await this.$get('/admin/training/users', {id: this.training.id })

				this.users = response.data
			},
			async loginAsUser(user) {
				let response = await this.$post('/admin/user/token', {
					id: user.id
				})

				const token = response.data
				this.$cookie.set('tabindex', 0)
				this.$cookie.set('token', token)

				window.open('/')
			},
			downloadPdf() {
				window.open(`https://legorace.s3.ap-northeast-2.amazonaws.com/pdf/${this.training.id}_${this.training.name}_유저카드.pdf`)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.training-wrap {
		width: 1024px;
		margin: 10px auto 0;
		padding: 10px;

		.training-title {
			color: #666;
			margin-top: 10px;
			font-weight: 400;
		}

		.team {
			background-color: #f9f9f9;
		}
	}
</style>
