<template lang="pug">
	v-layout.login-wrap(justify-center align-center column
	:style="`transform: scale(${scale})`"
	)
		.logo-wrap
			v-image.logo(src="login_logo.png")

		form.form(@keypress="login")
			.input-wrap
				input.username(
					v-model="username"
					type="text"
					placeholder="ID"
				)
			.input-wrap
				input.passwd(
					v-model="passwd"
					type="password"
					placeholder="PW"
				)
			.error-msg
				transition(name="fade")
					v-image(
						v-if="errorCode"
						:src="`login_error${errorCode}.png`"
					)
			.btn-login-wrap
				v-image.btn-login.active(
					v-if="canLogin"
					src="btn_login.png"
					@click="login()"
				)
				v-image.btn-login(
					v-else
					src="btn_login_disabled.png"
				)
</template>

<script>
	export default {
		comments: {
		},
		data() {
			return {
				username: '',
				passwd: '',
				errorCode: 0,
				scale: 1,
			}
		},
		computed: {
			canLogin() {
				const username = this.username.trim()
				const passwd = this.passwd.trim()

				return username && username.length > 3 && passwd && passwd.length > 3
			}
		},
		watch: {
			username() {
				this.errorCode = 0
			},
			passwd() {
				this.errorCode = 0
			}
		},
		mounted() {
			$(window).resize(this.onResize)
			this.onResize()
		},
		methods: {
			onResize() {
				const clientWidth = window.innerWidth
				const clientHeight = window.innerHeight

				if (!this.$route.path.startsWith('/admin')) {
					if (clientWidth < 621) {
						this.scale = clientWidth / 621

						$('.main-wrap').css('height', clientHeight / this.scale)
					} else {
						this.scale = 1
						$('.main-wrap').css('height', '100%')
					}
				} else {
					this.scale = 1
					$('.main-wrap').css('height', '100%')
				}

			},
			async login(e) {
				if (!!e && e.type === 'keypress') {
					if (e.code !== 'Enter') return

					if (this.username.length < 4 || this.passwd.length < 4)
						return
				}

				const response = await this.$post('/login', {
					username: this.username,
					passwd: this.passwd,
				})

				const today = moment().format('YYYY-MM-DD')
				const now = moment().format('HH:mm:ss')


				switch (response.code) {
					case '0000':
						const data = response.data
						if (data.type === 'superadmin') {
							this.$cookie.set('tpc_token', data.token)
							this.$gameData.admin = data
							this.$router.push('/manage')

						} else if (data.type === 'admin') {
							// 관리자인 경우 유효기간 확인
							if (!data.unlimited) {
								if (today < data.start_date || today > data.end_date) {
									this.errorCode = 3
									return
								}
							}

							this.$cookie.set('admin_token', data.token)
							this.$gameData.admin = data
							this.$router.push('/admin')


						} else if (data.type === 'user') {
							// 교육생인 경우 과정 시간 체크
							const training = data.training

							if (today === training.date) {
								if ((now >= training.start_time1 && now <= training.end_time1) || (now >= training.start_time2 && now <= training.end_time2)) {
									// 과정 참여 가능
									this.$cookie.set('token', data.token)
									this.$cookie.set('tabindex', 0)
									this.$cookie.delete('page')
									this.$router.replace('/')
									return
								} else if (now >= training.end_time2) {
									const end_time = moment(training.end_time2).add(2, 'hours').format('YYYY-MM-DD HH:mm:ss')
									if (now < end_time) {
										this.$cookie.set('token', data.token)
										this.$cookie.set('tabindex', 0)
									}
									if (training.ended) {
										this.$cookie.set('page', 'summary')
									} else {
										this.$cookie.set('page', 'ending')
									}
									this.$router.replace('/')
									return
								}
							}

							this.errorCode = 2
						}
						break;
					case '9011':
						this.errorCode = 1
						break;
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.login-wrap {
		width: 621px;
		height: 100%;
		margin: 0 auto;

		transform-origin: center left;
	}
	.logo {
		margin-bottom: 45px;
	}
	.input-wrap {
		width: 521px;
		padding: 0 5px;

		input {
			width: 100%;
			height: 65px;
			border: 2px solid #D1D1D1;
			border-radius: 30px;

			background-color: #fff;

			padding: 0 35px;
			font-size: 30px;
			line-height: 10px;

			margin: 30px 0 0;

			outline-color: #C10D12;

			&::placeholder {
				color: #D1D1D1;
				transform: translateY(3px);
			}
		}
	}

	.error-msg {
		width: 100%;
		margin-top: 20px;
		padding-left: 40px;
		height: 32px;
	}

	.btn-login {
		margin-top: 14px;
		&.active {
			cursor: pointer;
		}
	}
</style>