<template lang="pug">
	.setting-wrap()
		v-card.elevation-0
			v-card-title 프로젝트 지시서 설정
			v-card-subtitle.subtitle ※ 설정 탭에서 변경한 데이터는 이전 교육에는 적용되지 않으며, 앞으로 추가하는 교육에만 반영됩니다.

			v-card-text
				v-tabs(
					v-model="tab"
					color="secondary"
					grow
				)
					v-tabs-slider(
						color="#C10D12"
					)
					v-tab(
						v-for="item in items"
						:key="item"
					) {{ item }}

				v-tabs-items(
					v-model="tab"
				)
					v-tab-item(
						v-for="typeNo in 3"
						:key="`type-${typeNo}`"
					)
						v-simple-table.table-striped
							template
								thead
									tr
										th(style="width: 5%") No.
										th(style="width: 5%") 종류
										th(style="width: 30%") 프로젝트 지시서 이미지
										th(style="width: 30%") 제작에 필요한 레고
										th(style="width: 30%") 정답 이미지
								tbody
									tr(
										v-for="(n, index) in 8"
										:key="`row-${tab}-${n}`"
									)
										td {{ n }}
										td {{ levelName[tab] }} {{ n }}
										td
											v-layout.text-center.mt-2.mb-2(
												align-center
												v-for="dimg in 2"
												:key="`dimg-${dimg}`"
											)
												div.flex-grow-1
													v-btn(
														small
														color="secondary"
														@click="selectFile(typeNo, index, `direction_image${dimg}`)"
													) 파일 선택
												div.flex-grow-1(style="width: 200px")
													img.image(
														v-if="image(typeNo, index, `direction_image${dimg}`)"
														:src="image(typeNo, index, `direction_image${dimg}`)"
													)
													span(v-else) 선택된 파일 없음
										td.text-center
											div(v-if="legos(typeNo, index).length")
												v-layout.lego(
													justify-space-between align-center
													v-for="(lego, index) in legos(typeNo, index)"
													:key="`lego-${index}`"
												)
													img(:src="`https://legorace.s3.ap-northeast-2.amazonaws.com/images/icon_lego_${lego.lego_name}.png`")
													div
														span.amount {{ lego.amount }}
														span 개

											div(v-else) 선택된 레고 없음
											v-btn.mb-2(
												small
												color="secondary"
												@click="selectLego(typeNo, index)"
											) 수정
										td
											v-layout.text-center.mt-2.mb-2(
												align-center
												v-for="aimg in 3"
												:key="`aimg-${aimg}`"
											)
												div.flex-grow-1
													v-btn(
														small
														color="secondary"
														@click="selectFile(typeNo, index, `answer_image${aimg}`)"
													) 파일 선택
												div.flex-grow-1(style="width: 200px")
													img.image(
														v-if="image(typeNo, index, `answer_image${aimg}`)"
														:src="image(typeNo, index, `answer_image${aimg}`)"
													)
													span(v-else) 선택된 파일 없음

		v-dialog(
			content-class="lego-dialog"
			v-model="showDialog"
			persistent
			width="500"
		)
			v-card(v-if="currentItem")
				v-card-title.justify-center &lt;{{ levelName[currentItem.typeNo - 1] }} {{ currentItem.index + 1}}&gt; 제작에 필요한 레고 설정
				v-card-text
					.lego-item-wrap
						.lego-items
							div(
								v-for="item in legoItems"
								:key="item.lego_name"
							)
								.lego
									img.lego-img(:src="`https://legorace.s3.ap-northeast-2.amazonaws.com/images/icon_lego_${item.lego_name}.png`")

									v-layout.amount-wrap
										img(
											v-if="item.amount === 0"
											srcset="https://legorace.s3.ap-northeast-2.amazonaws.com/images/btn_minus_disabled.png 3x"
										)
										img.btn-lego-amount(
											v-else
											srcset="https://legorace.s3.ap-northeast-2.amazonaws.com/images/btn_minus.png 3x"
											@click="setLegoAmount(item, -1)"
										)
										v-layout.amount(align-center justify-center) {{ item.amount }}
										img.btn-lego-amount(srcset="https://legorace.s3.ap-northeast-2.amazonaws.com/images/btn_plus.png 3x"
											@click="setLegoAmount(item, 1)"
										)
				v-card-actions
					v-layout(justify-center)
						v-btn(@click="showDialog = false") 취소
						v-btn.ml-2(
							color="secondary"
							@click="setLego"
						) 저장

		input.file(
			type="file"
			accept="image/*"
			@change="onSelectFile"
		)

</template>

<script>
	export default {
		data() {
			return {
				tab: 0,
				items: ['난이도 상', '난이도 중', '난이도 하'],
				directions: [],
				currentItem: null,
				showDialog: false,
				legoItems: [],
				levelName: ['상', '중', '하'],
			}
		},
		async mounted() {
			this.$adminData.tabIndex = 2
			this.$adminData.tabSubIndex = 2

			await this.getSettings()

			// await this.selectLego(1, 0)
		},

		methods: {
			async getSettings() {
				const response = await this.$get('/admin/directions')
				if (response.code === '0000') {
					const directions = {
						high: [],
						medium: [],
						low: [],
					}
					response.data.forEach(direction => {

						if (direction.legos) direction.legos = JSON.parse(direction.legos)

						directions[direction.type].push(direction)
					})

					this.directions = directions
				}
			},
			async save() {
				const type = this.currentItem.type
				const index = this.currentItem.index
				const field = this.currentItem.field

				this.$store.commit('showLoader')

				const item = this.directions[type][index]
				let value = item[field]

				if (field === 'legos') value = JSON.stringify(value)
				const data = {
					id: item.id,
					type: type,
					order: index + 1,
					field: field,
					value: item[field]
				}
				await this.$post('/admin/direction', data)
				this.getSettings()
				this.$store.commit('hideLoader')
			},

			selectFile(typeNo, index, field) {
				this.currentItem = {
					typeNo: typeNo,
					type: this.typeName(typeNo),
					index: index,
					field: field,
				}
				$('.file').click()
			},
			async onSelectFile(e) {
				const file = e.target.files[0]

				const base64 = await new Promise((resolve) => {
					let fileReader = new FileReader();
					fileReader.onload = (e) => resolve(fileReader.result);
					fileReader.readAsDataURL(file);
				});

				const response = await this.$post(`/admin/upload`, {
					imgBase64: base64.replace(/^data:image\/[^;]+;base64,/, "")
				})

				const item = this.currentItem

				if (!this.directions[item.type][item.index]) {
					this.$set(this.directions[item.type], item.index, {})
				}
				this.$set(this.directions[item.type][item.index], item.field, response.data)

				this.save()

				$('.file').val(null)
			},
			typeName(typeNo) {
				return ['', 'high', 'medium', 'low'][typeNo]
			},
			image(typeNo, index, field) {
				if (!this.directions[this.typeName(typeNo)]) return null
				if (!this.directions[this.typeName(typeNo)][index]) return null
				if (!this.directions[this.typeName(typeNo)][index][field]) return null

				return this.directions[this.typeName(typeNo)][index][field]
			},
			legos(typeNo, index) {
				if (!this.directions[this.typeName(typeNo)]) return []
				if (!this.directions[this.typeName(typeNo)][index]) return []
				if (!this.directions[this.typeName(typeNo)][index].legos) return []

				return this.directions[this.typeName(typeNo)][index].legos

			},

			selectLego(typeNo, index) {
				this.currentItem = {
					typeNo: typeNo,
					type: this.typeName(typeNo),
					index: index,
					field: 'legos',
				}

				const type = this.typeName(typeNo)
				if (!this.directions[this.typeName(typeNo)][index]) {
					this.$set(this.directions[type], index, {})
				}
				if (!this.directions[this.typeName(typeNo)][index].legos) {
					this.$set(this.directions[type][index], 'legos', [])
				}

				const legos = this.directions[this.typeName(typeNo)][index].legos
				const items = []

				for (let color of ['red', 'yellow', 'green', 'blue', 'white']) {
					for (let n of [2,3,4]) {
						const name = `${color}${n}`
						const lego = legos.find(v => v.lego_name === name)
						const item = {
							lego_name: name,
							amount: lego ? lego.amount : 0
						}
						items.push(item)
					}
				}

				this.legoItems = items
				this.showDialog = true

				setTimeout(_ => {
					$('.lego-items').scrollTop(0)
				})
			},

			setLegoAmount(item, amount) {
				item.amount += amount
			},

			setLego() {
				const item = this.currentItem
				this.directions[item.type][item.index].legos = this.legoItems.filter(v => v.amount > 0)

				this.save()
				this.showDialog = false
			},
		}
	}
</script>

<style lang="scss" scoped>
	.setting-wrap {
		margin-top: 20px;
		padding: 10px;

		.subtitle {
			color: #C00000;
		}

		input {
			border: 1px solid #000;
			padding: 5px 10px;
		}
		.file {
			display: none;
		}

		.image {
			max-width: 200px;
		}

		.lego {
			border: 1px solid #D1D1D1;
			border-radius: 10px;
			background-color: #fff;
			padding: 10px;

			margin-top: 5px;
			margin-bottom: 5px;

			color: #C10D0D;
			font-size: 20px;

			img {
				height: 50px;
			}

			.amount {
				font-size: 25px;
				margin-right: 3px;
				font-weight: bold;
			}
		}
	}
</style>

<style lang="scss">

	.lego-dialog {

		.lego-item-wrap {
			border: 1px solid #ccc;
			border-radius: 10px;
			height: 400px;

			.lego-items {
				margin: 10px;
				height: calc(100% - 20px);
				overflow-y: scroll;
				/*background-color: #F7F7F7;*/

				.lego {
					position: relative;
					background-color: #fff;
					margin: 10px;
					padding: 10px;
					border-radius: 10px;
					border: 1px solid #D1D1D1;
					height: 90px;

					.lego-img {
						height: 50px;
					}
					.amount-wrap {
						position: absolute;
						right: 12px;
						bottom: 8px;

						.btn-lego-amount {
							cursor: pointer;
						}

						.amount {
							width: 80px;
							height: 30px;
							background-color: #F0F0F0;
							font-size: 18px;
							padding-top: 2px;
						}
					}
				}
			}
		}
	}

</style>