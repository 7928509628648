<template lang="pug">
	div.tab-wrap
		div.game-wrap(
			v-if="playing"
		)
			v-image.background(src="background_delivery_game.png")
			.paths
				.path(
					v-for="n in [1,4,7]"
					:key="`path-${n}`"
					:class="`path${n}`"
				) {{ deliveries.find(v => v.no === trainingData.delivery_no)[`answer${n}`] }}
			.fields
				v-layout.field(
					align-center
					justify-center
					v-for="n in [2,3,5,6]"
					:key="`field-${n}`"
					:class="`field${n} ${selected === n ? 'selected' : ''}`"
					@click="selectField(n)"
				) {{ answers[n] || '&nbsp;' }}

			.time {{ timeFormat }}

			v-layout(justify-center)
				v-image.btn-submit(
					v-if="Object.values(answers).filter(v => !!v).length === 4"
					src="btn_submit_active.png"
					@click="submit"
				)
				v-image.btn-submit(
					v-else
					src="btn_submit.png"
				)
		div.stand-by(
			v-else
		)
			div.message 다음 운송게임을 기다려주세요
			div.fee(v-if="teamData.delivery_fee")
				span 현재 운송비는
				span.point {{ teamData.delivery_fee }}p
				span 입니다

		v-slide-y-reverse-transition
			number-keypad(
				disable-backdrop="true"
				@click-outside="onClickOutside"
				v-if="numberPad"
				@click="onPadClick"
				@close="onPadClose"
			)

</template>

<script>
	import NumberKeypad from "@/components/modules/NumberKeypad";

	export default {
		components: {
			NumberKeypad,
		},
        data() {
            return {
            	playing: false,
	            selected: -1,
	            answers: {
            		2: '',
		            3: '',
		            5: '',
		            6: '',
	            },
	            numberPad: false,
	            time: 300,
	            timer: null,
            }
        },

	    computed: {
		    mqttData() {
			    return this.$gameData.mqttData
		    },
		    timeFormat() {
		    	const minutes = Math.floor(this.time / 60)
			    const seconds = this.time % 60

			    return String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0')
		    }

	    },
	    watch: {
		    mqttData(val) {
		    	console.log(val)
			    if (val && val.action === 'game_start') {
				    const endTime = moment(this.trainingData.delivery_start_time2 || this.trainingData.delivery_start_time1).add(this.setting.delivery_game_time, 'minutes').format('YYYY-MM-DD HH:mm:ss')
				    if (moment().format('YYYY-MM-DD HH:mm:ss') < endTime) {
					    this.time = Math.ceil(moment(endTime).diff(moment()) / 1000)
					    this.$gameData.gameStarted = true
					    this.startGame()
				    }
			    }
			    if (val && val.action === 'game_end') {

				    // if (this.timer && !val.delivery_fee) {
					//     this.$post('/delivery/game', {timeover: true})
				    // }
				    // this.teamData.delivery_fee = val.delivery_fee || 200
				    this.$getUserData()
				    this.endGame()
				    this.$gameData.mqttData = null
			    }
			    if (val && val.action === 'game_reset') {

				    this.endGame()
				    this.$gameData.mqttData = null
			    }
		    }
	    },

	    async mounted() {
			if (this.trainingData) {
				const response = await this.$get('/delivery/game', { delivery_no: this.trainingData.delivery_no})
				if (!response.data.id) {

					if (this.trainingData.delivery_start_time1 || this.trainingData.delivery_start_time2) {
						const endTime = moment(this.trainingData.delivery_start_time2 || this.trainingData.delivery_start_time1).add(this.setting.delivery_game_time, 'minutes').format('YYYY-MM-DD HH:mm:ss')
						if (moment().format('YYYY-MM-DD HH:mm:ss') < endTime) {
							this.time = Math.ceil(moment(endTime).diff(moment()) / 1000)
							this.$gameData.gameStarted = true
							this.startGame()
						}
					}
				}
			}
	    },

	    methods: {
        	startGame() {
		        this.playing = true

		        if (this.timer) {
		        	clearInterval(this.timer)
		        }
		        this.timer = setInterval(_ => {
			        this.time--
			        if (this.time <= 0) {
				        this.$post('/delivery/game', { timeover: true })
				        this.teamData.delivery_fee = 200
				        this.endGame()
				        this.$publishMqtt('team', 'game_end', {delivery_fee: this.teamData.delivery_fee})
			        }
		        }, 1000)
	        },
		    async endGame() {

        		this.$hideDialog()
			    if (this.timer) {
				    clearInterval(this.timer)
			    }
			    Object.keys(this.answers).forEach(k => {
			    	this.answers[k] = ''
			    })
			    this.timer = null
			    this.numberPad = false

			    this.playing = false
		    },
		    selectField(n) {
        		this.selected = n
			    this.showNumberKeypad()
		    },
		    showNumberKeypad() {
			    $('.remittance-wrap').animate({
				    scrollTop: 200
			    })
			    this.numberPad = true
		    },
		    onClickOutside() {
			    this.numberPad = false
			    $('.remittance-wrap').animate({
				    scrollTop: 0
			    })
		    },
		    onPadClick(num) {
        		let answer = String(this.answers[this.selected] || '')
			    if (num === 'back') {
				    answer = answer.substring(0, answer.length - 1)
			    } else {
				    answer += num
			    }
			    if (answer > 49) return

			    this.answers[this.selected] = !!answer ? parseInt(answer) : ''
		    },
		    onPadClose() {
        		const arr = [2,3,5,6]
			    const index = arr.indexOf(this.selected)
			    if (index === 3) {
			    	this.selected = null
				    this.numberPad = false
			    } else {
			    	this.selected = arr[index + 1]
			    }
		    },
		    async submit() {
        		let correct = true
			    Object.keys(this.answers).forEach(n => {
			    	const answers = this.deliveries.find(v => v.no === this.trainingData.delivery_no)
			    	if (this.answers[n] !== answers[`answer${n}`]) {
			    		correct = false
				    }
			    })

			    this.$showToast(correct ? 'correct_answer' : 'incorrect_answer')
			    if (correct) {

			    	const response = await this.$post('/delivery/game')


			    	const delivery_fee = response.data.delivery_fee
				    this.teamData.delivery_fee = delivery_fee
				    this.endGame()

				    this.$publishMqtt('team', 'game_end', {delivery_fee: this.teamData.delivery_fee})
			    }
		    },

        }
    }
</script>

<style lang="scss" scoped>
	.tab-wrap {
		height: 100%;

		.game-wrap {
			position: absolute;
			left: 55px;
			top: 40px;
			padding-bottom: 170px;

			.paths {
				position: absolute;
				left: -7px;
				top: 87px;
				font-size: 27px;
				color: #222222;
				text-align: center;
				white-space: nowrap;

				.path {

					width: 70px;
					display: inline-block;

					&:nth-child(2) {
						margin-left: 158px;
					}
					&:nth-child(3) {
						margin-left: 160px;
					}
				}
			}

			.fields {
				position: absolute;
				left: 75px;
				top: 68px;
				font-size: 27px;
				color: #222222;

				.field {
					display: inline-flex;
					width: 50px;
					height: 62px;
					background-color: #fff;
					border-radius: 7px;
					border: 1px solid #D1D1D1;
					padding-top: 3px;

					&:nth-child(2) {
						margin-left: 30px;
					}
					&:nth-child(3) {
						margin-left: 102px;
					}
					&:nth-child(4) {
						margin-left: 30px;
					}

					&.selected {
						border-color: #C10D12;
					}
				}
			}

			.time {
				position: absolute;
				left: 296px;
				top: 171px;
				font-size: 23px;
				font-weight: bold;
				color: #0A111C;
			}

			.btn-submit {
				margin-top: 29px;
			}
		}

		.stand-by {
			height: calc(100% - 150px);

			background-image: url(https://legorace.s3.ap-northeast-2.amazonaws.com/images/background_shipping.png);
			background-size: 100%;
			background-position: bottom;
			text-align: center;

			.message {
				font-size: 32px;
				color: #022222;
				padding-top: 148px;
			}

			.fee {
				font-size: 25px;
				color: #5D5D5D;
				margin-top: 20px;

				.point {
					color: #BF121C;
					padding: 0 8px;
				}
			}
		}

	}
</style>