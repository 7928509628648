<template lang="pug">
	.setting-wrap
		v-card.elevation-0
			v-card-title 자본금/가격 설정
			v-card-subtitle.subtitle ※ 설정 탭에서 변경한 데이터는 이전 교육에는 적용되지 않으며, 앞으로 추가하는 교육에만 반영됩니다.

			v-card-text
				v-card
					v-card-subtitle.font-weight-bold 자본금 설정
					v-card-text
						v-layout(column justify-center align-center)
							v-layout(align-center)
								div
									span 기획파트
									input.ml-2(
										type="number"
										v-model="settings.capital_plan"
										style="width: 80px;"
									)
									span.ml-1 p
								div.ml-10
									span 구매파트
									input.ml-2(
										type="number"
										v-model="settings.capital_buy"
										style="width: 80px;"
									)
									span.ml-1 p
								div.ml-10
									span 제작파트
									input.ml-2(
										type="number"
										v-model="settings.capital_make"
										style="width: 80px;"
									)
									span.ml-1 p
					v-card-actions
						v-spacer
						v-btn(color="secondary" @click="save") 저장
			v-card-text
				v-card
					v-card-subtitle.font-weight-bold 레고 가격 설정
					v-card-text
						v-layout(column justify-center align-center)
							v-layout(align-center)
								span 동일한 레고가
								input.ml-2(
									type="number"
									v-model="settings.lego_impression_base"
									style="width: 50px;"
								)
								span.ml-1 개 구매될 때마다 해당 레고의 가격이
								input.ml-2(
									type="number"
									v-model="settings.lego_impression_price"
									style="width: 50px;"
								)
								span.ml-1 p 상승하며,
							v-layout(align-center).mt-2
								span 최소
								input.ml-2(
									type="number"
									v-model="settings.lego_impression_min"
									style="width: 50px;"
								)
								span.ml-1 p부터 최대
								input.ml-2(
									type="number"
									v-model="settings.lego_impression_max"
									style="width: 50px;"
								)
								span.ml-1 p 사이에서 가격 변동
					v-card-actions
						v-spacer
						v-btn(color="secondary" @click="save") 저장
			v-card-text
				v-card
					v-card-subtitle.font-weight-bold 제작 가격 설정
					v-card-text
						v-layout(column justify-center align-center)
							v-layout(align-center)
								div
									span 난이도 상
									input.ml-2(
										type="number"
										v-model="settings.make_price_high"
										style="width: 80px;"
									)
									span.ml-1 p
								div.ml-10
									span 난이도 중
									input.ml-2(
										type="number"
										v-model="settings.make_price_medium"
										style="width: 80px;"
									)
									span.ml-1 p
								div.ml-10
									span 난이도 하
									input.ml-2(
										type="number"
										v-model="settings.make_price_low"
										style="width: 80px;"
									)
									span.ml-1 p
					v-card-actions
						v-spacer
						v-btn(color="secondary" @click="save") 저장
			v-card-text
				v-card
					v-card-subtitle.font-weight-bold 물품 가격 설정
					v-card-text
						v-layout(column justify-center align-center)
							v-layout(align-center)
								div
									span 일반 설계도
									input.ml-2(
										type="number"
										v-model="settings.price_normal_direction"
										style="width: 80px;"
									)
									span.ml-1 p
								div.ml-10
									span 고급 설계도
									input.ml-2(
										type="number"
										v-model="settings.price_advanced_direction"
										style="width: 80px;"
									)
									span.ml-1 p
								div.ml-10
									span 자
									input.ml-2(
										type="number"
										v-model="settings.price_ruler"
										style="width: 80px;"
									)
									span.ml-1 p
								div.ml-10
									span 색연필
									input.ml-2(
										type="number"
										v-model="settings.price_pencil"
										style="width: 80px;"
									)
									span.ml-1 p
					v-card-actions
						v-spacer
						div.subtitle.mr-5 *자, 색연필의 재고 : 해당 교육의 ‘팀 수’를 2로 나눈 몫
						v-btn(color="secondary" @click="save") 저장
			v-card-text
				v-card
					v-card-subtitle.font-weight-bold 포인트 대출 이자 설정
					v-card-text
						v-layout(column justify-center align-center)
							v-layout(align-center)
								div
									span 포인트 대출 이자 설정
									input.ml-2(
										type="number"
										v-model="settings.interest_rate"
										style="width: 80px;"
									)
									span.ml-1 %
					v-card-actions
						v-spacer
						v-btn(color="secondary" @click="save") 저장
</template>

<script>
	export default {
		data() {
			return {
				settings: {
					capital_plan: 0,
					capital_buy: 0,
					capital_make: 0,

					lego_impression_base: 0,
					lego_impression_price: 0,
					lego_impression_min: 0,
					lego_impression_max: 0,

					make_price_high: 0,
					make_price_medium : 0,
					make_price_low : 0,

					price_normal_direction: 0,
					price_advanced_direction: 0,
					price_ruler: 0,
					price_pencil: 0,

					interest_rate: 0,
				}
			}
		},
		mounted() {
			this.$adminData.tabIndex = 2
			this.$adminData.tabSubIndex = 0

			this.getSettings()
		},

		methods: {
			async getSettings() {
				const response = await this.$get('/admin/setting')
				if (response.code === '0000') {
					const data = response.data
					Object.keys(this.settings).forEach(k => {
						this.settings[k] = data[k]
					})
				}
			},
			async save() {
				this.$store.commit('showLoader')
				await this.$post('/admin/setting', this.settings)
				this.$store.commit('hideLoader')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.setting-wrap {
		margin-top: 20px;
		padding: 10px;

		.subtitle {
			color: #C00000;
		}

		input {
			border: 1px solid #000;
			padding: 5px 0 5px 10px;
		}
	}
</style>