<template lang="pug">
	.container-wrap.mt-7.ml-3.pb-1
		v-card.elevation-0
			v-card-title 포인트

			v-card-text
				v-tabs(
					v-model="tab"
					color="secondary"
					grow
				)
					v-tabs-slider(
						color="#C10D12"
					)
					v-tab(
						v-for="item in items"
						:key="item"
					) {{ item }}

				v-tabs-items(
					v-model="tab"
				)
					v-tab-item(
					)
						point-status(
							v-if="tab === 0"
							@change="getPointHistories"
						)
					v-tab-item(
					)
						point-manage(
							v-if="tab === 1"
							@change="getPointHistories"
						)
					v-tab-item(
					)
						point-interest(
							v-if="tab === 2"
							@change="getPointHistories"
						)


				v-card.mt-5(style="width: 70%; margin: 0 auto")
					v-card-subtitle 내역
					v-card-actions
						v-spacer
						v-select.flex-grow-0(
							label="파트"
							dense
							outlined
							:items="parts"
							v-model="search.part"
							clearable
							hide-details
							style="width: 150px;"
						)
						v-select.flex-grow-0.ml-2(
							label="팀"
							dense
							outlined
							:items="teams"
							v-model="search.team"
							clearable
							hide-details
							style="width: 150px;"
						)
						v-text-field.flex-grow-0.ml-2(
							label="사유"
							dense
							outlined
							clearable
							hide-details
							v-model="search.name"
						)
					v-card-text
						v-data-table(
							:headers="headers"
							:items="histories"
							:items-per-page="itemsPerPage"
							:item-class="itemRowBackground"
							:loading="isLoading"
							loading-text="데이터 조회 중.. 잠시만 기다리세요."
							hide-default-footer
							:page.sync="page"
							@page-count="pageCount = $event"
						)
							template(v-slot:item.part="{ item }")
								span {{ names[item.part] }}
							template(v-slot:item.name="{ item }")
								span {{ item.name }}
							template(v-slot:item.type="{ item }")
								span {{ item.point > 0 ? '지급' : '차감' }}
						v-divider
						.text-center.pt-2.mt-5
						v-pagination(
							v-model="page"
							:length="pageCount"
						)
</template>

<script>
	import PointStatus from "@/components/admin/PointStatus";
	import PointManage from "@/components/admin/PointManage";
	import PointInterest from "@/components/admin/PointInterest";

	export default {
		components: {
			PointStatus,
			PointManage,
			PointInterest,
		},
		data() {
			return {
				tab: 0,
				// items: ['보유 현황', '지급/차감', '대출/상환'],
				items: ['보유 현황', '지급/차감'],
				itemsPerPage: 10,
				page: 1,
				pageCount: 0,

				isLoading: false,
				histories: [],
				headers: [
					{text: '파트', sortable: true, value: 'part', filter: value => {
							return !this.search.part || value === this.search.part
						}},
					{text: '팀', sortable: true, value: 'team', filter: value => {
							return !this.search.team || value === this.search.team
						}},
					{text: '포인트', sortable: true, value: 'point'},
					{text: '사유', sortable: true, value: 'name', filter: value => {
							return !this.search.name || value.indexOf(this.search.name) > -1
						}},
					{text: '유형', sortable: true, value: 'type'},
					{text: '일시', sortable: true, value: 'time'},
				],
				names: { plan: '기획', buy: '구매', make: '제작'},

				parts: [
					{ text: '기획', value: 'plan'},
					{ text: '구매', value: 'buy'},
					{ text: '제작', value: 'make'},
				],
				teams: [],

				search: {
					part: null,
					team: null,
				}

			}
		},
		mounted() {
			this.$adminData.tabIndex = 5
			for (let n of _.range(0, this.$adminData.training.team_count)) {
				const team = n + 1
				this.teams.push({
					text: team + '팀',
					value: team,
				})
			}
		},
		methods: {
			async getPointHistories() {
				this.isLoading = true

				const response = await this.$get('/admin/point/histories', { id: this.training.id })
				this.histories = response.data

				this.isLoading = false
			},
			itemRowBackground(item) {
				return item.point > 0 ? 'incoming' : 'outgoing'
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrap {
		margin-top: 20px;
		padding: 10px;

		.subtitle {
			color: #C00000;
		}
	}

</style>
<style lang="scss">

	.v-data-table {
		.incoming {
			background-color: #F1F8E9;
		}
		.outgoing {
			background-color: #FFEBEE;
		}
	}
</style>
