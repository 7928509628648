<template lang="pug">
	.ending-wrap
		v-layout.tabs
		div.content-wrap
			transition(name="fade")
				v-layout.fill-height.justify-center.align-center
					v-image.pb-16(
						src="ending_message.png"
					)
</template>

<script>
	export default {

	}
</script>

<style lang="scss" scoped>
	.ending-wrap {
		width: 100%;
		.tabs {
			position: fixed;
			top: 150px;
			z-index: 1;
		}
		.content-wrap {
			height: calc(100% - 150px);
			font-size: 25px;
			font-weight: 600;
			color: #666;
		}
	}

</style>