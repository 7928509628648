<template lang="pug">
	.direction-wrap()
		v-card.elevation-0
			v-card-title 프로젝트 지시서

			v-card-text
				v-tabs(
					v-model="tab"
					color="secondary"
					grow
				)
					v-tabs-slider(
						color="#C10D12"
					)
					v-tab(
						v-for="item in items"
						:key="item"
					) {{ item }}

				v-tabs-items(
					v-model="tab"
				)
					v-tab-item(
						v-for="typeNo in 3"
						:key="`type-${typeNo}`"
					)
						v-simple-table
							template
								thead
									tr
										th(style="width: 5%") No.
										th(style="width: 5%") 종류
										th(style="width: 30%") 프로젝트 지시서 이미지
										th(style="width: 30%") 제작에 필요한 레고
										th(style="width: 30%") 정답 이미지
								tbody
									tr(
										v-for="(n, index) in 8"
										:key="`row-${tab}-${n}`"
									)
										td {{ n }}
										td {{ levelName[tab] }} {{ n }}
										td
											v-layout.text-center.mt-2.mb-2(
												align-center
												v-for="dimg in 2"
												:key="`dimg-${dimg}`"
											)
												div.flex-grow-1(style="width: 200px")
													img.image(
														v-if="image(typeNo, index, `direction_image${dimg}`)"
														:src="image(typeNo, index, `direction_image${dimg}`)"
													)
													span(v-else) 선택된 파일 없음
										td.text-center
											div(v-if="legos(typeNo, index).length")
												v-layout.lego(
													justify-space-between align-center
													v-for="(lego, index) in legos(typeNo, index)"
													:key="`lego-${index}`"
												)
													img(:src="`https://legorace.s3.ap-northeast-2.amazonaws.com/images/icon_lego_${lego.lego_name}.png`")
													div
														span.amount {{ lego.amount }}
														span 개

											div(v-else) 선택된 레고 없음
										td
											v-layout.text-center.mt-2.mb-2(
												align-center
												v-for="aimg in 3"
												:key="`aimg-${aimg}`"
											)
												div.flex-grow-1(style="width: 200px")
													img.image(
														v-if="image(typeNo, index, `answer_image${aimg}`)"
														:src="image(typeNo, index, `answer_image${aimg}`)"
													)
													span(v-else) 선택된 파일 없음
</template>

<script>
	export default {
		data() {
			return {
				tab: 0,
				items: ['난이도 상', '난이도 중', '난이도 하'],
				directions: [],
				currentItem: null,
				showDialog: false,
				legoItems: [],
				levelName: ['상', '중', '하'],
			}
		},
		computed: {
			training() {
				return this.$adminData.training
			}
		},
		watch: {
			training() {
				this.getList()
			}
		},
		beforeCreate() {
			this.$adminData.tabIndex = 2
			this.$adminData.tabSubIndex = 0
		},

		async mounted() {

			await this.getSettings()
		},

		methods: {
			async getSettings() {
				const response = await this.$get('/admin/training/directions', {id: this.training.id})
				if (response.code === '0000') {
					const directions = {
						high: [],
						medium: [],
						low: [],
					}
					response.data.forEach(direction => {

						if (direction.legos) direction.legos = JSON.parse(direction.legos)

						directions[direction.type].push(direction)
					})

					this.directions = directions
				}
			},
			typeName(typeNo) {
				return ['', 'high', 'medium', 'low'][typeNo]
			},
			image(typeNo, index, field) {
				if (!this.directions[this.typeName(typeNo)]) return null
				if (!this.directions[this.typeName(typeNo)][index]) return null
				if (!this.directions[this.typeName(typeNo)][index][field]) return null

				return this.directions[this.typeName(typeNo)][index][field]
			},
			legos(typeNo, index) {
				if (!this.directions[this.typeName(typeNo)]) return []
				if (!this.directions[this.typeName(typeNo)][index]) return []
				if (!this.directions[this.typeName(typeNo)][index].legos) return []

				return this.directions[this.typeName(typeNo)][index].legos

			},

		}
	}
</script>

<style lang="scss" scoped>

	.direction-wrap {
		margin-top: 20px;
		padding: 10px;

		.subtitle {
			color: #C00000;
		}

		input {
			border: 1px solid #000;
			padding: 5px 10px;
		}
		.file {
			display: none;
		}

		.image {
			max-width: 200px;
		}

		.lego {
			border: 1px solid #D1D1D1;
			border-radius: 10px;
			background-color: #fff;
			padding: 10px;

			margin-top: 5px;
			margin-bottom: 5px;

			color: #C10D0D;
			font-size: 20px;

			img {
				height: 50px;
			}

			.amount {
				font-size: 25px;
				margin-right: 3px;
				font-weight: bold;
			}
		}
	}
</style>
