<template lang="pug">
	v-layout.layout-wrap(
		v-if="initialized && user"
		justify-center
	)
		v-layout.main-wrap(align-center
		column
		:style="`transform: scale(${scale})`"
			@click="fullscreen"
		)
			div.container-wrap
				div.top-wrap
					v-image.logo(src="logo.png")
					v-image.btn-logout(src="btn_logout.png" v-ripple @click="confirmLogout")
					v-layout.team-info(align-center)
						div.team-part {{ user.team }}팀 {{ partNames[user.part] }}파트 :
						div.total-point {{ user.capital }}p

				div.contents-wrap
					account-book.page.no-scrollbar(v-if="page === 'accountbook'" @close="page = null")
					remittance.page.no-scrollbar(v-else-if="page === 'remittance'" @close="page = null")
					Summary.page(v-else-if="page === 'summary'")
					Ending.page(v-else-if="page === 'ending'")

					plan-part.page.no-scrollbar(v-else-if="part === 'plan'")
					buy-part.page.no-scrollbar(v-else-if="part === 'buy'")
					make-part.page.no-scrollbar(v-else-if="part === 'make'")

			div.bottom(v-if="page !== 'summary' && page !== 'ending'")
				v-layout(justify-space-between)
					v-image.btn(src="btn_ledger.png" v-ripple @click="page = 'accountbook'")
					v-image.btn(src="btn_remittance.png" v-ripple @click="page = 'remittance'")
					v-image.btn(src="btn_call.png" v-ripple @click="confirmCall")


			transition-group(name="fade")
				alert-dialog(
					v-for="dialog in Object.values(dialogs)"
					:key="dialog.dialogId"
					:dialog="dialog"
				)
			toast

		//-
			.username {{ user.username }}

</template>

<script>
	import AlertDialog from "@/components/modules/AlertDialog";
	import Toast from "@/components/modules/Toast";

	import AccountBook from "@/components/frontend/AccountBook";
	import Remittance from "@/components/frontend/Remittance";
	import Summary from "@/components/frontend/Summary";
	import Ending from "@/components/frontend/Ending";

	import PlanPart from "@/components/frontend/PlanPart";
	import BuyPart from "@/components/frontend/BuyPart";
	import MakePart from "@/components/frontend/MakePart";

	export default {
		components: {
			AlertDialog,
			Toast,

			AccountBook,
			Remittance,
			Summary,
			Ending,

			PlanPart,
			BuyPart,
			MakePart,
		},
		data() {
			return {
				initialized: false,
				partNames: {plan: '기획', buy: '구매', make: '제작'},
				page: null,
				part: null,
			}
		},
		computed: {
			scale: {
				get() {
					return this.$gameData.scale
				},
				set(val) {
					this.$gameData.scale = val
				}
			},
			user: {
				get() { return this.$userData.user },
				set(val) { this.$set(this.$userData, 'user', val )}
			},
			setting: {
				get() { return this.$userData.setting },
				set(val) { this.$set(this.$userData, 'setting', val )}
			},
			dialogs() {
				return this.$gameData.dialogs
			}
		},
		watch: {
			mqttData(val) {
				if (val) {
					if (val.action === 'round_ended') {
						if (val.round === 1) {
							this.logout()
						} else if (val.round === 2) {
							// dashboard
							// this.logout()
							this.$cookie.set('page', 'ending')
							this.page = 'ending'
						}
					} else if (val.action === 'call') {
					    this.$getUserData()
						this.startTimer(val)
					} else if (val.action === 'ended') {
						this.$cookie.set('page', 'summary')
						this.page = 'summary'
					}


					if (this.user.part === 'buy') {
						if (val && val.action === 'game_start') {
							const trainingData = this.trainingData
							trainingData.delivery_no = val.delivery_no
							trainingData.delivery_start_time1 = val.delivery_start_time1
							trainingData.delivery_start_time2 = val.delivery_start_time2

							this.$set(this, 'trainingData', trainingData)

							this.$gameData.gameStarted = true
							this.$cookie.set('tabindex', 0)

							this.$hideDialog()

							this.$showDialog({
								type: 'start_game',
								buttons: [
									{type: 'ok'}
								]
							})
							setTimeout(_ => {
								this.$gameData.mqttData = null
							})

						}
						if (val && (val.action === 'game_end' || val.action === 'game_reset')) {
							if (val.action === 'game_reset') {
								this.teamData.delivery_fee = null
							}
							this.$gameData.gameStarted = false
							setTimeout(_ => {
								this.$gameData.mqttData = null
							})
						}
					}
					if (val.sendType === 'resend') {
						if (this.user.part === 'buy') {
							if (val && val.target === 'team' && val.action === 'direction') {
								const names = {low: '하', medium: '중', high: '상'}
								this.$showDialog({
									type: 'empty',
									message: `[기획파트]에서\n[${names[val.type]}${val.order}] 설계도를\n다시 발송했습니다.`,
									buttons: [
										{ type: 'ok' }
									]
								})
							}
						} else if (this.user.part === 'make') {
							if (val && val.target === 'team' && val.action === 'delivery') {
								const names = {low: '하', medium: '중', high: '상'}
								this.$showDialog({
									type: 'empty',
									message: `[구매파트]에서\n[${names[val.type]}${val.order}] 설계도 또는 레고를\n다시 발송했습니다.`,
									buttons: [
										{ type: 'ok' }
									]
								})
							}
						}
					}
					if (val.action === 'inspection') {
						if (this.part === 'make') {
							if (val.type && val.order) {
								if (val.passed === 1) {
									this.$showToast('make2')
								}
							}
						}
					}
				}
			},
		},
		async mounted() {

			window.onpopstate = e => {
				if (this.$cookie.get('token') && this.$route.path === '/login') {
					this.$router.push('/')
				}
			}

			const token = this.$cookie.get('token')
			if (!token) {
				this.moveToLogin()
				return
			}

			let response
			response = await this.$post('/check', { token : token})
			if (response.code !== '0000' || response.data.type !== 'user') {
				this.moveToLogin()
				return
			}

			this.user = response.data
			if (this.user.afterLogin) {
				if (this.user.training.ended) {
					this.page = 'summary'
				} else {
					this.page = 'ending'
				}
			} else if (this.$cookie.get('page') === 'summary') {
				this.page = 'summary'
			} else {
				this.part = this.user.part
			}

			await this.$getUserData()

			this.initialized = true

			$(window).resize(this.onResize)
			await this.$wait(0)

			await this.onResize()


			this.$setMqttClient()

			// this.client.publish('dev/training/25/stock', JSON.stringify({ruler: 1, pencil: 2}), { qos: 1})
		},
		methods: {
			async onResize() {
				const clientWidth = document.documentElement.offsetWidth
				const clientHeight = document.documentElement.offsetHeight
				const orientation = this.detectOrientation()

				// console.log(clientWidth, clientHeight, orientation)

				$(document.body).width(clientWidth)
				$(document.body).height(clientHeight)
				$(document.body).css('transform', 'unset')
				$(document.body).css('transform-origin', 'unset')

				if (orientation === 'desktop' || !this.isMobile() || orientation.startsWith('portrait')) {
					if (clientWidth < 621) {
						this.scale = clientWidth / 621

						$('.main-wrap').css('height', clientHeight / this.scale)
					} else {
						this.scale = 1
						$('.main-wrap').css('height', '100%')
					}
				} else {
					if (clientHeight < 621) {
						this.scale = clientHeight / 621

						$('.main-wrap').css('height', clientWidth / this.scale)
					} else {
						this.scale = 1
						$('.main-wrap').css('height', '100%')
					}

					$(document.body).width(clientHeight)
					$(document.body).height(clientWidth)

					if (orientation === 'landscape-primary') {
						$(document.body).css('transform', 'rotate(270deg)')
						$(document.body).css('transform-origin', '50vh 50vh')
					} else {
						$(document.body).css('transform', 'rotate(90deg)')
						$(document.body).css('transform-origin', '50vw')
					}
				}
			},
			isMobile() {
				if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
					return true
				} else {
					return false
				}
			},
			detectOrientation(){
				if (!navigator.maxTouchPoints) return 'desktop'
				if (window.screen.orientation && window.screen.orientation.type) return window.screen.orientation.type

				const matchMedia = window.matchMedia('(orientation: portrait)')
				if (matchMedia.matches) return 'portrait'
				return 'landscape-primary'

			},
			fullscreen() {
				// const element = document.body
				// if (element.requestFullscreen) return element.requestFullscreen()
				// if (element.webkitRequestFullscreen) return element.webkitRequestFullscreen()
				// if (element.mozRequestFullScreen) return element.mozRequestFullScreen()
				// if (element.msRequestFullscreen) return element.msRequestFullscreen()
				//
				// return ScreenOrientation.lock()
			},

			moveToLogin() {
				this.$router.replace('/login')
			},
			confirmLogout() {
				this.$showDialog({
					type: 'logout',
					buttons: [
						{ type: 'cancel' },
						{ type: 'logout', click: this.logout },
					]
				})
			},
			logout() {
				this.$cookie.delete('token')
				location.replace('/login')
			},

			async confirmCall() {
				const response = await this.$get('/team/call')
				if (response.data && response.data.id) {
					this.startTimer(response.data)
				} else {
					if (this.user.capital < this.setting.call_price) {
						this.$showToast('point_not_enough')
						return
					}
					this.$showDialog({
						type: 'call',
						buttons: [
							{type: 'cancel'},
							{type: 'call', click: this.call},
						]
					})
				}
			},

			async call() {
				if (this.user.capital < this.setting.call_price) {
					this.$showToast('point_not_enough')
					return
				}
				const response = await this.$post('/team/call')
				if (response.code === '0000') {
					this.$publishMqtt('team', 'call', response.data)
				}
				// this.$getUserData()
				// this.startTimer(response.data)
			},
			startTimer(call) {
				const diff =
					Math.min(Math.ceil(moment(call.call_start_time).add(this.setting.call_time, 'seconds').diff(moment()) / 1000), this.setting.call_time)
				if (diff < 0) return

				this.$showDialog({
					type: 'empty',
					message: `CALL 남은 시간`,
					time: diff,
					buttons: [
						{ type: 'close' }
					]
				})
			}

		}
	}
</script>

<style lang="scss" scoped>
	.main-wrap {
		position: absolute;
		transform-origin: top center;

		.container-wrap {
			flex-grow: 1;
			width: 621px;
			background-color: #F7F7F7;

			.top-wrap {
				height: 200px;
				background-color: #C10D0D;

				.logo {
					position: absolute;
					left: 45px;
					top: 43px;
				}

				.btn-logout {
					position: absolute;
					right: 55px;
					top: 31px;
				}

				.team-info {
					position: absolute;
					width: 316px;
					height: 52px;

					line-height: 1;

					padding: 0 23px;

					right: 45px;
					top: 72px;

					background-color: #fff;
					border-radius: 26px;

					.team-part {
						font-size: 25px;
						color: #C10D0D;
					}

					.total-point {
						position: absolute;
						right: 23px;
						font-size: 27px;
						color: #222222;
					}
				}
			}

			.contents-wrap {

				height: 100%;
				background-color: #F7F7F7;
				border-top-left-radius: 50px;
				border-top-right-radius: 50px;

				position: absolute;
				top: 150px;
				left: 0;
				width: 100%;

				.page {
					position: absolute;
					top: 70px;
					bottom: 90px;
					width: 100%;

					overflow-x: hidden;
					overflow-y: auto;
				}
			}
		}

		.bottom {
			position: relative;
			flex-grow: 0;
			width: 100%;
			height: 90px;
			z-index: 2;

			padding: 0 80px;

			background-color: #E5E5E5;

			img {
				cursor: pointer;
			}
		}
	}

	.username {
		position: absolute;
		left: 0;
		top: 0;
	}
</style>

<style lang="scss">
</style>