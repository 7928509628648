<template lang="pug">
	.account-book-wrap
		v-image.title-account-book(src="title_account_book.png")
		.account-book
			v-image.background(src="background_account_book.png")
			.rows.no-scrollbar
				.row(
					v-for="point in points"
					:key="point.id"
				)
					.time {{ point.time }}
					.name {{ point.name }}
					.incoming {{ point.point >= 0 ? `${point.point}p` : '-' }}
					.outgoing {{ point.point < 0 ? `${-1 * point.point}p` : '' }}
					.balance {{ point.balance }}p
		.button
			v-image.btn-return(
				src="btn_return_active.png"
				@click="$emit('close')"
			)
</template>

<script>
	export default {
		data() {
			return {
				points: [],
			}
		},
		watch: {
			'$userData.user.capital'(val) {
				this.getPoints()
			}
		},
		mounted() {
			this.getPoints()
		},
		methods: {
			async getPoints() {
				const response = await this.$get('/part/points')

				this.points = response.data
			}
		}
	}
</script>

<style lang="scss" scoped>
	.account-book-wrap {
		width: 100%;
		text-align: center;
		padding-top: 30px;
		margin-top: -70px;
		padding-bottom: 120px;

		.title-account-book {
		}

		.account-book {
			margin-top: 33px;

			.rows {
				position: absolute;
				left: 65px;
				top: 168px;
				padding-top: 30px;
				height: 543px;
				overflow-y: scroll;
				overflow-x: hidden;



				.row {
					margin-bottom: 20px;

					font-size: 17px;
					color: #222222;

					& > * {
						display: inline-block;
					}

					.time {
						width: 88px;
					}
					.name {
						width: 153px;
					}
					.incoming {
						width: 89px;
					}
					.outgoing {
						width: 92px;
					}
					.balance {
						width: 91px;
						color: #C10D12;
						font-weight: bold;
					}
				}
			}
		}

		.btn-return {
			cursor: pointer;
			margin: 30px 0;
		}
	}
</style>