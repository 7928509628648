<template lang="pug">
	div.part-wrap(v-if="ready")
		v-layout.tabs
			v-image(src="tab_buy1.png" v-if="tab === 0")
			v-image(src="tab_buy1_deactive.png" v-else
				@click="changeTab(0)"
			)
			v-image(src="tab_buy2.png" v-if="tab === 1")
			v-image(src="tab_buy2_deactive.png" v-else
			@click="changeTab(1)"
			)
			v-image(src="tab_buy3.png" v-if="tab === 2")
			v-image(src="tab_buy3_deactive.png" v-else
			@click="changeTab(2)"
			)
			v-image(src="tab_buy4.png" v-if="tab === 3")
			v-image(src="tab_buy4_deactive.png" v-else
				@click="changeTab(3)"
			)
		div.content-wrap
			transition(name="fade")
				buy-tab1.tab(v-if="tab === 0")
				buy-tab2.tab(v-if="tab === 1")
				buy-tab3.tab(v-if="tab === 2" @change="tab = 1")
				buy-tab4.tab(v-if="tab === 3")
</template>

<script>

	import BuyTab1 from "@/components/frontend/modules/BuyTab1";
	import BuyTab2 from "@/components/frontend/modules/BuyTab2";
	import BuyTab3 from "@/components/frontend/modules/BuyTab3";
	import BuyTab4 from "@/components/frontend/modules/BuyTab4";

	export default {
		components: {
			BuyTab1,
			BuyTab2,
			BuyTab3,
			BuyTab4,
		},
		data() {
			return {
				tab: 0,
				ready: false,
			}
		},
		computed: {
			mqttData() {
				return this.$gameData.mqttData
			},
		},
		watch: {
			mqttData(val) {
				// if (val && val.target === 'team' && val.action === 'direction') {
				// 	if (val.sendType === 'resend') {
				// 		const names = {low: '하', medium: '중', high: '상'}
				// 		this.$showDialog({
				// 			type: 'empty',
				// 			message: `[기획파트]에서\n[${names[val.type]}${val.order}] 설계도를\n다시 발송했습니다.`,
				// 			buttons: [
				// 				{ type: 'ok' }
				// 			]
				// 		})
				// 	}
				// }
				if (val && val.action === 'game_start') {
					this.changeTab(0)
				}
			}
		},
		mounted() {
			const index = this.$cookie.get('tabindex')
			if (index !== null) {
			    this.tab = parseInt(index)
			}

			this.ready = true
        },
		methods: {
		    changeTab(index) {
		    	if (index === 2 && !this.teamData.delivery_fee) {
				    this.$showToast('buy_delivery')
				    return
			    }
		    	if (this.$gameData.gameStarted && index !== 0) {
		    		this.$showToast('buy_delivery')
				    return
			    }
		        this.tab = index

			    this.$cookie.set('tabindex', index)
		    }
		}
    }
</script>

<style lang="scss" scoped>
	.part-wrap {
		width: 100%;
		.tabs {
			position: fixed;
			top: 150px;
			z-index: 1;
		}
		.content-wrap {
			.tab {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
			}
		}
	}
</style>