<template lang="pug">
	v-layout.dialog-wrap(
		justify-center align-center
	)
		v-layout.flex-grow-0(
			column
		)
			.dialog-body
				v-image(
					:src="`dialog_${dialog.type}.png`"
					width="452"
				)
				v-layout.message(
					v-if="dialog.message"
					column
					justity-center
					:class="dialog.align === 'left' ? 'align-start' : 'align-center'"
					:style="dialog.color ? `color: ${dialog.color}` : ''"
				)
					span(v-html="message")
					div.timer(v-if="timer") {{ time }}

				.directions(
					v-if="dialog.type === 'plan4'"
				)
					.item(
						:class="directionClass[0]"
						@click="selectDirection(0)"
					)
						v-image(v-if="directionClass[0] === 'selected'" src="dialog_direction_item_selected.png")
						v-image(v-else-if="directionClass[0] === 'disabled'" src="dialog_direction_item_disabled.png")
						v-image(v-else src="dialog_direction_item.png")
						v-layout.content(align-center)
							span.name 일반 설계도 :
							span.amount {{ normalDirectionCount }}
							span.msg1 개
							span.msg2 보유
					.item(
						:class="directionClass[1]"
						@click="selectDirection(1)"
					)
						v-image(v-if="directionClass[1] === 'selected'" src="dialog_direction_item_selected.png")
						v-image(v-else-if="directionClass[1] === 'disabled'" src="dialog_direction_item_disabled.png")
						v-image(v-else src="dialog_direction_item.png")
						v-layout.content(align-center)
							span.name 고급 설계도 :
							span.amount {{ advancedDirectionCount }}
							span.msg1 개
							span.msg2 보유

			.buttons
				v-image(
					v-for="(button, index) in dialog.buttons"
					:key="index"
					:src="`dialog_btn_${button.type}${button.disabled && disabled ? button.disabled : ''}.png`"
					:class="disabled && button.disabled ? 'disabled' : ''"
					@click="onClick(button)"
				)


</template>

<script>
	export default {

		data() {
			return {
				disabled: true,
				selectedDirection: -1,
				params: {},
				timer: null,
				time: null,
				directionClass: [],
			}
		},
		props: [
			'dialog',
		],
		computed: {
			// dialog() {
			// 	return this.$gameData.dialog
			// },
			normalDirectionCount() {
				return this.inventories
					.filter(v => v.part === this.user.part && v.amount > 0)
					.reduce((acc, curr) => acc += curr.name === 'normal_direction' ? curr.amount : 0 , 0)
			},
			advancedDirectionCount() {
				return this.inventories
					.filter(v => v.part === this.user.part && v.amount > 0)
					.reduce((acc, curr) => acc += curr.name === 'advanced_direction' ? curr.amount : 0 , 0)
			},
			message() {
				let message = this.dialog.message || ''
				message = message.replace(/\n/g, '<br />')
					.replace(/\[([^\]]+)\]/g, '<span class="highlight">$1</span>')

				return message
			}
		},
		watch: {
			inventories(val) {
				this.setDirectionClass()
			}
		},
		beforeDestroy() {
			if (this.timer) {
				this.stopTimer()
			}
		},
		mounted() {
			this.selectedDirection = -1
			if (this.dialog.time) {
				this.startTimer(this.dialog.time)
			}

			this.setDirectionClass()
		},
		methods: {
			onClick(button) {
				if (button.click) button.click(this.params)

				this.$hideDialog(this.dialog.dialogId)
			},
			startTimer(time) {
				this.time = time
				this.timer = setInterval(_ => {
					this.time--
					if (this.time <= 0) {
						this.stopTimer()
						this.$hideDialog()
					}
				}, 1000)
			},
			stopTimer() {
				clearInterval(this.timer)
				this.timer = null
			},
			selectDirection(index) {
				this.params.selected = ['normal_direction', 'advanced_direction'][index]
				this.selectedDirection = index

				this.setDirectionClass()
			},
			setDirectionClass() {
				let className = []
				for (let index of [0,1]) {
					if (index === 0) {

						if (this.normalDirectionCount === 0) {
							className[index] = 'disabled'
							if (this.selectedDirection === 0) {
								this.selectedDirection = -1
								this.disabled = true
							}
						} else if (this.selectedDirection === 0) {
							className[index] = 'selected'
							this.disabled = this.normalDirectionCount <= 0
						}
					} else {
						if (this.advancedDirectionCount === 0) {
							if (this.selectedDirection === 1) {
								this.selectedDirection = -1
								this.disabled = true
							}
							className[index] = 'disabled'
						} else if (this.selectedDirection === 1) {
							className[index] = 'selected'
							this.disabled = this.advancedDirectionCount <= 0
						}
					}

				}

				this.directionClass = className
			}
		}
	}
</script>

<style lang="scss" scoped>

	.dialog-wrap {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1000;
		background-color: rgba(0, 0, 0, 0.85);

		.dialog-body {
			position: relative;

			.message {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				justify-content: center;
				text-align: center;
				font-size: 25px;
				color: #222222;
				padding-left: 40px;
				&.align-center {
					padding-left: 0;
				}
			}

			.directions {
				position: absolute;
				top: 143px;
				left: 38px;
				.item {
					position: relative;
					&+.item {
						margin-top: 18px;
					}

					.content {
						position: absolute;
						top: 2px;
						left: 68px;
						height: 100%;
						font-size: 24px;
						color: #222222;

						.amount {
							text-align: right;
							display: inline-block;
							width: 55px;
							margin-left: 7px;
							color: #BF121C;
							font-weight: bold;
						}
						.msg1 {
							margin-left: 7px;
							color: #BF121C;
						}
						.msg2 {
							margin-left: 7px;
						}
					}

					&.disabled {
						pointer-events: none;
						.content {
							color: #D0D0D0;
							.amount, .msg1 {
								color: #D0D0D0;
							}
						}
					}
					&.selected {
						.content {
							color: #FFF;
							.amount, .msg1 {
								color: #FFF;
							}
						}
					}
				}
			}
		}

		.buttons {
			.disabled {
				pointer-events: none;
			}
			img {
				cursor: pointer;
			}
		}
	}

</style>

<style lang="scss">


	.message {
		.highlight {
			color: #C10D12;
		}
		.timer {
			display: block;
			font-size: 80px;
			font-weight: bold;
			line-height: 1;
			margin-top: 15px;
			margin-bottom: -10px;
		}
	}
</style>