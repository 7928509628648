<template lang="pug">
	.keypad-container(
		:class="!disableBackdrop ? 'backdrop' : ''"
		@click="onClickOutside"
	)
		.keypad-wrap(
			@click.stop
		)
			v-layout(column align-center)
				v-layout(justify-center wrap)
					v-image.number(src="btn_number1.png" @click="onClick(1)")
					v-image.number(src="btn_number2.png" @click="onClick(2)")
					v-image.number(src="btn_number3.png" @click="onClick(3)")
					v-image.number(src="btn_number4.png" @click="onClick(4)")
					v-image.number(src="btn_number5.png" @click="onClick(5)")
					v-image.number(src="btn_number6.png" @click="onClick(6)")
					v-image.number(src="btn_number7.png" @click="onClick(7)")
					v-image.number(src="btn_number8.png" @click="onClick(8)")
					v-image.number(src="btn_number9.png" @click="onClick(9)")
					v-image.number(src="btn_number00.png" @click="onClick('00')")
					v-image.number(src="btn_number0.png" @click="onClick(0)")
					v-image.number(src="btn_number_back.png" @click="onClick('back')")

			v-image.btn-next(src="btn_next.png" @click="$emit('close')")
</template>

<script>
	export default {
		props: ['disableBackdrop'],
		methods: {
			onClickOutside() {
				this.$emit('click-outside')
			},
			onClick(num) {
				this.$emit('click', num)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.keypad-container {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		pointer-events: none;

		&.backdrop {
			pointer-events: auto;
		}

		.keypad-wrap {
			pointer-events: auto;
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			padding-top: 27px;
			padding-bottom: 20px;
			border-top-left-radius: 40px;
			border-top-right-radius: 40px;
			text-align: center;

			background-color: #E8E8E8;

			.number {
				margin: 4px;
			}

			.btn-next {
				margin-top: 21px;
			}
		}
	}
</style>