<template lang="pug">
	.remittance-wrap
		v-image.title-remittance(src="title_remittance.png")
		.remittance
			v-image(src="label_remittance_part.png")
			v-layout.part(justify-space-between)
				v-image(
					v-for="part in ['plan', 'buy', 'make']"
					:key="part"
					v-if="part !== user.part"
					@click="onClickPart(part)"
					:src="`btn_part_${part}${part === selectedPart ? '_active': ''}.png`"
				)
			v-image(src="label_remittance_point.png")
			v-layout.point(justify-space-between)
				v-image(src="btn_point50.png" @click="add(50)")
				v-image(src="btn_point100.png" @click="add(100)")
				v-image(src="btn_point500.png" @click="add(500)")
			v-image(src="label_remittance_total.png")
			v-layout.total(
				v-ripple
				align-center
				justify-end
				@click="showNumberKeypad"
			)
				span.total-point {{ point }}
				span.point-annotation p
				v-image(
					src="btn_clear.png"
					@click="clear"
				)
			v-layout.buttons(
				justify-center
			)
				v-image(src="btn_return.png" @click="$emit('close')")
				v-image(
					v-if="point && selectedPart"
					src="btn_send_active.png"
					@click="confirmSend"
				)
				v-image(
					v-else
					src="btn_send.png"
				)

		v-slide-y-reverse-transition
			number-keypad(
				@click-outside="onClickOutside"
				v-if="numberPad"
				@click="onPadClick"
				@close="numberPad = null"
			)



</template>

<script>
	import NumberKeypad from "@/components/modules/NumberKeypad";

	export default {
		components: {
			NumberKeypad,
		},
		computed: {
			user() {
				return this.$userData.user
			}
		},
		data() {
			return {
				numberPad: false,

				selectedPart: null,
				point: 0,
			}
		},
		methods: {
			showNumberKeypad() {
				$('.remittance-wrap').animate({
					scrollTop: 200
				})
				this.numberPad = true
			},
			onClickOutside() {
				this.numberPad = false
				$('.remittance-wrap').animate({
					scrollTop: 0
				})
			},
			onClickPart(part) {
				this.selectedPart = part
			},
			add(point) {
				this.point += point
			},
			onPadClick(num) {
				let point = String(this.point)
				if (num === 'back') {
					point = point.substring(0, point.length - 1)
				} else {
					point += num
				}
				this.point = parseInt(point || '0')
			},
			clear() {
				this.point = 0
			},
			confirmSend() {
				this.$showDialog({
					type: 'send_point',
					buttons: [
						{ type: 'cancel', },
						{ type: 'send', click: this.send},
					]
				})
			},
			async send() {
				const totalPoint = this.point + 50

				if (this.user.capital < totalPoint) {
					this.$showToast('point_not_enough')
					return
				}

				const response = await this.$post('/send/point', {
					part: this.selectedPart,
					point: this.point,
				})
				if (response.code !== '0000') {
                    this.$showToast('point_not_enough')
					return
				}

				this.$publishMqtt('team', 'reload', {})

				this.selectedPart = null
				this.point = 0

				this.$showToast('send_succeed')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.remittance-wrap {
		width: 100%;
		text-align: center;
		padding-top: 30px;
		margin-top: -70px;

		.remittance {
			margin-top: 33px;
			padding: 0 54px 40px;
			text-align: left;

			.part, .point {
				margin-top: 17px;
				margin-bottom: 34px;
			}
			.total {
				margin-top: 7px;
				height: 60px;
				border-bottom: 1px solid #ccc;
				color: #C10D0D;
				margin-bottom: 325px;

				.total-point {
					outline: none;
					font-size: 40px;
					font-weight: bold;
				}
				.point-annotation {
					margin-left: 10px;
					margin-right: 15px;
					font-size: 32px;

				}

			}

			.buttons {
				position: fixed;
				bottom: 120px;
				left: 0;
				width: 100%;
			}
		}
	}

</style>