<template lang="pug">
	img(:srcset="`${path}/${src} ${scale? scale + 'x' : '2x'}`"
		:width="width ? width : ''"
		@click.prevent.stop="onClick"
	)
</template>

<script>
	export default {
	    data() {
	        return {
	            path: process.env.VUE_APP_S3_URL + '/images'
	        }
	    },
		props: [
		    'src',
			'scale',
			'width',
		],
		mounted() {
        },
        methods: {
	    	onClick() {
	    		this.$emit('click')
		    }
		}
	}
</script>

<style lang="scss" scoped>
	img {
		display: inline-block;
		vertical-align: middle;
	}
</style>