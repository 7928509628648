<template lang="pug">
	div.tab-wrap
		v-layout.tab-container(
			column
			align-center
		)

			.lego-layer.fill-height
				.lego-title(v-if="legos.length > 0") 보유한 레고 목록입니다
				v-layout.lego-wrap(
					v-if="legos.length > 0"
					column
				)
					.legos
						v-layout.lego(
							align-center
							justify-space-between
							v-for="(item, index) in legos"
							:key="index"
						)
							v-image.lego-img(:src="`icon_lego_${item.name}.png`")
							.owned 현재 보유 개수 : {{ legoOwned(item) }}
				.empty(v-else)
					span 보유한 레고가 없어요

</template>

<script>

	export default {
		data() {
			return {
			}
		},

		computed: {
			legos() {
				const legos = []
				this.inventories.forEach(item => {
					if (item.amount > 0) {
						legos.push({
							name: item.name,
							amount: 0,
						})
					}
				})
				return legos
			}
		},
		watch: {
		},

		beforeDestroy() {
		},

		async mounted() {
		},

		methods: {

			legoOwned(item) {
				return (this.inventories.find(v => v.name === item.name) || { amount : 0 }).amount
			},
		}
	}
</script>

<style lang="scss" scoped>
	.tab-wrap {
		height: 100%;

		.lego-title {
			margin-top: 40px;
			font-size: 22px;
			color: #5D5D5D;
		}
		.lego-wrap {
			padding-bottom: 150px;

			.message {
				margin-top: 40px;
				margin-bottom: 16px;
			}

			.legos {
				width: 512px;
				padding-bottom: 30px;
				.lego {
					position: relative;
					background-color: #fff;
					margin: 10px 0;
					padding: 0 26px;
					border-radius: 15px;
					border: 1px solid #D1D1D1;
					height: 100px;

					.lego-img {
						height: 50px;
					}

					.owned {
						font-size: 17px;
					}

				}
			}

		}


		.empty {
			margin-top: 273px;
			font-size: 22px;
			color: #5D5D5D;
			text-align: center;
		}

	}
</style>