<template lang="pug">
	.setting-wrap
		v-card.elevation-0
			v-card-title CALL 설정
			v-card-subtitle.subtitle ※ 설정 탭에서 변경한 데이터는 이전 교육에는 적용되지 않으며, 앞으로 추가하는 교육에만 반영됩니다.

			v-card-text
				v-card
					v-card-subtitle.font-weight-bold CALL 진행 시간 설정
					v-card-text
						v-layout(column justify-center align-center)
							v-layout(align-center)
								span 1회
								input.ml-2(
									type="number"
									v-model="settings.call_time"
									style="width: 80px;"
								)
								span.ml-1 초
					v-card-actions
						v-spacer
						v-btn(color="secondary" @click="save") 저장
			v-card-text
				v-card
					v-card-subtitle.font-weight-bold CALL 가격 설정
					v-card-text
						v-layout(column justify-center align-center)
							v-layout(align-center)
								span 1회
								input.ml-2(
									type="number"
									v-model="settings.call_price"
									style="width: 80px;"
								)
								span.ml-1 p
					v-card-actions
						v-spacer
						v-btn(color="secondary" @click="save") 저장
</template>

<script>
	export default {
		data() {
			return {
				settings: {
					call_time: 0,
					call_price: 0,
				}
			}
		},
		mounted() {
			this.$adminData.tabIndex = 2
			this.$adminData.tabSubIndex = 1

			this.getSettings()
		},

		methods: {
			async getSettings() {
				const response = await this.$get('/admin/setting')
				if (response.code === '0000') {
					const data = response.data
					Object.keys(this.settings).forEach(k => {
						this.settings[k] = data[k]
					})
				}
			},
			async save() {
				this.$store.commit('showLoader')
				await this.$post('/admin/setting', this.settings)
				this.$store.commit('hideLoader')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.setting-wrap {
		margin-top: 20px;
		padding: 10px;

		.subtitle {
			color: #C00000;
		}

		input {
			border: 1px solid #000;
			padding: 5px 0 5px 10px;
		}
	}
</style>