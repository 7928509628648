<template lang="pug">
	.app-bar-wrap
		v-layout.app-bar(
			justify-space-between
			align-center
		)
			.logo
				v-image.logo(
					src="logo.png"
					width="120"
				)
			.status(
				v-if="gameStatus"
			) 진행상태 : {{ gameStatus }} {{ time ? (gameStatus.endsWith('준비') ? `(시작까지 ${timeFormat})` : `(종료까지 ${timeFormat})`) :	''}}
			v-layout.flex-grow-0(
				column
				align-end
			)
				v-image.btn-logout(
					src="btn_logout.png"
					@click="logout"
				)
				v-layout.training.mt-2(
					v-if="admin.type === 'admin'"
					align-center
				)
					span.training_label 현재교육 :
					span {{ $adminData.training.name || '선택된 교육 없음' }}
		v-layout.gnb-wrap(align-end)
			.gnb
				v-hover(
					v-slot="{ hover }"
					v-for="(menu, index) in menus[this.admin.type]"
					:key="index"
					v-if="(!menu.index || menu.index === tabIndex) && !tabChanged"
				)
					span.menu(
						:class="tabIndex === index || tabIndex === menu.index ? 'active' : ''"
						@click="changeTab(index)"
					) {{ menu.name }} {{ menu.children ? '▼' : '' }}
							v-list.sub-menu(
								v-if="menu.children && hover"
							)
								v-list-item(
									v-for="(item, subIndex) in menu.children"
									:key="item.name"
									link
									color="#C10D12"
									@change="changeTab(index, subIndex)"
								)
									v-list-item-title(
										v-text="item.name"
									)
				v-btn(
					v-if="admin.type === 'admin'"
					color="error"
					@click="openDirectionReset"
				) 상태 초기화

		v-dialog(
			width="700"
			v-model="showDirectionReset"
			content-class="direction-reset"
		)
			v-card()
				v-card-title 상태 초기화
				v-card-text
					v-row.filter
						v-col(cols="6")
							v-select(
								label="팀"
								dense
								hide-details
								outlined
								:items="teams"
								v-model="directionFilter.team"
							)
						v-col(cols="6")
							v-select(
								label="파트"
								dense
								hide-details
								outlined
								:items="parts"
								v-model="directionFilter.part"
							)
					.directions
						.low(
							v-if="directions.low"
						)
							div.type 하
							v-layout.item.justify-center.align-center(
								v-for="item in directions.low"
								:class="directionClass(item)"
								@click="confirmReset(item)"
							) 하{{ item.order }}
						.medium(
							v-if="directions.medium"
						)
							div.type 중
							v-layout.item.justify-center.align-center(
								v-for="item in directions.medium"
								:class="directionClass(item)"
								@click="confirmReset(item)"
							) 중{{ item.order }}
						.high(
							v-if="directions.high"
						)
							div.type 상
							v-layout.item.justify-center.align-center(
								v-for="item in directions.high"
								:class="directionClass(item)"
								@click="confirmReset(item)"
							) 상{{ item.order }}
				v-card-actions
					v-spacer
					v-btn(
						color="secondary"
						@click="showDirectionReset = false"
					) 닫기
					v-spacer


</template>

<script>
	export default {
		data() {
			return {
				menus: {
					superadmin: [
						{name: 'Client 계정 관리', path: '/manage/users'},
						{name: '교육 모니터링', path: '/manage/monitoring'},
						{name: '기본값 설정', children: [
								{name: '자본금/가격 설정', path: '/manage/setting/money'},
								{name: 'CALL 설정', path: '/manage/setting/call'},
								{name: '프로젝트 지시서 설정', path: '/manage/setting/direction'},
								{name: '운송게임 설정', path: '/manage/setting/game'},
							]
						},
						{name: '현황/결과', path: '/manage/result', index: 6},
					],
					admin: [
						{name: '교육 선택', path: '/admin/trainings'},
						{name: '교육 정보', path: '/admin/training'},
						{
							name: '기획파트', children: [
								{name: '프로젝트 지시서', path: '/admin/plan/direction'},
								{name: '완료 목록', path: '/admin/plan/completed'},
							]
						},
						{
							name: '구매파트', children: [
								{name: '운송게임 설정', path: '/admin/buy/game'},
								{name: '운송 목록', path: '/admin/buy/completed'},
							]
						},
						{
							name: '제작파트', children: [
								{name: '제작 검수', path: '/admin/make/inspection'},
								{name: '보유한 레고 목록', path: '/admin/make/owned'},
							]
						},
						{name: '포인트', path: '/admin/point'},
						{name: '현황/결과', path: '/admin/result'},
					],
				},
				tabChanged: false,
				timer: null,
				time: null,

				showDirectionReset: false,
				directionFilter: {},
				directions: [],
			}
		},

		computed: {
			tabIndex() {
				return this.$adminData.tabIndex
			},
			tabSubIndex() {
				return this.$adminData.tabSubIndex
			},
			admin() {
				return this.$adminData.admin
			},
			timeFormat() {
				if (!this.time) return ''
				const time = moment.utc(this.time*1000)
				if (time.unix() > 3600) return time.format('HH:mm:ss')
				return time.format('mm:ss')
			},
			teams() {
				if (!this.training) return []

				const teams = []
				for (let no = 1; no <= this.training.team_count; no++) {
					teams.push({
						text: no + '팀',
						value: no
					})
				}

				return teams
			},
			parts() {
				return [
					{ text: '기획', value: 'plan' },
					{ text: '구매', value: 'buy' },
					{ text: '제작', value: 'make' },
				]
			}
		},
		watch: {
			mqttData(val) {
				if (val) {
					if (val.action === 'round' || val.action === 'round_ended') {
						this.$set(this.$adminData.training, 'timestamp', moment().unix())

						this.stopTimer()
						if ([1,1.5,2].indexOf(this.round) > -1) {
							this.startTimer(this.round)
						}
					}
				}
			},
			directionFilter: {
				deep: true,
				handler(val) {
					if (val.team && val.part) {
						this.getDirectionList()
					}
				}
			}
		},
		mounted() {
			if (!this.$route.path.startsWith('/manage')) {
				setTimeout(_ => {
					if (this.tabIndex > 0 && !this.$cookie.get('training_id')) {
						if (this.$route.path !== '/admin/trainings') {
							this.$router.replace('/admin/trainings')
						}
						this.$store.commit('showDialog', {
							type: 'alert',
							message: '선택된 교육이 없습니다.\n교육을 먼저 선택하세요.'
						})
					}


				}, 500)
			}

			this.stopTimer()
			if ([1,1.5,2].indexOf(this.round) > -1) {
				this.startTimer(this.round)
			}
		},

		methods: {
			changeTab(index, subIndex) {

				if (!this.$route.path.startsWith('/manage')) {
					if (index > 0 && !this.$cookie.get('training_id')) {
						if (this.$route.path !== '/admin/trainings') {
							this.$router.replace('/admin/trainings')
						}
						this.$store.commit('showDialog', {
							type: 'alert',
							message: '선택된 교육이 없습니다.\n교육을 먼저 선택하세요.'
						})
						return
					}
				}

				this.tabChanged = true

				setTimeout(_ => {
					this.tabChanged = false
				})

				const menu = this.menus[this.admin.type][index]

				if (subIndex === undefined) {
					if (this.tabIndex === index || this.tabIndex === menu.index) return
					if (!menu.path) return
					this.$router.push(menu.path)
				} else {
					if (this.tabIndex === index && this.tabSubIndex === subIndex) return
					const item = menu.children[subIndex]
					this.$router.push(item.path)
				}
			},

			logout() {
				this.$cookie.delete('admin_token')
				this.$cookie.delete('tpc_token')
				this.$router.replace('/login')
			},

			startTimer(round) {
				this.timer = setInterval(_ => {
					let endTime
					if (round === 1.5) {
						endTime = `${this.training.date} ${this.training[`start_time2`]}`
					} else {
						endTime = `${this.training.date} ${this.training[`end_time${round}`]}`
					}

					this.time = Math.ceil(moment(endTime).diff(moment()) / 1000)

					if (this.time <= 0) {
						this.stopTimer()
					}
				}, 1000)
			},
			stopTimer() {
				if (this.timer) {
					clearInterval(this.timer)
					this.$set(this.$adminData.training, 'round', null)
					this.timer = null
					this.time = null
				}
			},
			openDirectionReset() {
				this.directionFilter = {}
				this.directions = []
				this.showDirectionReset = true
			},
			async getDirectionList() {
				const response = await this.$get('/admin/part/directions', {
					training_id: this.training.id,
					team: this.directionFilter.team,
				})

				const directions = {}
				response.data.forEach(v => {

					if (!directions[v.type]) {
						directions[v.type] = []
					}

					directions[v.type].push(v)
				})

				this.directions = directions

			},
			directionClass(item) {
				const status = item[`${this.directionFilter.part}_status`]

				switch (status) {
					case 1:
						return 'planing'
					case 2:
						return 'completed'
				}
			},
			confirmReset(item) {
				const status = item[`${this.directionFilter.part}_status`]

				let message = '선택한 프로젝트를 미완료 상태로 초기화하시겠습니까?'
				if (this.directionFilter.part === 'make') {
					message = '선택한 프로젝트를 검수 전 상태로 초기화하시겠습니까?'
				}

				if (status === 1) {
					this.$store.commit('showDialog', {
						type: 'confirm',
						message: message,
						okCb: async _ => {
							await this.$post('/admin/part/direction/reset', {
								id: item.id,
								part: this.directionFilter.part
							})

							await this.getDirectionList()
						}
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.app-bar-wrap {
		position: sticky;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 10;

		background-color: #C10D0D;

		.app-bar {
			height: 100px;
			padding: 0 20px;

			.status {
				color: #fff;
			}

			.training {
				height: 35px;
				background-color: #fff;
				border-radius: 20px;

				padding: 3px 20px 0;
				color: #222222;

				.training_label {
					color: #C10D0D;
					padding-right: 5px;
				}
			}

			.btn-logout {
				cursor: pointer;
			}
		}

		.gnb-wrap {
			height: 50px;

			background-color: #fff;
			border-top-left-radius: 50px;
			border-top-right-radius: 50px;

			padding: 0 50px;
			color: #BCBCBC;

			.menu {
				position: relative;
				display: inline-block;
				border-bottom: 2px solid #BCBCBC;
				padding: 5px;
				margin-right: 50px;

				cursor: pointer;

				&.active {
					color: #C10D12;
					border-bottom-color: #C10D12;
					cursor: default;
				}

				.sub-menu {
					position: absolute;
					left: 0;
					top: 34px;
					border: 1px solid #C10D12;
					padding: 0;
					z-index: 1;

					.v-list-item:hover {
						background-color: #ffe3e4;
					}
					.v-list-item + .v-list-item {
						border-top: 1px solid #C10D12;
					}
				}
			}

			.btn-direction-reset {
				position: absolute;
				right: 15px;
			}
		}
	}

	.direction-reset {
		.directions {
			margin-top: 10px;
			height: 700px;
			overflow-y: scroll;
			overflow-x: hidden;
			font-size: 17px;
			padding: 10px;
			background-color: #f9f9f9;

			& > div {
				margin-left: -5px;
				margin-right: -5px;

				.type {
					margin-top: 10px;
					margin-left: 5px;
				}

				.item {
					display: inline-flex;
					font-size: 15px;
					width: 100px;
					height: 100px;
					border-radius: 10px;
					border: 1px solid #efefef;
					background-color: #fff;

					margin: 5px;

					cursor: default;

					&.planing {
						background-color: #FEC6C7;
						border-color: #FDA3A3;
						cursor: pointer;
					}
					&.completed {
						background-color: #C10D12;
						border-color: #A51116;
						color: #fff;
					}
				}

			}
		}
	}
</style>
