<template lang="pug">
	.container-wrap.mt-7.ml-3.pb-1
		v-card.elevation-0
			v-card-title 보유 레고
			v-card-actions
				v-spacer
				v-select.flex-grow-0(
					v-model="team"
					dense
					hide-details
					outlined
					:items="teams"
					@change="getLegos"
				)
			v-card(
				style="width: 50%; margin: 0 auto;"
			)
				v-card-text
					v-data-table(
						:headers="headers"
						:items="legos"
						:items-per-page="-1"
						:loading="isLoading"
						loading-text="데이터 조회 중.. 잠시만 기다리세요."
						hide-default-footer
					)
						template(
							v-slot:item.legos="{ item }"
						)
							v-layout.lego-item(
								align-center justify-space-between
								v-for="(lego, index) in item.legos"
								:key="`legoitem-${item.id}-${index}`"
							)
								img(:src="`https://legorace.s3.ap-northeast-2.amazonaws.com/images/icon_lego_${lego && lego.name}.png`")
								span {{ lego.amount }} 개
</template>

<script>
	export default {
		data() {
			return {
				isLoading: false,
				teams: ['전체'],
				team: '전체',
				legos: [],
				headers: [
					{text: '팀', sortable: true, value: 'team', width: '50%'},
					{text: '보유한 레고 목록', sortable: true, value: 'legos'},
				],
			}
		},
		mounted() {
			this.$adminData.tabIndex = 4
			this.$adminData.tabSubIndex = 1

			_.range(1, this.training.team_count + 1).forEach(v => {
				this.teams.push(v + '팀')
			})

			this.getLegos()
		},
		methods: {
			async getLegos() {
				this.isLoading = true

				const team = this.team === '전체' ? '' : this.team
				const response = await this.$get('/admin/training/lego/owned', { id: this.training.id, team: team })

				const legos = []
				response.data.forEach(lego => {
					if (lego.amount === 0) return

					const item = legos.find(v => v.team === lego.team)

					if (!item) {
						legos.push({
							team: lego.team,
							legos: [lego]
						})
					} else {
						item.legos.push(lego)
					}
				})

				this.legos = legos

				this.isLoading = false
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrap {
		margin-top: 20px;
		padding: 10px;

		.subtitle {
			color: #C00000;
		}
	}

	.lego-item {
		margin: 5px 0;
		padding: 5px;
		border: 1px solid #efefef;
		border-radius: 5px;
		background-color: #fff;

		img { height: 30px; }
		span { color: #C10D0D; font-weight: bold; }
	}

</style>
