<template lang="pug">
	.module-wrap.pa-5
		v-card
			v-card-subtitle 지급/차감
			v-card-text
				v-layout(column justify-center align-center)
					v-form(ref="form")
						v-layout(align-center)
							v-layout(align-center)
								span
									v-select.ml-2(
										label="파트"
										dense
										outlined
										style="width: 100px;"
										:items="parts"
										v-model="item.part"
										:rules="[v => !!v || '필수항목']"
									)
							v-layout.ml-5(align-center)
								span
									v-select.ml-2(
										label="팀"
										dense
										outlined
										style="width: 100px;"
										:items="teams"
										v-model="item.team"
										:rules="[v => !!v || '필수항목']"
									)
							v-layout.ml-10(align-center)
								span
									v-text-field.ml-2(
										label="포인트"
										type="number"
										dense
										outlined
										min="0"
										style="width: 150px;"
										v-model="item.point"
										append-icon="mdi-alpha-p"
										:rules="[v => !!v || '필수항목']"
									)
							v-layout.ml-10(align-center)
								span
									v-text-field.ml-2(
										label="사유"
										dense
										outlined
										style="width: 200px;"
										v-model="item.name"
										:rules="[v => !!v || '필수항목']"
									)
							v-layout.ml-10(align-center)
								span.mb-6
									v-btn(color="#8BC34A" dark @click="setPoint(1)") 지급
									v-btn.ml-2(color="#EF5350" dark @click="setPoint(-1)") 차감
</template>

<script>
	export default {
		data() {
			return {
				item: {},
				parts: [
					{ text: '기획', value: 'plan' },
					{ text: '구매', value: 'buy' },
					{ text: '제작', value: 'make' },
				],
				teams: [],
			}
		},
		mounted() {
			_.range(1, this.training.team_count + 1).forEach(v => {
				this.teams.push({
					text: v + '팀',
					value: v,
				})
			})

			this.$emit('change')

		},
		methods: {
			async setPoint(sign) {
				if (!this.$refs.form.validate()) return

				const item = _.cloneDeep(this.item)
				item.point *= sign

				await this.$post('/admin/point/process', {
					id: this.training.id,
					...item
				})

				this.item = {}
				this.$refs.form.reset()

				this.$publish({
					type: 'part',
					trainingId: this.training.id,
					team: item.team,
					part: item.part,
					action: 'reload',
				})

				this.$emit('change')
			}
		}
	}
</script>

<style lang="scss" scoped>


	input, select {
		border: 1px solid #000;
		padding: 5px 0 5px 10px;
	}
</style>