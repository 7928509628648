<template lang="pug">
	v-app
		div.app-wrap
			router-view
</template>

<script>

    export default {
        name: 'App',
        components: {
        },
	    data() {
        	return {
	        }
	    },
	    beforeCreate() {
            // if (!this.$cookie.get('token') && this.$route.path !== '/login') {
            //     this.$router.replace('/login')
            // }
        },
	    mounted() {
	    },
	    methods: {
	    }

    };
</script>

<style lang="scss" scoped>
	.app-wrap {
		transform-origin: top;
		height: 100%;
	}
</style>

<style lang="scss">

	html, body {
		width: 100%;
		height: 100%;
		overflow: hidden !important;
		user-select: none;
	}
	.v-application {
		font-family: GmarketSans, sans-serif !important;
		/*font-weight: 300;*/
		background-color: #F7F7F7 !important;
		height: 100%;
	}

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	img.btn {
		cursor: pointer;
	}

	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}

	.delayed-fade-enter-active,
	.delayed-fade-leave-active {
		transition-property: opacity;
		transition-duration: .5s;
	}

	.delayed-fade-enter-active {
		transition-delay: .5s;
	}

	.delayed-fade-enter,
	.delayed-fade-leave-active {
		opacity: 0
	}

	.open-enter-active, .open-leave-active {
		transition: opacity .3s, transform .3s;
	}
	.open-enter, .open-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
		transform-origin: 50% 50%;
		transform: scale(0);
	}

	.no-scrollbar {

		&::-webkit-scrollbar {
			width: 0;
			visibility: hidden;
		}
		/* Track */
		&::-webkit-scrollbar-track {
			background-color: rgba(0,0,0,0);
		}
		/* Handle */
		&::-webkit-scrollbar-thumb {
			background-color: rgba(0,0,0,0);
		}
		&::-webkit-scrollbar-thumb:window-inactive {
			background-color: rgba(0,0,0,0);
		}
	}


</style>
