import { render, staticRenderFns } from "./Remittance.vue?vue&type=template&id=900dc0ce&scoped=true&lang=pug&"
import script from "./Remittance.vue?vue&type=script&lang=js&"
export * from "./Remittance.vue?vue&type=script&lang=js&"
import style0 from "./Remittance.vue?vue&type=style&index=0&id=900dc0ce&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "900dc0ce",
  null
  
)

export default component.exports