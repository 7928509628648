import Vue from 'vue';

export default {
	install(Vue) {

		Vue.prototype.$gameData = Vue.observable({
			scale: 1,
			client: null,
			dialogs: {},
			toast: null,
			mqttData: null,
			gameStarted: false,
		})
		Vue.prototype.$adminData = Vue.observable({
			admin: {},
			tabIndex: -1,
			tabSubIndex: -1,
			training: {}
		})

		Vue.prototype.$userData = Vue.observable({
			user: {},
			teamData: {},
			setting: {},
			deliveries: [],
			data: {},
			inventories: [],
		})
	}
	
}