<template lang="pug">
	div.part-wrap(v-if="ready")
		v-layout.tabs
			v-image(src="tab_plan1.png" v-if="tab === 0")
			v-image(src="tab_plan1_deactive.png" v-else
				@click="changeTab(0)"
			)
			v-image(src="tab_plan2.png" v-if="tab === 1")
			v-image(src="tab_plan2_deactive.png" v-else
				@click="changeTab(1)"
			)
		div.content-wrap
			transition(name="fade")
				plan-tab1.tab(v-if="tab === 0" @change="tab = 1")
				plan-tab2.tab(v-if="tab === 1" @change="tab = 0")
</template>

<script>
	import PlanTab1 from "@/components/frontend/modules/PlanTab1";
	import PlanTab2 from "@/components/frontend/modules/PlanTab2";

	export default {
		components: {
			PlanTab1,
			PlanTab2,
		},
		data() {
			return {
				tab: 0,
				ready: false,
			}
		},
		async mounted() {
		    const index = this.$cookie.get('tabindex')
			if (index !== null) {
			    this.tab = parseInt(index)
			}

			this.ready = true
        },
		methods: {
		    changeTab(index) {
		        this.tab = index

			    this.$cookie.set('tabindex', index)
		    },
		}
    }
</script>

<style lang="scss" scoped>
	.part-wrap {
		width: 100%;
		.tabs {
			position: fixed;
			top: 150px;
			z-index: 1;
		}
		.content-wrap {
			.tab {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
			}
		}
	}
</style>