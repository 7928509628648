<template lang="pug">
	.delivery-wrap()
		v-card.elevation-0
			v-card-title 운송 목록
			v-card-actions
				v-spacer
				v-select.flex-grow-0(
					v-model="team"
					dense
					hide-details
					outlined
					:items="teams"
					@change="getDeliveries"
				)
			v-card-text
				v-card(
					style="width: 75%; margin: 0 auto;"
				)
					v-card-text
						v-data-table(
							:headers="headers"
							:items="deliveries"
							:items-per-page="-1"
							:loading="isLoading"
							loading-text="데이터 조회 중.. 잠시만 기다리세요."
							hide-default-footer
						)
							template(
								v-slot:item.legos="{ item }"
							)
								v-layout.lego-item(
									align-center justify-space-between
									v-for="(lego, index) in item.legos"
									:key="`legoitem-${item.id}-${index}`"
								)
									img(:src="`https://legorace.s3.ap-northeast-2.amazonaws.com/images/icon_lego_${lego && lego.name}.png`")
									span {{ lego.amount }} 개
</template>

<script>
	export default {
		data() {
			return {
				isLoading: false,
				teams: ['전체'],
				team: '전체',
				headers: [
					{text: '시간', sortable: true, value: 'time'},
					{text: '팀', sortable: true, value: 'team'},
					{text: '운송한 설계도', sortable: true, value: 'type_order'},
					{text: '운송한 레고', sortable: true, value: 'legos'},
				],
			}
		},
		async mounted() {
			this.$adminData.tabIndex = 3
			this.$adminData.tabSubIndex = 1

			_.range(1, this.training.team_count + 1).forEach(v => {
				this.teams.push(v + '팀')
			})

			await this.getDeliveries()
		},

		methods: {
			async getDeliveries() {
				this.isLoading = true

				const team = this.team === '전체' ? '' : this.team
				const response = await this.$get('/admin/training/delivery/completed', { id: this.training.id, team: team })

				this.deliveries = response.data.map(v => {
					v.legos = JSON.parse(v.legos)
					return v
				})

				this.isLoading = false
			}
		}
	}
</script>

<style lang="scss" scoped>
	.delivery-wrap {
		margin-top: 20px;
		padding: 10px;

		.subtitle {
			color: #C00000;
		}

		.lego-item {
			margin: 5px 0;
			padding: 5px;
			border: 1px solid #efefef;
			border-radius: 5px;
			background-color: #fff;

			img { height: 30px; }
			span { color: #C10D0D; font-weight: bold; }
		}
	}
</style>
