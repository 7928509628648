<template lang="pug">
	div.tab-wrap
		v-layout.tab-container(
			column
			align-center
		)
			.title-wrap
				v-image.progress(
					v-if="progress < 4"
					:src="`buy_progress${progress}.png`"
				)
				.buy-title(
					v-if="progress === 2 && directions.length > 0"
				) 보낼 설계도를 선택해주세요

			.direction-layer.fill-height(v-if="progress === 2" :key="2")
				v-layout.direction-wrap(
					v-if="directions.length > 0"
					column
				)
					.legend
						v-image(src="buy_direction_legend.png")
					.directions(
						v-for="type in ['low', 'medium', 'high']"
						:key="type"
					)
						v-layout.direction(
							justify-center
							align-center
							v-for="(direction, index) in directionItems(type)"
							:key="index"
							:class="`${selectedClass(direction)} ${completedClass(direction)}`"
							@click="selectItem(direction)"
						) {{ names[type] }}{{ direction.order }}
						.direction-name(v-if="directionItems(type).length === 0") {{ names[type] }}
				.empty(v-else)
					span 진행 중인 프로젝트가 없어요

			.lego-layer.fill-height(v-if="progress === 3" :key="3")
				v-layout.lego-wrap(
					v-if="legos.length > 0"
					column
				)
					.message <span class='highlight'>{{ names[selected.type]}}{{selected.order}}</span> 설계도와 함께 보낼 레고를 선택해주세요
					.legos
						.lego(
							v-for="(item, index) in legos"
							:key="index"
							:class="warning(item) ? 'lego-warning' : ''"
						)
							v-image.lego-img(:src="`icon_lego_${item.name}.png`")

							v-image.icon-warning(
								v-if="warning(item)"
								src="icon_stock_warning.png"
							)

							.owned 현재 보유 개수 : {{ legoOwned(item) }}

							v-layout.amount-wrap
								v-image(
									v-if="item.amount === 0"
									src="btn_minus_disabled.png"
								)
								v-image.btn-lego-amount(
									v-else
									src="btn_minus.png"
									@click="setLegoAmount(item, -1)"
								)
								v-layout.amount(align-center justify-center) {{ item.amount }}
								v-image(
									v-if="legoOwned(item) <= item.amount"
									src="btn_plus_disabled.png"
								)
								v-image.btn-lego-amount(src="btn_plus.png"
									v-else
									@click="setLegoAmount(item, 1)"
								)
					v-layout.buttons(justify-center)
						v-image(
							src="btn_back.png"
							@click="backToDirection"
						)
						v-image(
							v-if="canSend"
							src="btn_send_active.png"
							@click="confirmSend"
						)
						v-image(
							v-else
							src="btn_send.png"
						)
				.empty(v-else)
					span 보유한 레고가 없어요
					v-layout.button(justify-center)
						v-image(
							src="btn_buy_lego.png"
							@click="$emit('change')"
						)
			.result-layer.fill-height(v-if="progress === 4" :key="4")
				v-layout.result-title(align-center) {{ names[resultItem.type] }}{{ resultItem.order }}
				.legos
					v-layout.lego(
						justify-space-between
						align-center
						v-for="(lego, index) in results"
						:key="index"
					)
						v-image.lego-img(:src="`icon_lego_${lego.name}.png`")
						v-layout.amount.flex-grow-0(align-center)
							span {{ lego.amount }}
							span.unit 개
					v-layout.lego(
						justify-space-between
						align-center
						v-if="results.length === 0"
					)
						div.empty-label 다시 보낸 레고
						v-layout.amount.flex-grow-0(align-center)
							span 0
							span.unit 개
				v-layout.buttons(
					v-if="canResend"
					justify-center
				)
					v-image(src="btn_back.png"
						@click="backToDirection()"
					)
					v-image(
						src="btn_resend.png"
						@click="resend()"
					)

</template>

<script>

	export default {
		data() {
			return {
				progress: 2,
				directions: [],
				names: { low: '하', medium: '중', high: '상'},
				selected: null,
				legoItems: [],
				legos: [],
				results: [],
				resultItem: null,
				canResend: false,
			}
		},

		computed: {
			mqttData() {
				return this.$gameData.mqttData
			},
			canSend() {
				const selectedCount = this.legos.filter(lego => lego.amount > 0).length
				if (!this.canResend && selectedCount === 0) return

				return this.legos.filter(lego => {
					const item = this.inventories.find(v => v.name === lego.name)

					if (lego.amount > 0) {
						if (item.amount >= lego.amount) {
							return true
						} else {
							return false
						}
					}
					return false

				}).length === selectedCount
			},
		},
		watch: {
			mqttData(val) {
				if (val && val.action === 'direction' &&
					(val.target === 'part' && val.part === 'buy' || val.target === 'team' && (val.part === 'plan' ||
						val.part === 'buy'
					))) {

					if (val.plan_status === 2) {
						this.$getUserData()
						if (!this.directions.find(v => v.type === val.type && v.order === val.order)) {
							this.directions.push(val)
						}
					}

					if (val.buy_status !== undefined) {

						if (this.selected && this.selected.type === val.type && this.selected.order === val.order) {
							console.log(val, this.selected)
							if (val.buy_status === 2 && this.selected.buy_status === 1 && val.user_id !== this.user.id) {
								this.selected.buy_status = val.buy_status

								this.progress = 2

								this.selected = null
								this.results = []
							}
						} else {
							this.directions.find(v => v.type === val.type && v.order === val.order).buy_status = val.buy_status
						}
					}
				}
			},
			inventories(val) {

				let notEnoughLego = false
				this.inventories.forEach(item => {
					const lego = this.legos.find(v => v.name === item.name)
					const legoItem = this.legoItems.find(v => v.name === item.name)
					if (!legoItem) return

					if (!lego) {
						if (item.amount > 0) {
							this.legos.push({
								name: item.name,
								amount: 0,
								no: legoItem.no,
							})
						}
					} else {
						if (item.amount < lego.amount) {
							notEnoughLego = true
						}
					}
				})
				this.legos.sort((a, b) => {
					return a.no > b.no ? 1 : -1;
				})

				if (notEnoughLego) {
					if (!this.selected || [0,2].indexOf(this.selected.buy_status) > -1) return
					this.$showToast('lego_not_enough')
				}
			}
		},

		beforeDestroy() {
			this.unLoadEvent()
			$(window).off('beforeunload', this.unLoadEvent)
		},

		async mounted() {
			$(window).on('beforeunload', this.unLoadEvent)
			await this.getTeamDirections()

			await this.setLegoItems()

			if (!this.teamData || !this.teamData.delivery_fee) {
				await this.$getUserData()
			}
		},

		methods: {
			unLoadEvent(e) {
				if (!this.selected) return

				this.selected.buy_status = this.selected.buy_status === 3 ? 2 : 0;
				this.selected.part = 'buy'
				this.selected.user_id = this.user.id

				// this.$publishMqtt('part', 'direction', this.selected)
				this.$publishDirection('part', 'buy', this.selected)

				if (e) {
					e.preventDefault();
					e.returnValue = '';
				}

			},
			async getTeamDirections() {
				let response
				response = await this.$get('/team/directions')

				this.$set(this, 'directions', response.data.filter(v => v.plan_status === 2 || v.plan_status === 3))
			},

			async setLegoItems() {
				// 레고 가격과 순서를 위해 전체 레고 목록 조회
				const response = await this.$get('/legos')
				const legoItems = []
				response.data.forEach(lego => {
					lego.amount = 0
					legoItems.push(lego)
				})

				this.legoItems = legoItems

				// 보유중인 레고 목록
				const legos = []
				this.inventories.forEach(item => {
					const legoItem = this.legoItems.find(v => v.name === item.name)
					if (!legoItem) return

					if (item.amount > 0) {
						legos.push({
							name: item.name,
							amount: 0,
							no: legoItem.no
						})
					}
				})
				legos.sort((a, b) => {
					return a.no > b.no ? 1 : -1;
				})
				this.legos = legos
			},

			directionItems(type) {
				let items = []
				this.directions.forEach(direction => {
					if (direction.type === type) items.push(direction)
				})

				items.sort((a, b) => {
					return a.order > b.order ? 1 : -1;
				})

				return items
			},
			selectedClass(item) {
				if (item.buy_status === 1) return 'selected'
				if (this.selected && this.selected.type === item.type && this.selected.order === item.order) return 'selected'

				return ''
			},
			completedClass(item){
				if (item.buy_status === 2 || item.buy_status === 3) return 'completed'
				return ''
			},
			async selectItem(item) {

				if (item.buy_status === 1) {
					this.$showToast('delivery_duplicated')
					return
				} else if (item.buy_status === 3) {
					this.$showToast('delivery_duplicated_resend')
					return
				}

				this.selected = item

				item.part = 'buy'
				item.user_id = this.user.id

				await this.setLegoItems()

				this.legos.forEach(v => v.amount = 0)

				if (!item.buy_status) {
					item.buy_status = 1
					item.buy_opened = moment('YYYY-MM-DD HH:mm:ss')
					// this.$publishMqtt('part', 'direction', item)
					this.$publishDirection('part', 'buy', item)

					this.progress = 3
				} else if (item.buy_status === 2) {
					item.buy_status = 3
					// this.$publishMqtt('part', 'direction', item)
					this.$publishDirection('part', 'buy', item)

					this.canResend = true

					await this.showResult()
				}
			},
			backToDirection() {
				this.progress = 2

				let status = this.selected.buy_status
				switch(this.selected.buy_status) {
					case 1: status = 0; break;
					case 3: status = 2; break;
				}

				this.selected.buy_status = status
				this.selected.part = 'buy'
				this.selected.user_id = this.user.id

				// this.$publishMqtt('part', 'direction', this.selected)
				this.$publishDirection('part', 'buy', this.selected)
				this.selected = null
				this.results = []

				this.getTeamDirections()
			},
			legoOwned(item) {
				return (this.inventories.find(v => v.name === item.name) || { amount : 0 }).amount
			},
			warning(lego) {
				const item = this.inventories.find(v => v.name === lego.name)
				if (!item) return false
				return lego.amount > item.amount
			},
			setLegoAmount(item, amount) {
				item.amount += amount
			},
			confirmSend() {

				this.$showDialog({
					type: 'buy_send',
					message: `&nbsp;\n운송비는 [${this.teamData.delivery_fee}]입니다`,
					align: 'left',
					color: '#969696',
					buttons: [
						{ type: 'cancel' },
						{ type: 'send', click: this.send },
					]
				})
			},
			async send() {
				if (!this.canSend) {
					return
				}
				if (this.user.capital < 50) {
					this.$showToast('point_not_enough')
					this.canResend = false
					return
				}

				let response
				const legos = this.legos.filter(v => v.amount > 0)

				response = await this.$post('/delivery/complete', {
					...this.selected,
					legos: legos,
					resend: this.canResend ? 1 : 0,
				})
				if (response.code !== '0000') {
					this.progress = 2
					this.selected.buy_status = this.canResend ? 2 : 0
					if (response.code === '9401') {
						if (this.canResend) {
							this.$showToast('delivery_duplicated_resend')
						} else {
							this.$showToast('delivery_duplicated')
						}
					} else if (response.code === '9502') {
						this.$showToast('stock_not_enough')
					}

					// this.$publishMqtt('team', 'direction', this.selected)
					this.$publishDirection('team', 'buy', this.selected)
					this.selected = null
					this.canResend = false
					return
				}

				this.selected.buy_status = 2
				this.selected.part = 'buy'
				this.selected.user_id = this.user.id

				// this.$publishMqtt('team', 'direction', this.selected)
				this.$publishDirection('team', 'buy', this.selected)
				if (this.canResend) {
					this.$publishMqtt('team', 'delivery', {...this.selected, sendType: 'resend'})
				}

				await this.$wait(0)
				this.resultItem = _.cloneDeep(this.selected)
				this.progress = 4
				this.selected = null
				this.canResend = false
				this.$showDialog({
					type: 'buy2',
					buttons: [
						{ type: 'buy2', click: __ => {
							}},
					],
				})
				this.results = _.cloneDeep(this.legos.filter(v => v.amount > 0))

				this.legos.forEach(v => v.amount = 0)

				this.canResend = false
			},
			async showResult() {

				this.resultItem = this.selected

				const response = await this.$get('/delivery/result', this.selected)
				const results = []
				response.data.forEach(v => {
					const legos = JSON.parse(v.legos)
					legos.forEach(item => {
						const lego = results.find(v => v.name === item.name)
						if (lego) lego.amount += item.amount
						else results.push(item)
					})
				})
				this.results = results

				this.progress = 4

			},

			resend() {
				this.progress = 3
				this.canResend = true
			}
		}
	}
</script>

<style lang="scss" scoped>
	.tab-wrap {
		height: 100%;

		.progress {
			margin-top: 30px;
		}

		.title-wrap {
			.buy-title {
				margin-top: 40px;
				font-size: 22px;
				color: #5D5D5D;
			}
		}

		.direction-wrap {
			padding-bottom: 150px;

			.legend {
				margin-top: 26px;
				text-align: right;
			}

			.directions {
				width: 522px;
				padding: 15px 0;

				&+.directions {
					border-top: 1px solid #D1D1D1;
				}

				.direction {
					display: inline-flex;
					width: 120px;
					height: 120px;
					background-color: #ffffff;
					border: 1px solid #D1D1D1;
					border-radius: 15px;
					font-size: 30px;
					margin: 5px;

					transition: background-color 0.5s, border-color 0.5s;

					&.selected {
						background-color: #FEC6C7;
						border-color: #FDA3A3;
					}
					&.completed {
						background-color: #C10D12;
						border-color: #A51116;
						color: #fff;
					}
				}
				.direction-name {
					text-align: center;
					font-size: 45px;
					color: #D1D1D1;
					margin-top: 37px;
					margin-bottom: 30px;
				}
			}
		}

		.lego-wrap {
			padding-bottom: 150px;

			.message {
				margin-top: 40px;
				margin-bottom: 16px;
				font-size: 22px;
				color: #5D5D5D;
			}

			.legos {
				width: 512px;
				padding-bottom: 120px;
				.lego {
					position: relative;
					background-color: #fff;
					margin: 10px 0;
					padding: 20px 26px;
					border-radius: 15px;
					border: 1px solid #D1D1D1;
					height: 140px;

					&.lego-warning {
						background-color: #FEC6C7;
						border-color: #FDA3A3;
					}

					.lego-img {
						height: 50px;
					}

					.owned {
						margin-top: 14px;
					}

					.icon-warning {
						position: absolute;
						right: 28px;
						top: 17px;
					}

					.amount-wrap {
						position: absolute;
						right: 25px;
						bottom: 15px;

						.btn-lego-amount {
							cursor: pointer;
						}

						.amount {
							width: 135px;
							height: 45px;
							background-color: #F0F0F0;
							font-size: 28px;
							padding-top: 4px;
						}
					}
				}
			}

		}

		.result-layer {
			padding-bottom: 150px;

			.result-title {
				width: 100%;
				margin-top: 35px;
				background-color: #C10D12;
				border: 1px solid #A51116;
				border-radius: 15px;
				height: 60px;

				font-size: 30px;
				color: #fff;

				padding-left: 27px;
			}

			.legos {
				width: 512px;
				margin-top: 15px;
				padding-bottom: 120px;

				.lego {
					height: 100px;
					background-color: #fff;
					border: 1px solid #D1D1D1;
					border-radius: 15px;

					&+.lego {
						margin-top: 15px;
					}

					.lego-img {
						margin-left: 26px;
					}

					.empty-label {
						margin-left: 26px;
						font-size: 30px;
						padding-top: 5px;
						color: #222222;
					}

					.amount {
						margin-right: 21px;
						font-size: 40px;
						font-weight: bold;
						color: #C10D0D;
						padding-top: 5px;

						.unit {
							font-size: 32px;
							font-weight: normal;
							margin-left: 6px;
						}
					}
				}
			}

		}

		.buttons {
			position: fixed;
			left: 0;
			bottom: 120px;
			width: 100%;

			animation: fade-in 1s;
		}

		.empty {
			margin-top: 273px;
			font-size: 22px;
			color: #5D5D5D;
			text-align: center;

			.button {
				position: fixed;
				left: 0;
				bottom: 120px;
				width: 100%;

			}
		}

	}

	@keyframes fade-in {
		0% { pointer-events: none; opacity: 0}
		50% { pointer-events: none; opacity: 0}
		100% { pointer-events: auto; opacity: 1}
	}
</style>