<template lang="pug">
	.user-wrap
		v-layout(justify-end)
			v-btn(
				color="secondary"
				@click="openUser"
			) 새 계정 생성
		v-card.mt-5
			v-card-text
				v-data-table(
					:headers="headers"
					:items="users"
					:items-per-page="itemsPerPage"
					:page.sync="page"
					:loading="isLoading"
					loading-text="데이터 조회 중.. 잠시만 기다리세요."
					hide-default-footer
					@page-count="pageCount = $event"
				)
					template(
						v-slot:item.username="{ item }"
					)
						a(@click="selectUser(item)") {{ item.username }}
					template(
						v-slot:item.date="{ item }"
					)
						div(v-if="item.unlimited") 유효기간 제한 없음
						div(v-else) {{ `${item.start_date} ~ ${item.end_date}` }}
					template(
						v-slot:item.actions="{ item }"
					)
						.actions
							v-btn(
								small color="secondary"
								@click="openUser(item)"
							) 수정
							v-btn.ml-2(
								small color="error"
								@click="deleteUser(item)"
							) 삭제
				v-divider
				.text-center.pt-2.mt-5
				v-pagination(
					v-model="page"
					:length="pageCount"
				)
		v-dialog(
			v-model="showDialog"
			persistent
			width="500"
		)
			v-card
				v-card-title 계정 정보를 입력해주세요
				v-card-text
					v-form()
						v-container
							v-row(no-gutters)
								v-col.d-flex.justify-end.align-center.pr-2(cols="3") Client명
								v-col(cols="9")
									v-text-field(
										v-model="item.name"
										dense
										outlined
										hide-details
										color="secondary"
									)
							v-row.mt-2(no-gutters)
								v-col.d-flex.justify-end.align-center.pr-2(cols="3") ID
								v-col(cols="9")
									v-text-field(
										v-model="item.username"
										dense
										outlined
										hide-details
										color="secondary"
									)
							v-row.mt-2(no-gutters)
								v-col.d-flex.justify-end.align-center.pr-2(cols="3") PW
								v-col(cols="9")
									v-text-field(
										v-model="item.passwd"
										dense
										outlined
										hide-details
										color="secondary"
									)
							v-row.mt-2(no-gutters)
								v-col.d-flex.justify-end.align-center.pr-2(cols="3") 유효기간
								v-col(cols="9")
									v-layout
										v-text-field(
											v-model="item.start_date"
											label="시작일"
											type="date"
											dense
											outlined
											hide-details
											color="secondary"
										)
										v-text-field.ml-2(
											v-model="item.end_date"
											label="종료일"
											type="date"
											dense
											outlined
											hide-details
											color="secondary"
										)
								v-row(no-gutters)
									v-col
										v-layout(justify-end)
											v-checkbox(
												dense
												hide-details
												color="secondary"
												v-model="item.unlimited"
												label="유효기간 제한 없음"
											)
						v-layout(justify-center)
							v-btn(@click="showDialog = false") 취소
							v-btn.ml-2(
								color="secondary"
								@click="saveUser"
								:disabled="!canSave"
							) 저장
</template>

<script>
	export default {
		data() {
			return {
				page: 1,
				pageCount: 0,
				itemsPerPage: 20,
				headers: [
					{text: 'No.', sortable: true, value: 'id'},
					{text: 'Client명', sortable: true, value: 'name'},
					{text: 'ID', sortable: true, value: 'username'},
					{text: 'PW', sortable: true, value: 'passwd'},
					{text: '유효기간', sortable: true, value: 'date'},
					{text: '', sortable: false, value: 'actions', align: 'center'},
				],
				users: [],

				item: {},

				isLoading: false,
				showDialog: false,
			}
		},

		computed: {
			canSave() {
				let canSave = true
				if (!this.item.name) canSave = false
				if (!this.item.username) canSave = false
				if (!this.item.passwd) canSave = false
				if (!this.item.unlimited && !this.item.start_date) canSave = false
				if (!this.item.unlimited && !this.item.end_date) canSave = false

				return canSave
			}
		},

		mounted() {
			this.$adminData.tabIndex = 0
			this.getList()
		},

		methods: {
			async getList() {
				this.isLoading = true

				const response = await this.$get('/admin/admins')
				if (response.code === '0000') {
					this.users = response.data
				}

				this.isLoading = false
			},
			openUser(item) {
				if (item) {
					this.item = _.cloneDeep(item)
				} else {
					item = {}
				}
				this.showDialog = true
			},
			async saveUser() {
				this.isLoading = true
				this.$store.commit('showLoader')

				const response = await this.$post('/admin/admin', this.item)
				if (response.code === '0000') {
					this.showDialog = false
					await this.getList()
				} else {
					this.$store.commit('showDialog', {
						type: 'alert',
						message: response.message
					})
				}
				this.$store.commit('hideLoader')
				this.isLoading = false
			},

			async deleteUser(item) {
				this.$store.commit('showDialog', {
					type: 'confirm',
					message: `계정을 삭제하면 복구가 불가능합니다.\n&lt;${item.username}&gt; 계정을 삭제하시겠습니까?`,
					okCb: async _ => {
						this.$store.commit('showLoader')
						await this.$post('/admin/admin/delete', {id: item.id})

						await this.getList()
						this.$store.commit('hideLoader')
					}
				})

			},

			selectUser(item) {
				this.$cookie.set('admin_token', item.token)
				window.open('/admin/trainings')
			}
		}
	}
</script>

<style lang="scss" scoped>

	.user-wrap {
		margin-top: 20px;
		padding: 10px;
	}
</style>