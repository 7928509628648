<template lang="pug">
	div.tab-wrap
		v-layout.legend(justify-end)
			v-image(src="plan_direction_legend.png")
		v-layout.directions(
			v-for="type in ['low', 'medium', 'high']"
			:key="`type-${type}`"
		)
			v-layout.btn(
				justify-center
				align-center
				v-for="(no, index) in 8"
				:key="`no${no}`"
				:class="`${plan_status[type][no] === 1 ? 'planing' : ([2,3].indexOf(plan_status[type][no]) > -1 ? 'completed' : '')}`"
				@click="openDirection(type, no, index)"
			) {{ types[type] }}{{ no }}

		v-layout.dialog-wrap.fill-height(
			v-if="showDialog"
			column
		)
			vue-slick-carousel.slider-wrap(
				:swipe="false"
				:vertical="true"
				v-bind="settings"
				ref="carousel"
				@beforeChange="onChangeCarousel"
			)
				div(
					v-for="(image, index) in images"
					:key="index"
				)
					pinch-zoom(
						disableZoomControl="disable"
					)
						img.direction-image(:src="image || 'https://legorace.s3.ap-northeast-2.amazonaws.com/images/direction_blank.png'" class="image")
			v-layout.btn-arrows(
				column
				align-center
			)
				v-image.btn-top(
					v-if="selIndex > 0"
					src="btn_arrow_top.png"
					@click="prevDirection"
				)
				v-image.btn-bottom(
					v-if="selIndex < 1 && images.filter(v => !!v).length > 1"
					src="btn_arrow_bottom.png"
					@click="nextDirection"
				)
			v-layout.dialog-bottom(justify-center align-center column)
				v-image(v-if="currentDirection.plan_status !== 3" src="direction_hint1.png")
				v-image(v-else src="direction_hint2.png")
				v-layout.buttons(justify-center align-center)
					v-image(src="btn_back.png"
						@click="closeDialog"
					)
					v-image(v-if="currentDirection.plan_status === 3" src="btn_plan_resend.png"
						@click="confirmSend('resend')"
					)
					v-image(v-else src="btn_plan_complete.png"
						@click="confirmSend()"
					)
</template>

<script>
	import PinchZoom from "vue-pinch-zoom";
	import VueSlickCarousel from "vue-slick-carousel";
	// import style
	import "vue-slick-carousel/dist/vue-slick-carousel.css";
	// optional style for arrows & dots
	import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

	export default {
		components: {
			PinchZoom,
			VueSlickCarousel
		},
		data() {
			return {
				types: {
					low: '하',
					medium: '중',
					high: '상'
				},
				plan_status: {
					low: {},
					medium: {},
					high: {},
				},
				selIndex: 0,

				currentDirection: null,

				showDialog: false,

				images: [],
				settings: {
				},
				directions: {},
				completed: [],

				sendType: '',
				dialogId: -1,
			}
		},
		computed: {
			mqttData() {
				return this.$gameData.mqttData
			},

		},
		watch: {
			mqttData(val) {
				if (val && val.action === 'direction' && val.part === 'plan') {
					if (val.complete_with) {
						this.getTeamDirections()
					} else {
						this.$set(this.plan_status[val.type], val.order, val.plan_status)
					}

					if (this.currentDirection && this.currentDirection.type === val.type && this.currentDirection.order === val.order) {
						if (val.plan_status === 2 && this.currentDirection.plan_status === 1 && val.user_id !==
							this.user.id) {

							this.currentDirection.plan_status = val.plan_status
							this.images = []

							this.selIndex = 0
							this.showDialog = false

							this.$hideDialog(this.dialogId)
						}
					}

					this.$gameData.mqttData = null
				}
			}
		},
		beforeDestroy() {
			this.unLoadEvent()
			$(window).off('beforeunload', this.unLoadEvent)

		},
		mounted() {
			$(window).on('beforeunload', this.unLoadEvent)
			this.getTeamDirections()
		},
		methods: {
			unLoadEvent(e) {
				if (!this.currentDirection) return

				const direction = this.currentDirection
				if (direction.plan_status !== 2) {
					// this.$publishMqtt('part', 'direction', {
					//     type: direction.type,
					//     order: direction.order,
					//     plan_status: direction.plan_status === 3 ? 2 : 0,
					//     user_id: this.user.id,
					//     team: this.user.team,
					//     part: 'plan',
					// })
					this.$publishDirection('part', 'plan', {...direction, plan_status: direction.plan_status === 3 ?
							2 : 0})
				}

				if (e) {
					e.preventDefault();
					e.returnValue = '';
				}

			},
			async getTeamDirections() {
				let response
				response = await this.$get('/team/directions')

				this.completed = []
				response.data.forEach(direction => {
					this.$set(this.plan_status[direction.type], direction.order, direction.plan_status)
					if (direction.complete_with) this.completed.push(direction)
				})

				response = await this.$get('/setting/directions')
				const directions = {}
				response.data.forEach(direction => {
					if (!directions[direction.type]) directions[direction.type] = []
					directions[direction.type][direction.order] = direction
				})

				this.directions = directions
			},
			openDirection(type, no, index) {
				const plan_status = this.plan_status[type][no]

				if (plan_status === 1) {
					this.$showToast('plan_duplicated')
					return
				} else if (plan_status === 3) {
					this.$showToast('plan_duplicated_resend')
					return
				}

				const direction = this.directions[type][no]
				const images = [direction.direction_image1, direction.direction_image2]
				this.images = images

				this.currentDirection = {
					id: direction.id,
					training_id: direction.training_id,
					type: type,
					order: no,
					plan_status: plan_status === 2 ? 3 : 1,
					complete_with: (this.completed.find(v => v.type === type && v.order === no ) || {}).complete_with,
					user_id: this.user.id,
					team: this.user.team,
					part: 'plan',
				}
				if (!direction.plan_opened) {
					this.currentDirection.plan_opened = moment('YYYY-MM-DD HH:mm:ss')
				}
				// this.$publishMqtt('part', 'direction', this.currentDirection)
				this.$publishDirection('part', 'plan', this.currentDirection)

				if (direction.direction_image1 && direction.direction_image2) {
					this.$showDialog({
						type: 'plan3',
						buttons: [
							{ type: 'ok' },
						]
					})
				}

				this.showDialog = true
				setTimeout(_ => {
					// const dialogHeight = $('.dialog-wrap').get(0).getBoundingClientRect().height
					// const bottomHeight = $('.dialog-bottom').get(0).getBoundingClientRect().height
					const dialogHeight = $('.dialog-wrap').height()
					const bottomHeight = $('.dialog-bottom').height()
					$('.slider-wrap').height(dialogHeight - bottomHeight - 72)
					$('.btn-arrows').height(dialogHeight - bottomHeight - 72)
					this.selIndex = 0
				}, 100)
			},

			closeDialog() {
				const direction = this.currentDirection
				let status = direction.plan_status

				switch(direction.plan_status) {
					case 1: status = 0; break;
					case 3: status = 2; break;
				}
				// this.$publishMqtt('part', 'direction', {
				// 	id: direction.id,
				// 	type: direction.type,
				//     order: direction.order,
				//     plan_status: status,
				//     part: 'plan',
				//     team: this.user.team,
				//     user_id: this.user.id,
				// })
				this.$publishDirection('part', 'plan', {...direction, plan_status: status})

				this.images = []

				this.selIndex = 0
				this.showDialog = false
			},

			onChangeCarousel(oldIndex, newIndex) {
				this.selIndex = newIndex
			},

			prevDirection() {
				this.$refs.carousel.prev()
			},
			nextDirection() {
				this.$refs.carousel.next()
			},
			confirmSend(type = 'send') {
				this.sendType = type
				if (this.inventories.filter(v => v.name.endsWith('_direction') && v.part === this.user.part && v.amount > 0).length === 0) {
					this.$showDialog({
						type: 'plan2',
						buttons: [
							{ type: 'ok', click: _ => { this.$emit('change') } },
						],
						options: {
							completed_with: this.currentDirection.complete_with
						}
					})
					return
				}
				this.dialogId = this.$showDialog({
					type: 'plan4',
					buttons: [
						{ type: 'cancel' },
						{ type: 'send', disabled: '_disabled', click: this.send },
					],
					options: { complete_with : this.currentDirection.complete_with }
				})
			},
			async send(params) {
				const direction = this.currentDirection

				const value = {
					...direction,
					direction: params.selected,
					resend: this.sendType === 'resend',
				}

				const response = await this.$post('/direction/complete', value)
				if (response.code !== '0000') {
					if (response.code === '9401') {
						this.currentDirection.plan_status = 2

						this.images = []

						this.selIndex = 0
						this.showDialog = false

						if (this.sendType === 'resend') {
							this.$showToast('plan_duplicated_resend')
							this.sendType = ''
							return
						} else {
							this.$showToast('plan_duplicated')
							this.sendType = ''
							return
						}
					} else {
						this.$showToast('stock_not_enough')
						this.sendType = ''
						return
					}
				}
				this.$publishDirection('team', 'plan', {...direction, plan_status: 2, complete_with: params.selected,
					sendType: this.sendType})

				// this.$publishMqtt('team', 'direction', {
				// 	type: direction.type,
				//     order: direction.order,
				//     plan_status: 2,
				//     complete_with: params.selected,
				//     sendType: this.sendType,
				//     user_id: this.user.id,
				//     team: this.user.team,
				//     part: 'plan',
				// })
				this.$publishMqtt('part', 'reload')

				this.currentDirection.plan_status = 2

				this.images = []

				this.selIndex = 0
				this.showDialog = false
				this.sendType = ''

				await this.$wait(0)
				this.$showDialog({
					type: 'plan5',
					buttons: [
						{ type: 'plan5' },
					],
				})
			}

		}
	}
</script>

<style lang="scss" scoped>
	.tab-wrap {
		padding-bottom: 130px;

		.legend {
			padding-top: 38px;
			padding-right: 57px;
			margin-bottom: 17px;
		}

		.directions {
			padding: 0 55px 25px;
			flex-wrap: wrap;
			margin-right: -10px;

			&:after {
				content: '';
				border-bottom: 2px solid #D1D1D1;
				width: 100%;
				margin-top: 15px;
				margin-right: 10px;
			}
			&:last-child {
				&:after {
					border-bottom: unset;
				}
			}

			.btn {
				width: 120px;
				height: 120px;
				margin-right: 10px;
				margin-bottom: 10px;
				padding-top: 5px;

				border: 1.5px solid #D1D1D1;
				border-radius: 15px;
				background-color: #fff;

				font-size: 30px;
				line-height: 1;

				transition: background-color 0.5s, border-color 0.5s;

				&.planing {
					background-color: #FEC6C7;
					border-color: #FDA3A3;
				}
				&.completed {
					background-color: #C10D12;
					border-color: #A51116;
					color: #fff;
				}
			}
		}
	}

	.dialog-wrap {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;

		z-index: 3;

		background-color: #fff;

		.slider-wrap {
			display: flex;
			align-items: center;
			height: 100%;

			* div {
				height: 100% !important;
			}

			.direction-image {
			}
		}

		.btn-arrows {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;

			.btn-top {
				position: absolute;
				top: 25px;
				pointer-events: all;
			}
			.btn-bottom {
				position: absolute;
				bottom: 25px;
				pointer-events: all;
			}
		}

		.dialog-bottom {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			background-color: #fff;

			padding: 36px 0;

			.buttons {
				margin-top: 21px;

				animation: fade-in 1s;
			}
		}
	}

	@keyframes fade-in {
		0% { pointer-events: none; opacity: 0}
		50% { pointer-events: none; opacity: 0}
		100% { pointer-events: auto; opacity: 1}
	}

</style>
