<template lang="pug">
	.module-wrap.pa-5
		v-card
			v-card-actions
				v-spacer
				v-select.flex-grow-0(
					v-model="team"
					dense
					hide-details
					outlined
					:items="teams"
					@change="getTimelines"
				)
			v-card-text
				v-card(style="width: 70%; margin: 0 auto")
					v-card-text
						v-simple-table
							template
								thead
									tr
										th(style="width: 20%") 시간
										th(style="width: 20%") 팀
										th(style="width: 20%") 파트
										th(style="width: 20%") 내역
								tbody
									tr(
										v-for="(timeline, index) in timelines"
										:key="index"
									)
										td {{ timeline.time }}
										td {{ timeline.team }}팀
										td {{ partNames[timeline.part] }}
										td {{ timeline.name }}
									tr.text-center(
										v-if="timelines.length === 0"
									)
										td(colspan="5") 데이터가 없습니다
</template>

<script>
	export default {
		data() {
			return {
				isLoading: false,
				teams: ['전체'],
				team: '전체',
				timelines: [],
				headers: [
					{text: '시간', sortable: true, value: 'time'},
					{text: '팀', sortable: true, value: 'team'},
					{text: '파트', sortable: true, value: 'part'},
					{text: '내역', sortable: true, value: 'name'},
				],
				partNames: {
					plan: '기획',
					buy: '구매',
					make: '제작',
				}
			}
		},
		mounted() {
			_.range(1, this.training.team_count + 1).forEach(v => {
				this.teams.push(v + '팀')
			})

			this.getTimelines()
		},
		methods: {
			async getTimelines() {
				this.isLoading = true

				const team = this.team === '전체' ? '' : this.team
				const response = await this.$get('/admin/timeline', { id: this.training.id, team: team})

				this.timelines = response.data
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>