import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import axios from 'axios'
import VueAxios from "vue-axios";

import VueCookie from 'vue-cookie'
import imagePreloader from 'vue-image-preloader'
import VueZoomer from 'vue-zoomer'

import VueMoment from 'vue-moment'
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from "vue-loaders"

// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

import GameData from "./plugins/GameData";
import GameManager from "./plugins/GameManager";

import Image from "@/components/modules/Image";
import PinchZoom from "vue-pinch-zoom";

// import './registerServiceWorker'


import './assets/css/fonts.css'

global.jQuery = require('jquery');
const $ = global.jQuery;
window.jQuery = $;
window.$ = $;

window._ = require('lodash');
window.moment = require('moment')


Vue.use(VueAxios, axios)

Vue.use(VueCookie)
Vue.use(imagePreloader)
Vue.use(VueMoment)
Vue.use(VueLoaders)

Vue.use(VueZoomer)

Vue.use(GameData)
Vue.mixin(GameManager)

Vue.component('v-image', Image)
Vue.component("pinch-zoom", PinchZoom);


Vue.config.productionTip = false

/*
Sentry.init({
	Vue,
	dsn: "https://f3769d6ba5464c109363a4d0f2168aa2@o4504848421552128.ingest.sentry.io/4504848603086848",
	environment: process.env.NODE_ENV,
	logErrors: true,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,

	trackComponents: true,

	integrations: [
		new Sentry.Replay({
			// Additional SDK configuration goes in here, for example:
			maskAllText: false,
			blockAllMedia: false,
		}),
		new BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			tracePropagationTargets: [],
		}),
	],
	tracesSampleRate: 1.0,
});
 */

window.vm = new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
