<template lang="pug">
	v-layout.container-wrap(
		justify-center
		align-center
		:class="capture ? 'hide' : ''"
	)
		div
			#status.status-wrap(v-if="!!training")
				.training {{ training.name }}
				.timer
					v-layout.time(justify-center align-center) {{ timeFormat }}
				div(v-if="!capture")
					.call-title CALL 진행 중
					.call-list
						div(v-for="row in calls")
							img.team(
								v-for="(call, index) in row"
								:key="`call-${call.id}`"
								:src="`//legorace.s3.ap-northeast-2.amazonaws.com/images/icon_team${call.team}.png`"
							)

				v-carousel.team-slider(
					ref="carousel"
					v-if="!capture"
					cycle
					interval="3000"
					:continuous="true"
					height="330"
					hide-delimiters
					:show-arrows="false"
				)
					v-carousel-item(
						v-for="(n, i) in pageCount"
						:key="i"
					)
						v-sheet(height="100%")
							v-layout.fill-height(justify-center align-center)
								table.team-table(cellspacing="1")
									thead
										tr.head
											th 프로젝트 난이도
											th(
												style="width: 107px;"
												v-for="t in 10"
												:key="`team-${t}`"
											) {{ t + (n - 1) * 10 <= training.team_count ? `${t + (n - 1) * 10}팀` : '' }}
									tbody
										tr
											td 상
											td(
												v-for="t in 10"
												:key="`high-${t}`"
											) {{ t + (n - 1) * 10 <= training.team_count ? (statusData.high[t + (n - 1) * 10] || 0) : '' }}
										tr
											td 중
											td(
												v-for="t in 10"
												:key="`medium-${t}`"
											) {{ t + (n - 1) * 10 <= training.team_count ? (statusData.medium[t + (n - 1) * 10] || 0) : '' }}
										tr
											td 하
											td(
												v-for="t in 10"
												:key="`low-${t}`"
											) {{ t + (n - 1) * 10 <= training.team_count ? (statusData.low[t + (n - 1) * 10] || 0) : '' }}
										tr.call
											td CALL
											td(
												v-for="t in 10"
												:key="`call-${t}`"
											) {{ t + (n - 1) * 10 <= training.team_count ? (statusData.call[t + (n - 1) * 10] || 0) : '' }}
										tr.bottom
											td 완료 프로젝트
											td(
												v-for="t in 10"
												:key="`completed-${t}`"
											) {{ t + (n - 1) * 10 <= training.team_count ? '?' : '' }}

				.team-slider.mt-16(v-else)
					div(
						v-for="(n, i) in pageCount"
						:key="i"
					)
						v-sheet(height="100%")
							v-layout.fill-height(justify-center align-center)
								table.team-table(cellspacing="1")
									thead
										tr.head
											th 프로젝트 난이도
											th(
												style="width: 107px;"
												v-for="t in 10"
												:key="`team-${t}`"
											) {{ t + (n - 1) * 10 <= training.team_count ? `${t + (n - 1) * 10}팀` : '' }}
									tbody
										tr
											td 상
											td(
												v-for="t in 10"
												:key="`high-${t}`"
											) {{ t + (n - 1) * 10 <= training.team_count ? (statusData.high[t + (n - 1) * 10] || 0) : '' }}
										tr
											td 중
											td(
												v-for="t in 10"
												:key="`medium-${t}`"
											) {{ t + (n - 1) * 10 <= training.team_count ? (statusData.medium[t + (n - 1) * 10] || 0) : '' }}
										tr
											td 하
											td(
												v-for="t in 10"
												:key="`low-${t}`"
											) {{ t + (n - 1) * 10 <= training.team_count ? (statusData.low[t + (n - 1) * 10] || 0) : '' }}
										tr.call
											td CALL
											td(
												v-for="t in 10"
												:key="`call-${t}`"
											) {{ t + (n - 1) * 10 <= training.team_count ? (statusData.call[t + (n - 1) * 10] || 0) : '' }}
										tr.bottom
											td 완료 프로젝트
											td(
												v-for="t in 10"
												:key="`completed-${t}`"
											) {{ t + (n - 1) * 10 <= training.team_count ? '?' : '' }}
</template>

<script>
	import domtoimage from 'dom-to-image'
	import {Howl, Howler} from 'howler';

	export default {
		data() {
			return {
				capture: false,
				calls: [[],[]],
				statusData: {
					low: [],
					medium: [],
					high: [],
					call: [],
				},
				timer: null,
				time: null,
				sound: null,
			}
		},
		computed: {
			pageCount() {
				if (!this.training.id) return 0
				return Math.ceil(this.training.team_count / 10)
			},
			timeFormat() {
				if (!this.time) return '-'
				const time = moment.utc(this.time*1000)
				if (time.unix() > 3600) return time.format('HH:mm:ss')
				return time.format('mm:ss')
			}
		},
		watch: {
			mqttData(val) {
				if (val) {
					if (val.action === 'call') {
						this.setCall(val)
						this.getStatus()
					} else if (val.action === 'call_end') {
						this.removeCall(val)
						this.getStatus()
					} else if (val.action === 'direction') {
						if (val.plan_status === 2) {
							this.getStatus()
						}
					}
					if (val.action === 'round') {
						this.startTimer(val.round)
					} else if (val.action === 'round_ended') {
						this.stopTimer()
					}
				}
			}
		},
		async mounted() {
			if (this.$route.query.capture) {
				this.capture = true
			}
			const token = this.$cookie.get('admin_token')
			if (!token) {
				this.moveToLogin()
				return
			}

			const response = await this.$post('/check', { token: token})
			if (response.code !== '0000' || (response.data.type !== 'superadmin' && response.data.type !== 'admin')) {
				this.moveToLogin()
				return
			}
			const admin = response.data
			this.$adminData.admin = admin

			if (this.$cookie.get('training_id') && !this.$adminData.training.id) {
				const response = await this.$get('/admin/training', {id : this.$cookie.get('training_id')})
				if (response.code === '0000') {
					this.$adminData.training = response.data
				}
			}
			const training = this.$adminData.training
			if (!training.id) return ''

			if (this.$cookie.get('training_id') && !this.$adminData.training.id) {
				const response = await this.$get('/admin/training', {id : this.$cookie.get('training_id')})
				if (response.code === '0000') {
					this.$adminData.training = response.data
				}
			}

			this.$setMqttClient()
			await this.getStatus()

			const now = moment().format('YYYY-MM-DD HH:mm:ss')

			const start1 = `${training.date} ${training.start_time1}`
			const end1 = `${training.date} ${training.end_time1}`
			const start2 = `${training.date} ${training.start_time2}`
			const end2 = `${training.date} ${training.end_time2}`


			if (now >= start1 && now <= end1)  {
				this.startTimer(1)
			} else if (now >= start2 && now <= end2)  {
				this.startTimer(2)
			}

			if (!this.capture) {
				$(window).click(_ => {
					const element = document.body
					if (element.requestFullscreen) return element.requestFullscreen()
					if (element.webkitRequestFullscreen) return element.webkitRequestFullscreen()
					if (element.mozRequestFullScreen) return element.mozRequestFullScreen()
					if (element.msRequestFullscreen) return element.msRequestFullscreen()
				})

				this.sound = new Howl({
					src: 'https://legorace.s3.ap-northeast-2.amazonaws.com/sounds/CALL.mp3',
					autoplay: false,
					loop: false,
				})
			}

		},

		methods: {
			moveToLogin() {
				this.$router.replace('/login')
			},

			arrangeTeams(calls) {
				this.calls = [[],[]]
				calls.forEach((call, i) => {
					if (i >= 14) return

					let index = i < 7 ? 0 : 1
					this.calls[index].push(call)
				})
			},

			async getStatus() {
				const response = await this.$get('/admin/status', {id: this.training.id})
				const data = response.data
				const calls = []
				const statusData = {
					low: [],
					medium: [],
					high: [],
					call: [],
				}
				data.calls.forEach(item=> {
					if (!calls.find(v => v.team === item.team)) {
						calls.push(item)
					}
				})
				this.arrangeTeams(calls)

				data.callCounts.forEach(item => {
					statusData.call[item.team] = item.callCount
				})
				data.projectCounts.forEach(item => {
					statusData[item.type][item.team] = item.projectCount
				})

				this.statusData = statusData

				if (this.capture) {
					await new Promise(resolve => setTimeout(_ => { resolve() }, 1000))
					await this.downloadImage()
				}


			},
			setCall(call) {
				let exists = -1
				const calls = [
					...this.calls[0],
					...this.calls[1]
				]

				calls.forEach((v, index) => {
					if (v.team === call.team) exists = index
				})

				if (exists > -1) calls.splice(exists, 1)
				calls.splice(0, 0, call)

				this.arrangeTeams(calls)

				if (this.sound) {
					this.sound.play()
				}
			},
			removeCall(call) {
				let exists = -1
				const calls = [
					...this.calls[0],
					...this.calls[1]
				]

				calls.forEach((v, index) => {
					if (v.team === call.team) exists = index
				})

				if (exists > -1) calls.splice(exists, 1)

				this.arrangeTeams(calls)
			},
			startTimer(round) {
				this.timer = setInterval(_ => {
					const endTime = `${this.training.date} ${this.training[`end_time${round}`]}`
					const time = Math.ceil(moment(endTime).diff(moment()) / 1000)
					this.time = time

					if (this.time <= 0) {
						this.stopTimer()
					}
				}, 1000)
			},
			stopTimer() {
				if (this.timer) {
					clearInterval(this.timer)
					this.$set(this.$adminData.training, 'round', null)
					this.timer = null
					this.time = null
				}
			},
			async downloadImage() {
				this.$store.commit('showLoader')
				const dataUrl = await domtoimage.toJpeg(document.getElementById('status'), { quality: 0.95, cacheBust: true })

				const link = document.createElement('a');
				link.download = `${this.training.name}과정현황_${moment().format('YYYY-MM-DD')}.jpeg`;
				link.href = dataUrl;
				link.click();
				this.$store.commit('hideLoader')

				window.close()
			},
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrap {
		width: 100%;
		height: 100%;
		background-color: #fff;

		&.hide {
			width: 1px;
			height: 1px;
			transform: translateX(-2000px);
		}

		.status-wrap {
			flex-grow: 0;
			position: relative;
			width: 1367px;
			padding: 41px 49px 34px;
			background-color: #fff;

			.training {
				position: absolute;
				left: 49px;
				top: 41px;
				font-size: 25px;
				color: #000;
			}

			.timer {
				position: absolute;
				right: 49px;
				top: 41px;
				width: 254px;
				height: 50px;
				background-image: url(//legorace.s3.ap-northeast-2.amazonaws.com/images/status_time.png);

				font-size: 20px;
				font-weight: bold;

				text-align: right;
				line-height: 1.2;
				padding-top: 14px;
				padding-right: 29px;
				.time {
					position: absolute;
					right: 1px;
					top: 11px;
					height: 31px;
					width: 101px;
				}
			}

			.call-title {
				position: absolute;
				left: 49px;
				top: 100px;
				font-size: 30px;
			}
			.call-list {
				position: absolute;
				left: 0;
				top: 145px;
				padding-left: 49px;
				height: 235px;
				overflow: hidden;

				.team {
					margin-right: 34px;
					margin-bottom: 6px;
				}
			}

			.team-slider {
				margin-top: 364px;

				.team-table {
					width: 100%;
					background-color: #E5E5E5;

					tr {
						height: 55px;
						font-size: 20px;

						th, td {
							background-color: #fff;
							text-align: center;
							vertical-align: center;
							color: #C10D0D;
						}
						th {
							font-weight: normal;
							background-color: #C10D0D;
							color: #fff;
						}

						&.call {
							td {
								background-color: #FFD9D9;
							}
						}

						&.bottom {
							td {
								background-color: #505050;
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
</style>