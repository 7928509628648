<template lang="pug">
	transition(name="fade")
		v-layout.toast-wrap(
			v-if="!!toast"
			justify-center
		)
			v-image(
				:src="`toast_${toast}.png`"
			)
</template>

<script>
	export default {

	    data() {
	        return {
	            st: null,
	        }
	    },

	    computed: {
	        toast() {
	            return this.$gameData.toast
	        }
	    },
		watch: {
	        toast(val) {
	            if (val) {
	                if (this.st) clearTimeout(this.st)

	                this.st = setTimeout(_ => {
                        this.$gameData.toast = null
                    }, 2000)
	            }
	        }
		}

    }
</script>

<style lang="scss" scoped>
	.toast-wrap {
		position: fixed;
		left: 0;
		width: 100%;
		bottom: 115px;
		z-index: 5;
	}
</style>