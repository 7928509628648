<template lang="pug">
	div.tab-wrap
		v-layout.tab-container.fill-height(
			column
			align-center
		)
			.title-wrap
				v-image.progress(
					:src="`buy_progress${progress}.png`"
				)
				.buy-title 필요한 레고를 구매해주세요 (
					span.count {{ totalCount }}
					span /10)
			.buy-lego-wrap
				.lego-item-wrap
					.lego-items.no-scrollbar
						div(
							v-for="item in legoItems"
							:key="item.lego_name"
						)
							.lego(
								:class="item.priceChanged ? 'changed' : ''"
							)
								v-image.lego-img(:src="`icon_lego_${item.name}.png`")

								.price-wrap
									span.price {{ item.price }}
									span.unit p

								.owned 현재 보유 개수 : {{ (inventories.find(v => v.name === item.name) || { amount : 0 }).amount }}

								v-layout.amount-wrap
									v-image(
										v-if="item.amount === 0"
										src="btn_minus_disabled.png"
									)
									v-image.btn-lego-amount(
										v-else
										src="btn_minus.png"
										@click="setLegoAmount(item, -1)"
									)
									v-layout.amount(align-center justify-center) {{ item.amount }}
									v-image.btn-lego-amount(src="btn_plus.png"
										@click="setLegoAmount(item, 1)"
									)
			v-layout.btn-buy(
				justify-center
				:class="!canBuy ? 'disabled' : ''"
			)
				span
					v-image(
						v-if="canBuy"
						src="btn_buy.png"
						@click="confirmBuy"
					)
					v-image(src="btn_buy_disabled.png"
						v-else
					)
					div.price(
					) {{ totalPrice }}

</template>

<script>

	export default {
        data() {
            return {
            	progress: 1,
	            legoItems: [],
            }
        },

	    computed: {
		    mqttData() {
			    return this.$gameData.mqttData
		    },
		    totalPrice() {
			    let totalPrice = 0
			    const impressionBase = this.setting.lego_impression_base
			    const impressionMin = this.setting.lego_impression_min
			    const impressionMax = this.setting.lego_impression_max
			    const impressionPrice = this.setting.lego_impression_price

			    this.legoItems.forEach(lego => {
			    	let multiply = Math.floor(lego.sell_count / impressionBase)
					multiply = multiply % (impressionMax / impressionBase) + 1

			    	let price = multiply * impressionPrice

				    if (lego.amount > 0) {
				    	if (lego.sell_count % impressionBase + lego.amount > impressionBase) {
						    const beforeAmount = impressionBase - (lego.sell_count % impressionBase)
						    const afterAmount = lego.amount - beforeAmount

							multiply = multiply % (impressionMax / impressionBase) + 1

						    let newPrice = multiply * impressionPrice

						    totalPrice += price * beforeAmount + newPrice * afterAmount
						    lego.price = newPrice
					    } else {
						    totalPrice += price * lego.amount
						    lego.price = price
					    }
				    }
			    })

			    return totalPrice
		    },
		    totalCount() {
			    return this.legoItems.reduce((calc, curr) => calc += curr.amount, 0)
		    },
		    canBuy() {
			    let canBuy = true
			    if (this.totalPrice === 0) canBuy = false

			    return canBuy
		    },

	    },
	    watch: {
		    mqttData(val) {
			    if (val && val.action === 'update_legos') {
			    	val.legos.forEach(lego => {
			    		const item = this.legoItems.find(v => v.name === lego.name)
					    item.sell_count = lego.sell_count
					    if (lego.price) {
					    	item.price = lego.price
						    item.priceChanged = true
					    }
				    })
			    }
		    }
	    },

		beforeDestroy() {
        	$(window).off('resize', this.onResize)
		},

		async mounted() {
			$(window).on('resize', this.onResize)
			await this.$wait(0)
			this.onResize()
			await this.getLegoItems()
	    },

	    methods: {
        	onResize() {
        		// const top = $('.lego-items').offset().top
		        // const height = document.documentElement.offsetHeight - 90 - top
		        // $('.lego-items').height(`calc(100% - 90px - ${top}px)`)
	        },
        	async getLegoItems() {
		        const response = await this.$get('/legos')
		        const legoItems = []
		        response.data.forEach(lego => {
		        	lego.amount = 0
			        legoItems.push(lego)
		        })

		        this.legoItems = legoItems
	        },

		    setLegoAmount(item, amount) {
        		if (this.totalCount === 10 && amount > 0) return

			    item.amount += amount
		    },

		    confirmBuy() {
			    if (this.$userData.user.capital < this.totalPrice) {
				    this.$showToast('point_not_enough')
				    return
			    }
        		this.$showDialog({
			        type: 'buy1',
			        buttons: [
				        { type: 'cancel' },
				        { type: 'buy', click: this.buy },
			        ]
		        })
		    },
		    async buy() {
			    if (this.$userData.user.capital < this.totalPrice) {
				    this.$showToast('point_not_enough')
				    return
			    }

			    const legos = this.legoItems.filter(v => v.amount > 0).map(v => {
				    return { name: v.name, amount: v.amount }
			    })

			    this.$userData.user.capital -= this.totalPrice
			    const response = await this.$post('/inventory', { inventories: legos, price: this.totalPrice })
			    const result = response.data

			    this.legoItems.forEach(lego => {
			    	const item = result.find(v => v.name === lego.name)
				    if (item) {
				    	lego.sell_count = item.sell_count
					    if (item.price) lego.price = item.price
				    }
			    	lego.amount = 0
			    })

			    this.$publishMqtt('training', 'update_legos', {legos: result})
			    this.$showToast('buy_succeed')

		    }

        }
    }
</script>

<style lang="scss" scoped>
	.tab-wrap {
		height: 100%;
		padding-bottom: 150px;
		.tab-container {
		}

		.progress {
			margin-top: 30px;
		}

		.title-wrap {
			.buy-title {
				margin-top: 40px;
				font-size: 22px;
				color: #5D5D5D;
			}
		}

		.buy-lego-wrap {
			width: 512px;
			height: calc(100% - 290px);

			.lego-item-wrap {
				height: 100%;

				.lego-items {
					overflow-x: hidden;
					overflow-y: scroll;
					height: 100%;
					.lego {
						position: relative;
						background-color: #fff;
						margin: 10px 0;
						padding: 20px 26px;
						border-radius: 15px;
						border: 1px solid #D1D1D1;
						height: 140px;

						&.changed {
							animation: blink 0.5s alternate-reverse 10;
						}

						.lego-img {
							height: 50px;
						}

						.owned {
							margin-top: 14px;
						}

						.price-wrap {
							position: absolute;
							right: 20px;
							top: 12px;
							color: #C10D0D;

							.price {
								font-size: 40px;
								font-weight: bold;
							}
							.unit {
								font-size: 32px;
								margin-left: 10px;
							}
						}

						.amount-wrap {
							position: absolute;
							right: 25px;
							bottom: 15px;

							.btn-lego-amount {
								cursor: pointer;
							}

							.amount {
								width: 135px;
								height: 45px;
								background-color: #F0F0F0;
								font-size: 28px;
								padding-top: 4px;
							}
						}
					}
				}
			}
		}
		.btn-buy {
			position: fixed;
			bottom: 115px;
			left: 0;
			width: 100%;

			cursor: pointer;

			span {
				position: relative;
			}

			.price {
				position: absolute;
				right: 233px;
				top: 35px;
				color: #C10D0D;
				font-size: 40px;
				font-weight: 600;
				line-height: 1;

				pointer-events: none;
			}

			&.disabled {
				cursor: default;

				.price {
					color: #ACACAC;
				}
			}
		}

		@keyframes blink {
			0% { border-color: #D1D1D1; }
			100% { border-color: #C10D12; }

		}


	}
</style>