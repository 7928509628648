<template lang="pug">
	.layout-wrap(v-if="initialized")
		.main-wrap
			app-bar

			.content-wrap
				router-view


		v-dialog(
			v-show="showDialog"
			v-model="showDialog"
			:persistent="dialogType === 'confirm'"
			absolute
			max-width="450px"
		)
			v-card
				v-card-title
					.headline
						<v-icon v-if="dialogIcon">{{dialogIcon}}</v-icon> {{ dialogTitle }}
				v-card-text(v-html="dialogMessage")
				v-card-actions(v-if="dialogType === 'confirm' || dialogType === 'alert'")
					v-spacer
					div
						v-btn(color="secondary" @click.native="dialogOk") 확인
						v-btn(color="" @click.native="dialogCancel" v-show="dialogType === 'confirm'") 취소
		v-snackbar(
			:timeout="snackbarDuration"
			:color="snackbarColor"
			bottom
			v-model="showSnackbar"
		) {{ snackbarMessage }}
		.loader(v-if="showLoader")
			vue-loaders(name="ball-pulse-sync")
</template>

<script>
	import AppBar from "@/components/admin/modules/AppBar";

	export default {
		components: {
			AppBar,
		},
		data() {
			return {
				initialized: false,
			}
		},

		computed: {
			showSnackbar: {
				get() {
					return this.$store.getters.showSnackbar;
				},
				set(val) {
					if (!val) this.$store.commit('hideSnackbar');
				}
			},
			snackbarMessage() {
				return this.$store.getters.snackbarMessage;
			},
			snackbarColor() {
				return this.$store.getters.snackbarColor;
			},
			snackbarDuration() {
				return this.$store.getters.snackbarDuration;
			},
			showLoader() {
				return this.$store.getters.showLoader
			},

			// dialog
			showDialog: {
				get() {
					return this.$store.getters.showDialog;
				},
				set(val) {
					if (!val) this.$store.commit('hideDialog');
				}
			},
			dialogType() {
				return this.$store.getters.dialogType
			},
			dialogTitle() {
				return this.$store.getters.dialogTitle
			},
			dialogMessage() {
				return this.$store.getters.dialogMessage.replace(/\n/g, '<br />')
			},
			dialogIcon() {
				return this.$store.getters.dialogIcon
			},
		},

		beforeCreate() {
		},

		async mounted() {
			let token
			if (this.$route.path.startsWith('/manage')) {
				token = this.$cookie.get('tpc_token')
			} else if (this.$route.path.startsWith('/admin')) {
				token = this.$cookie.get('admin_token')
			}
			if (!token) {
				this.moveToLogin()
				return
			}

			$('html, body').css('user-select', 'auto')

			const response = await this.$post('/check', { token: token})
			if (response.code !== '0000' || (response.data.type !== 'superadmin' && response.data.type !== 'admin')) {
				this.moveToLogin()
				return
			}
			const admin = response.data
			this.$adminData.admin = admin

			if (this.$route.path === '/manage') {
				this.$router.replace('/manage/users')
			} else if (this.$route.path === '/admin') {
				this.$router.replace('/admin/trainings')
			}

			if (this.$cookie.get('training_id') && !this.$adminData.training.id) {
				const response = await this.$get('/admin/training', {id : this.$cookie.get('training_id')})
				if (response.code === '0000') {
					this.$adminData.training = response.data
				}
			}

			this.$setMqttClient()

			this.initialized = true
		},

		methods: {
			moveToLogin() {
				this.$router.replace('/login')
			},


			dialogOk() {
				this.$store.commit('dialogOk');
			},
			dialogCancel() {
				this.$store.commit('dialogCancel');
			},
		}
	}
</script>

<style lang="scss" scoped>
	.layout-wrap {
		width: 100vw;
		height: 100vh;
		overflow-y: scroll;

		* {
			user-select: auto !important;
		}

		.main-wrap{
			position: relative;
			width: 1280px;
			min-height: 100vh;
			margin: 0 auto;
			background-color: #fff;

			.content-wrap {
				/*padding-top: 150px;*/
			}
		}
	}
	.loader { position: fixed; left: 0; top: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; background: rgba(0,0,0,.3); z-index: 210;}
</style>

<style lang="scss">
	tr:nth-of-type(odd) td { background-color: rgba(0, 0, 0, .02); }
</style>