<template lang="pug">

	.monitoring-wrap
		v-card.mt-5
			v-card-text
				v-data-table(
					:headers="headers"
					:items="trainings"
					:items-per-page="itemsPerPage"
					:page.sync="page"
					:loading="isLoading"
					loading-text="데이터 조회 중.. 잠시만 기다리세요."
					hide-default-footer
					@page-count="pageCount = $event"
				)
					template(
						v-slot:item.name="{ item }"
					)
						a(@click="selectTraining(item)") {{ item.name }}
					template(
						v-slot:item.date="{ item }"
					) {{ item.date }} {{ `${item.date} ${item.start_time1}` | moment('HH:mm') }} ~ {{ `${item.date} ${item.end_time2}` | moment('HH:mm') }}
					template(
						v-slot:item.status="{ item }"
					)
						span(v-if="item.status === '예약'" style="color: #000") {{ item.status }}
						span(v-if="item.status === '진행'" style="color: red") {{ item.status }}
						span(v-if="item.status === '종료'" style="color: darkgrey") {{ item.status }}
				v-divider
				.text-center.pt-2.mt-5
				v-pagination(
					v-model="page"
					:length="pageCount"
				)
</template>

<script>
	export default {

		data() {
			return {
				page: 1,
				pageCount: 0,
				itemsPerPage: 20,
				headers: [
					{text: 'No.', sortable: true, value: 'id'},
					{text: 'Client명', sortable: true, value: 'user.name'},
					{text: '교육명', sortable: true, value: 'name'},
					{text: '일시', sortable: true, value: 'date'},
					{text: '팀 수', sortable: true, value: 'team_count'},
					{text: '현황', sortable: true, value: 'status'},
					{text: '', sortable: false, value: 'actions', align: 'center'},
				],
				trainings: [],

				item: {},

				isLoading: false,
				showDialog: false,
			}
		},
		mounted() {
			this.$adminData.tabIndex = 1
			this.getList()
		},
		methods: {
			async getList() {
				this.isLoading = true
				this.trainings = []

				const response = await this.$get('/admin/monitoring')
				if (response.code === '0000') {
					const trainings = []
					const now = moment().format('YYYY-MM-DD HH:mm:ss')
					response.data.forEach(training => {
						const start1 = `${training.date} ${training.start_time1}`
						const end2 = `${training.date} ${training.end_time2}`

						if (now < start1) {
							training.status = '예약'
						} else if (now >= start1 && now <= end2)  {
							training.status = '진행'
						} else if (now > end2)  {
							training.status = '종료'
						}
						trainings.push(training)
					})

					this.trainings = trainings
				}
				this.isLoading = false
			},

			selectTraining(item) {
				this.$cookie.set('admin_token', item.user.token)
				this.$cookie.set('training_id', item.id)
				window.open('/admin/result')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.monitoring-wrap {
		margin-top: 20px;
		padding: 10px;
	}
</style>