<template lang="pug">
	.module-wrap.pa-5
		v-card
			v-card-subtitle 보유 현황
			v-card-text
				v-card(style="width: 70%; margin: 0 auto")
					v-card-text
						v-simple-table
							template
								thead
									tr
										th(style="width: 20%") 팀
										th(style="width: 20%") 기획파트
										th(style="width: 20%") 구매파트
										th(style="width: 20%") 제작파트
										th(style="width: 20%") 총계
								tbody
									tr(
										v-for="(team, index) in points"
										:key="index"
									)
										td {{ team.team }}팀
										td {{ team.capital_plan.toLocaleString() }}p
										td {{ team.capital_buy.toLocaleString() }}p
										td {{ team.capital_make.toLocaleString() }}p
										td {{ team.total.toLocaleString() }}p
									tr.text-center(
										v-if="points.length === 0"
									)
										td(colspan="5") 데이터가 없습니다
</template>

<script>
	export default {
		data() {
			return {
				points: [],
			}
		},
		mounted() {
			this.getStatus()
		},
		methods: {
			async getStatus() {
				const response = await this.$get('/admin/point/status', { id: this.training.id})

				this.points = response.data.map(v => {
					v.total = v.capital_plan + v.capital_buy + v.capital_make

					return v
				})

				this.$emit('change')
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>