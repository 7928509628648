<template lang="pug">
	.module-wrap.pa-5
		v-card
			v-card-subtitle 대출/상환
			v-card-text
				v-layout(column justify-center align-center)
					v-form(ref="form")
						v-layout(align-center)
							v-layout(align-center)
								span
									v-select.ml-2(
										label="파트"
										dense
										outlined
										style="width: 100px;"
										:items="parts"
										v-model="item.part"
										:rules="[v => !!v || '필수항목']"
									)
							v-layout.ml-5(align-center)
								span
									v-select.ml-2(
										label="팀"
										dense
										outlined
										style="width: 100px;"
										:items="teams"
										v-model="item.team"
										:rules="[v => !!v || '필수항목']"
									)
							v-layout.ml-10(align-center)
								span
									v-text-field.ml-2(
										label="포인트"
										type="number"
										dense
										outlined
										min="0"
										style="width: 150px;"
										v-model="item.point"
										append-icon="mdi-alpha-p"
										:rules="[v => !!v || '필수항목']"
									)
							v-layout.ml-10(align-center)
								span.mb-6
									v-btn(color="secondary" dark @click="setInterest(1)") 대출
									v-btn.ml-2(color="#EF5350" dark @click="setInterest(-1)") 대출 취소
				v-card(style="width: 70%; margin: 0 auto")
					v-card-text
						v-simple-table
							template
								thead
									tr
										th(style="width: 20%") 팀
										th(style="width: 20%") 기획파트
										th(style="width: 20%") 구매파트
										th(style="width: 20%") 제작파트
								tbody
									tr(
										v-for="(interest, index) in interests"
										:key="index"
									)
										td {{ interest.team }}팀

										td(v-if="interest.plan")
											div {{ interest.plan }}
												v-btn.ml-2(
													color="secondary" small
												:disabled="interest.plan * 1.5 > interest.capital_plan"
													@click="repay(interest, 'plan')"
												) 상환
										td(v-else) 대출 없음

										td(v-if="interest.buy")
											div {{ interest.buy }}
												v-btn.ml-2(
													color="secondary" small
												:disabled="interest.buy * 1.5 > interest.capital_buy"
													@click="repay(interest, 'buy')"
												) 상환
										td(v-else) 대출 없음

										td(v-if="interest.make")
											div {{ interest.make }}
												v-btn.ml-2(
													color="secondary" small
												:disabled="interest.make * 1.5 > interest.capital_make"
													@click="repay(interest, 'make')"
												) 상환
										td(v-else) 대출 없음
									tr.text-center(
										v-if="interests.length === 0"
									)
										td(colspan="5") 데이터가 없습니다
</template>

<script>
	export default {
		data() {
			return {
				item: {},
				parts: [
					{ text: '기획', value: 'plan' },
					{ text: '구매', value: 'buy' },
					{ text: '제작', value: 'make' },
				],
				teams: [],
				interests: [],
			}
		},
		mounted() {
			_.range(1, this.training.team_count + 1).forEach(v => {
				this.teams.push({
					text: v + '팀',
					value: v,
				})
			})

			this.getInterests()

		},
		methods: {
			async getInterests() {

				const response = await this.$get('/admin/point/interests', { id: this.training.id })

				this.interests = response.data
				this.$emit('change')
			},

			async setInterest(sign) {
				const item = _.cloneDeep(this.item)
				item.point *= sign
				item.interest = item.point

				const response = await this.$post('/admin/point/interest', {
					id: this.training.id,
					type: sign > 0 ? '대출' : '대출 취소',
					...item
				})
				if (response.code !== '0000') {
					this.$store.commit('showDialog', {
						type: 'alert',
						message: response.message
					})
					return
				}

				this.$publish({
					type: 'part',
					trainingId: this.training.id,
					team: item.team,
					part: item.part,
					action: 'reload',
				})

				this.item = {}
				this.$refs.form.reset()

				await this.getInterests()
			},

			async repay(interest, part) {
				const item = {
					id: this.training.id,
					part: part,
					team: interest.team,
					interest: interest[part] * -1,
					point: interest[part] * -1.5,
					type: '대출 상환',
				}
				await this.$post('/admin/point/interest', item)

				this.$publish({
					type: 'part',
					trainingId: this.training.id,
					team: item.team,
					part: item.part,
					action: 'reload',
				})

				await this.getInterests()
			}
		}
	}
</script>

<style lang="scss" scoped>


	input, select {
		border: 1px solid #000;
		padding: 5px 0 5px 10px;
	}
</style>