import Vue from 'vue';
import mqtt from "mqtt";
import * as Sentry from "@sentry/browser";


let clientPrefix

export default {
	data() {
		return {
		}
	},

	computed: {
		client: {
			get() { return this.$gameData.client },
			set(client) { this.$gameData.client = client }
		},
		user: {
			get() { return this.$userData.user },
			set(user) { this.$userData.user = user }
		},
		setting: {
			get() { return this.$userData.setting },
			set(setting) { this.$userData.setting = setting }
		},
		deliveries: {
			get() { return this.$userData.deliveries },
			set(deliveries) { this.$userData.deliveries = deliveries }
		},
		trainingData: {
			get() { return this.$userData.data},
			set(trainingData) { this.$userData.data = trainingData }
		},
		teamData: {
			get() { return this.$userData.teamData },
			set(teamData) { this.$userData.teamData = teamData }
		},
		stocks: {
			get() { return this.$userData.stocks },
			set(stocks) { this.$userData.stocks = stocks }
		},
		inventories: {
			get() { return this.$userData.inventories },
			set(inventories) { this.$userData.inventories = inventories }
		},

		training: {
			get() { return this.$adminData.training },
			set(training) { this.$adminData.training = training }
		},
		mqttData() {
			return this.$gameData.mqttData
		},
		round() {

			const now = moment().format('YYYY-MM-DD HH:mm:ss')
			const training = this.$adminData.training
			if (!training.id) return 0

			const start1 = `${training.date} ${training.start_time1}`
			const end1 = `${training.date} ${training.end_time1}`
			const start2 = `${training.date} ${training.start_time2}`
			const end2 = `${training.date} ${training.end_time2}`

			if (now < start1) {
				return -1
			} else if (now >= start1 && now <= end1)  {
				return 1
			} else if (now < start2)  {
				return 1.5
			} else if (now >= start2 && now <= end2)  {
				return 2
			}

			return 3

		},
		gameStatus() {

			let status = ''

			const round = this.round
			if (!round) return ''

			if (round === -1) {
				status = '예약'
			} else if (round === 1) {
				status = '1라운드 진행 중'
			} else if (round === 1.5) {
				status = '2라운드 준비'
			} else if (round === 2) {
				status = '2라운드 진행 중'
			} else if (round === 3) {
				status = '교육 종료'
			}

			return status
		},
	},

	watch: {
	},

	methods: {

		/**
		 * CALL API
		 */
		async $send(method, path, data) {

			// let result
			// path = process.env.VUE_APP_API_URL + path
			// const res = await Vue.axios[method.toLowerCase()](path, data)
			// result = res.data
			//
			// return result


			try {
				let token
				if (this.$route.path.startsWith('/manage')) {
					token = this.$cookie.get('tpc_token')
				} else if (this.$route.path.startsWith('/admin') || this.$route.path === '/status') {
					token = this.$cookie.get('admin_token')
				} else {
					token = this.$cookie.get('token')
				}

				// const token = localStorage.getItem('token')

				data = {...data, token: token}

				if (method === 'get') {
					Object.keys(data).forEach(k => {
						if (path.indexOf('?') > -1) {
							path += `&${k}=` + data[k]
						} else {
							path += `?${k}=` + data[k]
						}
					})
				}

				let result
				path = process.env.VUE_APP_API_URL + path
				const res = await Vue.axios[method.toLowerCase()](path, data)
				if (res.data.code === '9001') {
					this.$cookie.delete('token')
					location.replace('/login')
					return
				}
				result = res.data

				if (method === 'get' && !result.data) {
					Sentry.captureMessage(JSON.stringify(result));
				}

				return result
			} catch (e) {
				Sentry.captureException(e)
				console.error(e)
				return {}
			}

		},
		async $get(path, data) {
			return await this.$send('get', path, data)
		},
		async $post(path, data) {
			if (this.$route.path !== '/login') {
				if (this.$route.path.startsWith('/manage')) {
					if (!this.$cookie.get('tpc_token')) {
						location.replace('/login')
						return
					}
				} else if (this.$route.path.startsWith('/admin') || this.$route.path === '/status') {
					if (!this.$cookie.get('admin_token')) {
						location.replace('/login')
						return
					}
				} else {
					if (!this.$cookie.get('token')) {
						location.replace('/login')
						return
					}
				}
			}
			return await this.$send('post', path, data)
		},

		async $wait(milliseconds) {
			await new Promise(resolve => setTimeout(resolve, milliseconds));
		},

		async $init() {
		},

		nl2br(message) {
			return (message || '').trim().replace(/\n/g, '<br />')
		},

		async $reportError(err) {
			console.error(err)
			// await this.$post('/report/error', {
			// 	id: this.$cookie.get('id'),
			// 	error: err.stack.toString()
			// })
		},

		$setMqttClient() {

			let url
			if (process.env.VUE_APP_MODE === 'development') {
				url = process.env.VUE_APP_MQTT_URL
				clientPrefix = 'dev/'
				// this.clientPrefix = ''
			} else {
				clientPrefix = ''
				url = process.env.VUE_APP_MQTT_URL
			}
			let will = null
			if (this.user.id) {
				will = {
					topic: `${clientPrefix}will/${this.user.training_id}`,
					payload: JSON.stringify({ user_id: this.user.id}),
				}

				let lastFired = new Date().getTime();
				setInterval(function() {
					let now = new Date().getTime();
					if(now - lastFired > 3000) {//if it's been more than 5 seconds
						console.log('wake up')
						location.reload()
					}
					lastFired = now;
				}, 500);
			}
			console.log(url)
			this.client = mqtt.connect(url, {
				protocolId: 'MQTT',
				username: process.env.VUE_APP_MQTT_USER,
				password: process.env.VUE_APP_MQTT_PASS,
				will: will,
			})

			let reconnect


			this.client.on('connect', _ => {
				console.log('connected ' + this.client.connected)
				if (reconnect) {
					location.reload()
				}
				reconnect = true
			})
			this.client.on('error', err => {
				console.error('connect error ' + err)
				alert('error : ' + err.message)
			})
			this.client.on('disconnected', _ => {
				console.error('disconnected')
				alert('disconnected')
			})

			this.client.on('message', async (topic, message, packet) => {
				topic = topic.replace(/^dev\//, '')
				const sep = topic.split('/')

				let data

				console.log(this.$adminData.training.id, sep[1])

				if (this.$adminData.training.id) {
					if (this.$adminData.training.id !== parseInt(sep[1])) {
						return
					}
				}

				switch (sep[0]) {
					case 'training':
						switch (sep[2]) {
							case 'stock':
								const stock = JSON.parse(message)
								Object.keys(stock).forEach(k => {
									this.$set(this.$userData.setting, 'stock_' + k, stock[k])
								})
								break;
							case 'game_start':
							case 'game_reset':
								if (this.training.id && this.training.id !== parseInt(sep[1])) return

								data = JSON.parse(message)
								this.$gameData.mqttData = {
									target: sep[0],
									action: sep[2],
									round: data.round,
									delivery_no: data.delivery_no,
									delivery_start_time1: data.delivery_start_time1,
									delivery_start_time2: data.delivery_start_time2,
								}
								break;
							case 'reload':
								await this.$getUserData()
								break;
							case 'inspection':
							case 'round':
							case 'round_ended':
							case 'round_reset':
								if (this.training.id && this.training.id !== parseInt(sep[1])) return

								data = JSON.parse(message)
								this.$gameData.mqttData = {
									target: sep[0],
									action: sep[2],
									...data
								}
								break;
							case 'ended':
								this.$gameData.mqttData = {
									target: sep[0],
									action: sep[2],
								}
								break;
							case 'update_legos':
								await this.$getUserData()
								this.$gameData.mqttData = {
									target: sep[0],
									action: sep[2],
									...JSON.parse(message),
								}
								break;
						}
						break;
					case 'team':
						switch (sep[3]) {
							case 'inspection':
							case 'direction':
							case 'delivery':
							case 'call':
							case 'call_end':
								this.$gameData.mqttData = {
									target: sep[0],
									action: sep[3],
									...JSON.parse(message),
								}
								break;
							case 'reload':
								await this.$getUserData()
								break;
							case 'game_end':
								this.$gameData.mqttData = {
									target: sep[0],
									action: sep[3],
									...JSON.parse(message),
								}
								break;
						}
						break;
					case 'part':
						switch (sep[4]) {
							case 'reload':
								if (sep[3] === this.user.part) {
									await this.$getUserData()
								}
								break;
							case 'direction':
								await this.$getUserData()
								this.$gameData.mqttData = {
									target: sep[0],
									action: sep[4],
									...JSON.parse(message),
								}
								break;
						}
						break;
				}
			})
			this.client.on('disconnect', msg => {
				console.log('disconnect')
				alert('disconnect')
			})


			if (this.$adminData.admin) {
				if (this.$adminData.admin.type === 'admin') {
					// 과정 전체 (training/과정ID/액션)
					// training/1/action
					this.client.subscribe(clientPrefix + 'training/+/+', { qos: 2 })

					// 과정 전체 (team/과정ID/팀ID/액션)
					// team/1/1/action
					this.client.subscribe(clientPrefix + 'team/+/+/+', { qos: 2 })

					// 과정 전체 (part/과정ID/팀ID/파트/액션)
					// part/1/1/plan/action
					this.client.subscribe(clientPrefix + 'part/+/+/+/+', { qos: 2 })

					console.log('subscribed to admin topics')
				}
			}
			if (this.user.id) {
				this.client.subscribe(`${clientPrefix}training/${this.user.training_id || this.training.id}/+`, {qos: 2})
				this.client.subscribe(`${clientPrefix}team/${this.user.training_id}/${this.user.team}/+`, {qos: 2})
				this.client.subscribe(`${clientPrefix}part/${this.user.training_id}/${this.user.team}/${this.user.part}/+`, {qos: 2})

				console.log('subscribed to user topics')
			}
		},

		$publish({type, trainingId, team, part, action, message, qos = 2}) {
			if (!this.client) {
				setTimeout(_ => {
					this.$publish({trainingId, team, part, message, qos})
				}, 500)
				return
			}

			let topic = `${clientPrefix}${type}/${trainingId}`
			if (team) topic += `/${team}`
			if (part) topic += `/${part}`
			topic += `/${action}`

			if (typeof message === 'object') message = JSON.stringify(message)
			this.client.publish(topic, message, { qos: qos })
		},

		$publishDirection(type, part, direction) {
			const data = {
				id: direction.id,
				type: direction.type,
				order: direction.order,
				part: part,
				team: this.user.team,
				user_id: this.user.id,
				sendType: direction.sendType,
				inspected: direction.inspected,
			}
			data[`${part}_status`] = direction[`${part}_status`]

			this.$publishMqtt(type, 'direction', data)
		},

		$publishMqtt(type, action, message, qos = 2) {
			if (!this.client) {
				setTimeout(_ => {
					this.$publishMqtt(type, action, message, qos)
				}, 500)

				return
			}
			const user = this.$userData.user

			if (action === 'direction') {
				switch (user.part) {
					case 'plan':
						delete message.buy_status
						delete message.buy_opened
						delete message.buy_completed
						delete message.make_status
						delete message.make_opened
						delete message.make_completed
						break;
					case 'buy':
						delete message.plan_status
						delete message.plan_opened
						delete message.plan_completed
						delete message.make_status
						delete message.make_opened
						delete message.make_completed
						break;
					case 'make':
						delete message.plan_status
						delete message.plan_opened
						delete message.plan_completed
						delete message.buy_status
						delete message.buy_opened
						delete message.buy_completed
						break;
				}
			}

			let topic = `${clientPrefix}${type}/`
			if (type === 'training') {
				topic += user.training_id || this.training.id
			} else if (type === 'team') {
				topic += `${user.training_id}/${user.team}`
			} else if (type === 'part') {
				topic += `${user.training_id}/${user.team}/${user.part}`
			}
			topic += `/${action}`
			if (typeof message === 'object') message = JSON.stringify(message)
			this.client.publish(topic, message, { qos: qos })
		},


		async $getUserData() {
			if (!this.user.id) return

			let response
			response = await this.$get('/gamedata')
			this.setting = response.data.setting
			this.teamData = response.data.teamData

			// response = await this.$get('/setting')
			// this.setting = response.data

			// response = await this.$get('/team/data')
			// this.teamData = response.data

			this.$set(this.user, 'capital', this.teamData[`capital_${this.user.part}`])

			// response = await this.$get('/training/data')
			const trainingData = response.data.trainingData
			const stocks = []
			Object.keys(trainingData).forEach(k => {
				if (k.startsWith('stock_')) {
					stocks.push(trainingData[k])
				}
			})
			this.$set(this.$userData, 'data', trainingData)
			this.$set(this.$userData, 'stocks', stocks)

			// response = await this.$get('/inventories')
			this.$set(this.$userData, 'inventories', response.data.inventories)

			if (this.user.part === 'plan') {
			} else if (this.user.part === 'buy') {
				// response = await this.$get('/setting/deliveries')
				this.deliveries = response.data.deliveries
			}
		},

		$showDialog(options) {
			const id = _.uniqueId()
			this.$set(this.$gameData.dialogs, id, {
				dialogId: id,
				...options,
			})

			return id
		},
		$hideDialog(id) {
			if (id) {
				this.$delete(this.$gameData.dialogs, id)
			} else {
				this.$gameData.dialogs = {}
			}
		},
		$showToast(toast) {
			this.$gameData.toast = toast
		}

	}

}